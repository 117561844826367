import React, { forwardRef, useState, useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import axios from 'utils/axios';
// material-ui
import {
    Grid,
    Button,
    Dialog,
    Slide,
    Typography,
    useMediaQuery,
    LinearProgress,
    Stack,
    Divider,
    Avatar,
    InputAdornment,
    Box,
    OutlinedInput,
    Menu
} from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
// assets
import SearchIcon from '@mui/icons-material/Search';
import { IconArrowLeft } from '@tabler/icons';
import { gridSpacing } from 'store/constant';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeIcon from '@mui/icons-material/Home';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaidIcon from '@mui/icons-material/Paid';
import InfoIcon from '@mui/icons-material/Info';
import PedidoView from 'views/pedidos/PedidoView/PedidoView';
import BarcodeReader from 'react-barcode-reader';
import NumPad from 'main-components/NumPad';
import { shouldForwardProp } from '@mui/system';

import { IconX, IconSearch, IconKeyboardShow, IconDots, IconTrash } from '@tabler/icons';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);
const bcImg =
    'https://firebasestorage.googleapis.com/v0/b/hawk-admin.appspot.com/o/hawk-users%2Fbarcode-enabled10.png?alt=media&token=06581c33-fd26-4085-96b7-deb905815b27';
// ===============================|| UI DIALOG - FULL SCREEN ||=============================== //

export default function SearchDialog({ open, handleCloseDialog }) {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('md'));
    const [scan, setScan] = useState('--');
    const [searchResult, setSearchResult] = useState({ searchStatus: -1, searchMessage: '' });
    const [guiaSearch, setGuiaSearch] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [bcEnabled, setBcEnabled] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [guiaFound, setGuiaFound] = useState(false);
    const [guiaData, setGuiaData] = useState({});

    React.useEffect(() => {}, [open]);

    const handleNumpad = (e) => {
        setGuiaSearch(e);
        setAnchorEl(null);
    };
    const handleClickNumPad = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleCloseNumPad = () => {
        setAnchorEl(null);
    };

    const handleScan = async (e) => {
        console.log(JSON.parse(e));
        setGuiaFound(false);
        setBcEnabled(false);
        setIsLoading(true);
        const guia = e;
        setGuiaSearch(guia);

        if (guia == 0) {
            setBcEnabled(true);
            return null;
        }

        if (guia.toString().length >= 10 || isNaN(guia)) {
            setSearchResult({ guiaInfo: {}, searchStatus: 2, searchMessage: 'Código de barras incorrecto' });
            setBcEnabled(true);
            setIsLoading(false);
            return null;
        }

        const apiUrl = `/api/v1/pedidos/pedidofullfull/${guia}`;
        await axios.get(apiUrl).then((response) => {
            const res = response.data;
            if (res.status == 1) {
                setGuiaData(res);
                const timer = setTimeout(() => {
                    setIsLoading(false);
                    setGuiaFound(true);
                    setGuiaSearch('');
                    setBcEnabled(true);
                }, 500);
            } else {
                const timer = setTimeout(() => {
                    setSearchResult({ guiaInfo: {}, searchStatus: 3, searchMessage: 'La guía no existe' });
                    setIsLoading(false);
                    setGuiaSearch('');
                    setBcEnabled(true);
                }, 500);
            }
        });
    };
    const handleError = (e) => {
        //console.log(e);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setGuiaFound(false);
        setGuiaSearch('');
        setGuiaData({});
        handleCloseDialog(false);
    };

    const handleCrearPedido = () => {};

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                keepMounted
                sx={{
                    '&>div:nth-of-type(3)': {
                        justifyContent: 'flex-end',
                        '&>div': {
                            m: 0,
                            p: 0,
                            borderRadius: '0px',
                            minWidth: 0.55,
                            minHeight: '100%'
                        }
                    }
                }}
            >
                {open && (
                    <>
                        <BarcodeReader onError={handleError} onScan={handleScan} />
                        <Grid>
                            <Stack sx={{ mx: 2 }}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ my: 0.5, mx: 0 }}>
                                    <Stack direction="row" alignItems="center" sx={{ mt: 1, cursor: 'pointer' }} onClick={handleClose}>
                                        <IconX stroke={2.5} size="1.8rem" />
                                        {bcEnabled && (
                                            <Grid sx={{ mr: 4 }}>
                                                <img src={bcImg} width={small ? 30 : 40} style={{ marginLeft: 40 }}></img>
                                            </Grid>
                                        )}
                                    </Stack>
                                    <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
                                        <SearchIcon color="primary" sx={{ fontSize: small ? 18 : 30, mr: 1 }} />
                                        <Typography variant={small ? 'h4' : 'h2'} align="left">
                                            Consultar guía
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Divider sx={{ my: 2 }} />
                                {!guiaFound ? (
                                    <Grid>
                                        <Typography variant="h5" align="left" sx={{ fontSize: 15, fontWeight: 700 }}>
                                            Escanea o escribe la guía
                                        </Typography>
                                        <Stack direction="row" alignItems="center" sx={{ my: 1 }}>
                                            <Box sx={{ display: { md: 'block' } }}>
                                                <OutlineInputStyle
                                                    id="input-search-header"
                                                    value={guiaSearch}
                                                    onChange={(e) => setGuiaSearch(e.target.value)}
                                                    onKeyDown={(ev) => {
                                                        if (ev.key === 'Enter') {
                                                            handleScan(ev.target.value);
                                                        }
                                                    }}
                                                    placeholder="Buscar guía"
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                                        </InputAdornment>
                                                    }
                                                    endAdornment={
                                                        <InputAdornment
                                                            position="end"
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={handleClickNumPad}
                                                        >
                                                            <IconKeyboardShow
                                                                stroke={1.5}
                                                                size="1.3rem"
                                                                sx={{ color: theme.palette.grey[400] }}
                                                            />
                                                        </InputAdornment>
                                                    }
                                                    aria-describedby="search-helper-text"
                                                    inputProps={{ 'aria-label': 'weight' }}
                                                />
                                            </Box>
                                            <Menu
                                                elevation={0}
                                                id="menu-friend-card"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleCloseNumPad}
                                                sx={{ p: 0, m: 0 }}
                                            >
                                                {Boolean(anchorEl) && <NumPad handleOutput={handleNumpad} cantidad={guiaSearch} />}
                                            </Menu>
                                            <InputAdornment position="end" onClick={() => handleScan(guiaSearch)} sx={{ ml: 2 }}>
                                                <HeaderAvatarStyle variant="rounded">
                                                    <IconSearch stroke={2.5} size="1.5rem" sx={{ p: 1.5 }} />
                                                </HeaderAvatarStyle>
                                            </InputAdornment>
                                        </Stack>
                                        {isLoading && <LinearProgress sx={{ my: 2 }} />}
                                        <Stack sx={{ mt: 4 }}>
                                            <Typography variant={'h5'} sx={{ color: '#E64848' }}>
                                                {searchResult.searchMessage}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                ) : (
                                    <Stack>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{ my: 1, ml: 3, cursor: 'pointer' }}
                                            onClick={() => setGuiaFound(false)}
                                        >
                                            <IconArrowLeft stroke={2.5} size="1.5rem" color={theme.palette.secondary.main} />
                                        </Stack>
                                        <PedidoView guia={guiaData.guia.id} standalone={false} data={guiaData} />
                                    </Stack>
                                )}
                            </Stack>
                        </Grid>
                    </>
                )}
            </Dialog>
        </div>
    );
}

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    background: theme.palette.grey[200],
    color: theme.palette.secondary.main,
    '&:hover': {
        background: theme.palette.grey[400],
        color: theme.palette.secondary.main
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 220,
    marginLeft: 2,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
    }
}));
