import React, { useState } from "react";
import { Grid, Button, Dialog, IconButton,  Slide, Typography, Card, CardActions, CardContent,
    CardHeader, CardMedia , Chip, Link, useMediaQuery,LinearProgress, Fade, Stack, Divider, 
    FormControl, Radio, RadioGroup, FormControlLabel, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getFecha, namedDate, namedStringDate, namedBloque, namedMetodo } from 'utils/helpers';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeIcon from '@mui/icons-material/Home';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaidIcon from '@mui/icons-material/Paid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { formatDate } from "@formatjs/intl";


export default function ResumenBox({pedido}) {
    React.useEffect(() => {

    }, []);

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid sx={{px: matchDownMd ? 1: 3}}>
            <Stack direction="row" alignItems="center" justifyContent='space-between'>
                <Stack direction="row" alignItems="center">
                    <HomeIcon sx={{color: "#f44336", fontSize: 18}} />
                    <Typography variant="h5" sx={{ ml: .5}} >Recolección</Typography>
                </Stack>
                <Stack direction="column">
                    <Typography variant="caption" align="right" >{pedido.origen_colonia}, {pedido.origen_municipio}</Typography>
                    <Typography variant="smallsub" align="right" sx={{color: "#2c88fb", fontSize: 10}}  >{namedStringDate(pedido.origen_fecha)}</Typography>
                    <Typography variant="smallsub" align="right" sx={{color: "#2c88fb", fontSize: 10}}  >{namedBloque(pedido.origen_bloque)}</Typography>
                </Stack>
            </Stack>
            <Divider sx={{my:1}}/>
            <Stack direction="row" alignItems="center" justifyContent='space-between'>
                <Stack direction="row" alignItems="center">
                    <LocationOnIcon sx={{color: "#f44336", fontSize: 18}} />
                    <Typography variant="h5" sx={{ ml: .5}} >Entrega</Typography>
                </Stack>
                <Stack direction="column">
                    <Typography variant="caption" align="right" >{pedido.entrega_colonia}, {pedido.entrega_municipio}</Typography>
                    <Typography variant="smallsub" align="right" sx={{color: "#2c88fb", fontSize: 10}}  >{namedStringDate(pedido.entrega_fecha)}</Typography>
                    <Typography variant="smallsub" align="right" sx={{color: "#2c88fb", fontSize: 10}}  >{namedBloque(pedido.entrega_bloque)}</Typography>
                </Stack>
            </Stack>
            <Divider sx={{my:1}}/>
            <Stack direction="row" alignItems="center" justifyContent='space-between'>
                <Stack direction="row" alignItems="center">
                    <ViewInArIcon sx={{color: "#f44336", fontSize: 18}} />
                    <Typography variant="h5" sx={{ ml: .5}} >Paquete</Typography>
                </Stack>
                <Stack direction="column">
                    <Typography variant="caption" align="right" >Paquete {pedido.paq_tipo}</Typography>
                    <Typography variant="smallsub" align="right" sx={{color: "#2c88fb", fontSize: 10}}  >{pedido.paq_contenido}</Typography>
                </Stack>
            </Stack>
            <Divider sx={{my:1}}/>
            <Stack direction="row" alignItems="center" justifyContent='space-between'>
                <Stack direction="row" alignItems="center">
                    <PaidIcon sx={{color: "#f44336", fontSize: 18}} />
                    <Typography variant="h5" sx={{ ml: .5}} >Forma de pago</Typography>
                </Stack>
                <Stack direction="column">
                    <Typography variant="caption" align="right" >${pedido.precio}</Typography>
                    <Typography variant="smallsub" align="right" sx={{color: "#2c88fb", fontSize: 10}}  >{namedMetodo(pedido.metodo_pago)}</Typography>
                </Stack>
            </Stack>
            <Divider sx={{my:1}}/>
            
           
        </Grid>
    );

}