import React,{useState,useEffect, useRef} from 'react'
import axios from 'utils/axios';
import { Grid, Button, Typography,Stack, useMediaQuery,Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, LinearProgress} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { namedDate } from 'utils/helpers';
import SubCard from 'ui-component/cards/SubCard';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAuth from 'hooks/useAuth';


const CobrosBloqueTab = ({bloque,fechaInfo, fechaName}) => {
    const {user} = useAuth();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const componentRef = useRef(null);

    const [openG1, setOpenG1] = React.useState(false);
    const [openG2, setOpenG2] = React.useState(false);
    const [openG3, setOpenG3] = React.useState(false);
    const [openPrint, setOpenPrint] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [pedidosData, setPedidosData] = React.useState([]);
    const [printDate, setPrintDate] = React.useState(new Date());
    const [municipios, setMunicipios] = React.useState({});
    const [printingData, setPrintingData] = React.useState([]);
    const [bloquesData, setBloquesData] = React.useState([]);


    React.useEffect(() => {
       fetchData(fechaInfo,bloque);
    }, [fechaInfo]);

    const dateHandler = (date) => {
        const fecha = new Date(fecha);
        return fecha;
    }

    const fetchData = async (fecha, bloque) => {
        const apiUrl = `/api/v1/ops/cobros/getbloques`;
        const apiData = {fecha: fecha, bloque: bloque};
        
        //console.log(apiData);
        await axios.post(apiUrl, apiData).then(response => {
           setBloquesData(response.data);
           
             });  
     }

     const setbloqueSent = async (subbloque) => {
        const apiUrl = `/api/v1/print/setbloquesent`;
        const apiData = {subbloque: subbloque}
        
        //console.log(apiData);
        await axios.post(apiUrl, apiData).then(response => {
           console.log(response.data);
           
             });  
     }

     const bloqueCreate = async (fecha,noBloque) => {
        setIsLoading(true);
        const apiUrl = `/api/v1/ops/cobros/bloque`;
        const apiData = {fecha: fecha, bloque: noBloque, nombre: `Cobros ${fecha} B${noBloque}`, created_by: user.eid}

      
        
        await axios.post(apiUrl, apiData).then(response => {
           console.log(response.data);
            const timer = setTimeout(() => {
                //console.log(apiData);
                fetchData(fechaInfo,bloque);
                setIsLoading(false);
                }, 1000);
           
             });   
     }




    const handleDialog = async (value) => {
        const sent = await setbloqueSent(value);
        window.open(`https://hawk-admin.web.app/printbloque/${value}`);
        fetchData(fechaInfo,bloque);
        //console.log(value);
        //window.open('http://www.google.com');
    };
    const closePrintDialog = (value) => {
    
        console.log(value);
    };

    return (
        <Grid container direction="column" spacing={2} >
        {/*     {printingData.length >= 1 && <PrintViewDialog open={openPrint} handleCloseDialog={closePrintDialog} data={printingData}/>} */}
            <Grid item xs={12} md={6} sx={{mb: 2}}>
                {bloquesData && bloquesData.length < 1 && <><Button variant="outlined" size="small" sx={{color: '#2c88fb', borderColor: '#2c88fb', textTransform: 'none'}} 
                    onClick={() => bloqueCreate(fechaInfo,bloque)}>Crear bloque</Button></>}
                {isLoading && <LinearProgress sx={{width: 300, my: 1}}/>}
            </Grid>
            <Grid item xs={12} md={8} lg={6} sx={{mb: 2, width: {xs: '100%', md: '100%'}}}>
                <TableContainer>
                    <Table size="small" aria-label="collapsible table">
                        <TableBody>
                        {bloquesData && bloquesData.map((bloque) => (
                                <Row key={bloque.id} bloque={bloque} dialog={handleDialog} />
                            ))}
                        
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            
        </Grid>
    );
}

function Row({ bloque, dialog }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow key={bloque.id} hover sx={{ '& > *': { borderBottom: 'unset' } }} >
                <TableCell sx={{ pl: 3, width: 50}} onClick={() => setOpen(!open)}>
                    <IconButton aria-label="expand row" size="small" >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" onClick={() => setOpen(!open)}>
                    <Typography variant="h6" >{bloque.nombre.toUpperCase()} </Typography>
                    <Typography variant="caption" >{'ID: '+bloque.id} </Typography>
                </TableCell>
                <TableCell align="right">
                <Button variant="outlined" size="small" sx={{color: '#2c88fb', borderColor: '#2c88fb', textTransform: 'none'}} 
                   component={Link} to={`/cobros/captura/${bloque.id}`} >Entrar</Button>
                </TableCell>   
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <Box sx={{m:2 , md:{ml:5}}}>
                                <TableContainer >
                                    <SubCard
                                        sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.100', mb: 2, width: {md: '100%'}}}
                                        content={false}
                                    >
                                        <Table size="small" aria-label="purchases" >
                                            <TableBody>
                                               
                                            </TableBody>
                                        </Table>
                                    </SubCard>
                                </TableContainer>
                            </Box>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default CobrosBloqueTab;