import React, { useEffect, useState, useRef } from "react";
import axios from 'utils/axios';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import L, { divIcon } from "leaflet";
import {
  TileLayer,
  MapContainer,
  LayersControl,
  Marker,
  Polyline,
  Popup,
  useMapEvents,
  Circle,
  FeatureGroup
} from "react-leaflet";
import { Grid, Button, Typography,Stack,Link, Divider, useMediaQuery,Box, Collapse, IconButton,  Chip, LinearProgress} from '@mui/material';
import SideBarInline from "./components/SideBarInline";
import Avatar from 'ui-component/extended/Avatar';

import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import { IconBattery, IconBattery1, IconBattery2, IconBattery3, IconBattery4 } from '@tabler/icons';

import Map from '../map/TrackingMap';

import './markers.css'

//const socket = io("https://hawkapi.lamensajeria.mx");

const maps = {
  base: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
};

var myIcon = L.icon({
  iconUrl: 'https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2F2.png?alt=media&token=4dc789d0-61e4-4402-91f7-f98e3a476fdf',
  iconSize: [35, 35],
  iconAnchor: [19, 32],
  popupAnchor: [-3, -76],
  shadowUrl: 'https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fmarker-shadow.png?alt=media&token=0ff7331c-b49f-4802-bb6e-b65c2a178b9a',
  shadowSize: [32, 32],
  shadowAnchor: [11, 30],
  name: 'Hola'
 
});



const RoutesMap = () => {
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const featureGroupRef = useRef();
  const [start, setStart] = useState(false)
  const [end, setEnd] = useState([25.692775871197192, -100.290930734875]);
  const [positions,setPositions] = useState([]);
  const [routes,setRoutes] = useState([]);
  const [multiPoly,setMultiPoly] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [sizeIcon, setSizeIcon] = useState(40);
  const [routeView, setRouteView] = useState(false);
  const [routeSelected,setRouteSelected] = useState(0);


 /*  useEffect(() => {
    socket.on('routeUpdate', (data) => {
      //console.log(data);
      const poss = [...positions];

     setPositions(current => 
                current.map(z => {
                    if (z.route_id === data.route_id) {
                        return {...z, lat: data.lat, lng: data.lng}
                    }
                    return z;
                })
            );
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
    };
  },[]); */

  /* useEffect(() => {
    socket.on('visitaAdded', (data) => {
      if(data == 1) {
        fetchData();
      }
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
    };
  },[]); */


  React.useEffect(() => {
    fetchData();
}, []);

const fetchData = async (id) => {
    
    const apiUrl = `/api/v1/app/route/operators`;
    //const apiData = {fecha: fecha, bloque: bloque};

    await axios.get(apiUrl).then(response => {
        //console.log(response.data);
        let last_pos = [];
        setRoutes(response.data);
        response.data.map((val) => {
          if(val.route.last_lat) {
            last_pos.push({route_id: val.route.id, lat: val.route.last_lat, lng: val.route.last_lng, nombre_corto: val.route.nombreCorto});
          } else {
            last_pos.push({route_id: val.route.id, lat: '25.65934418319787', lng: '-100.25207046863379', nombre_corto: val.route.nombreCorto});
          }
          
        });

        setPositions(last_pos);
        setFetched(true);


        
            });
}

const handleRouteView = async (routeId) => {
  setRouteSelected(routeId);
  setRouteView(true);
  console.log(routeId);
}

const handleBackView = async () => {
  setRouteView(false);
  setRouteSelected(0);
}




  return (
    <>
    <Grid container>
      <Grid item  md={2} sx={{xs: {display: 'none'}}} >
        {routes && routes.map((op) => {
          return(
            <OperadorRow op={op} />
          );
        })}
      </Grid>
      <Grid item  xs={12} md={9.9} sx={{my: 0, ml: 0}}>
        {!routeView ? <MapContainer
          center={[25.65226022118352, -100.27925554894423]}
          zoom={12}
          zoomControl={false}
          style={{ height: "100vh", width: "100%", padding: 0 }}
          whenCreated={map => {
            //console.log('map ready');
            setMap(map)}}
        >

          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Map">
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url={maps.base}
              />
              <FeatureGroup ref={featureGroupRef}>
              {fetched && positions.map((pos,i) => {
                //console.log(pos);
                //console.log(`${pos.lat},${pos.lng}`);


                let markerClass = 'entrega-marker';
               /*  html: `<div class="${markerClass}" style="background-size: ${sizeIcon}px ${sizeIcon}px; width: ${sizeIcon}px; height: ${sizeIcon}px">
                <div class="circle">${i+1}</div>
                </div>` */
                
                var iconDiv = new L.DivIcon({
                  //html: '<span></span>'
                  //className: 'recoleccion-marker',

                 /*  `<div width: ${sizeIcon}px; height: ${sizeIcon}px">
                          <img src="https://firebasestorage.googleapis.com/v0/b/hawk-admin.appspot.com/o/hawk-imgs%2FmotoMap.png?alt=media&token=ec63c006-9a07-4297-9424-deb58492b78b" height="110px"></img>
                        <div class="circle">Javier</div>
                        </div>` */

                  iconSize: null,
                  html: `<div class="moto-marker" style="background-size: 60px 60px; width:80px; height: 80px">
                      <div class="circle">${pos.nombre_corto}</div>
                      </div>`
                });
                return (
                  
                    <Marker key={i} position={[pos.lat,pos.lng]} icon={iconDiv} >
                      <Popup>
                        <Typography>{pos.route_id}</Typography>
                        <Typography>{pos.lat}</Typography>
                        <Typography>{pos.lng}</Typography>
                      </Popup>
                    </Marker>
                )
              })}

                  

            

            
            
                </FeatureGroup>
            </LayersControl.BaseLayer>
          </LayersControl>
        </MapContainer> : 
          <Map routeId={routeSelected} handleBack={handleBackView} />
        }
      </Grid>
    </Grid>
      
    </>
  );



  function OperadorRow({ op}) {

    console.log(op)


    return(
      <Grid sx={{my:2, cursor: 'pointer'}} onClick={ () => handleRouteView(op.route.id)}>
        <Stack direction="row"   alignItems="center" sx={{mt:0}}>
          <Avatar alt="User 1" src={op.route.foto_url} size="sm"/>
          <Stack sx={{ml:1,width: '100%'}}>
          <Stack direction="row" justifyContent="space-between" alignItems= "start" >
            <Stack>
              <Typography variant="h6" sx={{lineHeight: .7}}>{op.route.nombre.toUpperCase()} </Typography>
              <Typography variant="caption" >{op.route.correo} </Typography>
            </Stack>
            <Stack direction="row"   alignItems="center" sx={{mt:0}}>
              
              <Typography variant="caption"  sx={{ fontSize: 10,mr:1}}> 10.4 km</Typography>
            </Stack>
          </Stack>
            
            
            <Stack direction="row"   alignItems="center" sx={{mt:.5}}>
              <Stack direction="row"   alignItems="center" sx={{ml:0}}>
                  <HomeTwoToneIcon sx={{fontSize: 24}}/>
                  <Typography variant="h6" align="left" sx={{ fontSize: 14,mt:.1}}> {op.visitas.recs_guias}</Typography>
              </Stack>
              <Stack direction="row"   alignItems="center" sx={{ml:1}}>
                  <FmdGoodTwoToneIcon sx={{fontSize: 22}}/>
                  <Typography variant="h6" align="left" sx={{fontSize: 14, mt:.1}}> {op.visitas.ents_guias}</Typography>
              </Stack>
              <Stack direction="row"   alignItems="center" sx={{ml:1}}>
                  <WarningTwoToneIcon sx={{fontSize: 22}}/>
                  <Typography variant="h6" align="left" sx={{ fontSize: 14,mt:.1}}> {op.visitas.inciRecs_guias + op.visitas.inciEnts_guias }</Typography>
              </Stack>
              <Stack direction="row"   alignItems="center" sx={{ml:1}}>
                  <MonetizationOnTwoToneIcon sx={{fontSize: 22}}/>
                  <Typography variant="h6" align="left" sx={{ fontSize: 14,mt:.1}}> {op.visitas.efectivo}</Typography>
              </Stack>
            </Stack>
            <Stack alignItems="center" width={30}>
            <IconBattery4 stroke={1.5} size="1.8rem" color= '#00C897' />
            <Typography variant="smallsub" sx={{ fontSize: 9, lineHeight: .8}}> {Math.round(op.route.last_bat * 100)}%</Typography>
            </Stack>
            

          </Stack>
        </Stack>
        <Divider sx={{my: 2}}/>
      </Grid>
    );
  }

  function RealtimeMap({}) {

    return (
      <Grid></Grid>
    );
  }

};

export default RoutesMap;
