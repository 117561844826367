import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const createRoutineMachineLayer = ({ position, positions, color }) => {
  const instance = L.Routing.control({
    position,
    waypoints: positions,
    lineOptions: {
      styles: [
        {
          color,
        },
      ],
    },
    show: false,
    waypointMode: 'snap',
    createMarker: function() { return null; },
  });

  return instance;
};

const RoutingMachine = createControlComponent(createRoutineMachineLayer);

export default RoutingMachine;
