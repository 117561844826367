import React, { useState, useEffect, useRef } from 'react';
import axios from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Typography, Stack, Link, useMediaQuery, LinearProgress } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import PedidosPdf from './components/PedidosPdf';
import loadingGif from 'assets/images/icons/kOnzy.gif';
import QRGenerator from '../PedidosSearch/components/qrGenerator';

const PrintCorteView = ({}) => {
    let params = useParams();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const componentRef = useRef(null);

    const [pedidoId, setPedidoId] = useState(params.id);
    const [isView, setIsView] = React.useState(false);
    const [pedidosData, setPedidosData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [pdfDisplay, setPdfDisplay] = React.useState('none');
    const [loadingText, setLoadingText] = React.useState('');

    React.useEffect(() => {
        fetchData(params.id);
    }, []);

    const fetchData = async (corte) => {
        const apiUrl = `/api/v1/print/corte/${corte}`;
        setLoadingText('Descargando información...');
        //console.log(apiData);
        await axios.get(apiUrl).then((response) => {
            console.log(response.data);
            setPedidosData(response.data);
            setLoadingText(`Generando ${response.data.length} guías...`);
            const timer = setTimeout(() => {
                setIsView(true);
            }, 1000);
        });
    };

    const handleOnRender = (e) => {
        console.log(e);
        setIsLoading(false);
    };

    return (
        <MainCard>
            <>
                <Stack direction="column" alignItems="center">
                    {isLoading && (
                        <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
                            <img src={loadingGif} alt="Berry" width={25} />
                            <Typography variant="h4" sx={{ ml: 1, fontSize: 16 }}>
                                {loadingText}
                            </Typography>
                        </Stack>
                    )}
                </Stack>
                {pedidosData.map((p, i) => {
                    return (
                        <div key={i}>
                            <div style={{ display: 'none' }}>
                                <QRGenerator
                                    value={JSON.stringify({
                                        id: p.s_id,
                                        pv: p.precio_venta,
                                        fp: p.forma_pago_id,
                                        pt: p.tipo_paquete,
                                        c: p.cobro_destinatario,
                                        cc: p.cobro_cantidad,
                                        ot: 1,
                                        to: 1
                                    })}
                                    documentId={p.s_id + 8888}
                                />
                            </div>
                            <div style={{ display: 'none' }}>
                                <QRGenerator
                                    value={JSON.stringify({
                                        id: p.s_id,
                                        pv: p.precio_venta,
                                        fp: p.forma_pago_id,
                                        pt: p.tipo_paquete,
                                        c: p.cobro_destinatario,
                                        cc: p.cobro_cantidad,
                                        ot: 1,
                                        to: 2
                                    })}
                                    documentId={p.s_id + 9999}
                                />
                            </div>
                        </div>
                    );
                })}

                {isView && (
                    <div ref={componentRef}>
                        <PedidosPdf pedidosData={pedidosData} handleCallback={handleOnRender} />
                    </div>
                )}
            </>
        </MainCard>
    );
};

export default PrintCorteView;
