import  {useEffect, useState} from 'react';
import axios from 'utils/axios';
import ReactExport from 'react-data-export';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Grid, IconButton, Typography, Tooltip} from '@mui/material';
import xlsIcon from 'assets/images/icons/xls-icon.png';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const dirWidth = 160;


const CorteXls = ({corte,corteLetra}) => {

const [corteSet, setCorteSet] = useState([]);

useEffect(() => {
        fetchData(corte);
},[])


const fetchData = async (corteId, ) => {
    const apiUrl = "/api/v1/print/cortexls/"+corteId;
   await axios.get(apiUrl).then(response => {
        
        const mDataSet=[{columns: response.data.corteHeaders, data: response.data.corteValues}];
        setCorteSet(mDataSet);
        });
    
        
}

        return (
  
            
            <ExcelFile filename={`ReporteCorte${corteLetra}`} 
            element={
            <Tooltip title="Exportar a excel" placement="top">
                <IconButton aria-label="expand row" size="small" > 
                    <img src={xlsIcon} alt="Exportar a excel" height="20" />
                </IconButton>
            </Tooltip>}>
                <ExcelSheet dataSet={corteSet} name={"CORTE "+corteLetra} />
            </ExcelFile>
   
            
        );
    }
    


export default CorteXls;