import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, Card, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography, useMediaQuery, Stack } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';

// assets
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';

import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import NoPhotographyTwoToneIcon from '@mui/icons-material/NoPhotographyTwoTone';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfTwoTone';
import InfoIcon from '@mui/icons-material/Info';

// ==============================|| SOCIAL PROFILE - FRIENDS CARD ||============================== //

const PagoCard = ({ pago }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    /*  const avatarProfile = pago.comp_url 
    ? `https://sp-proxy.segpak.workers.dev/corsproxy/?apiurl=http://uploads.lamensajeria.mx/fichas/${pago.comp_url}` 
    : 'https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fnoimage.png?alt=media&token=6a90e633-1a07-40f6-9db3-3db6d792152f'; */

    const avatarProfile = pago.comp_url
        ? `${pago.comp_url}`
        : 'https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fnoimage.png?alt=media&token=6a90e633-1a07-40f6-9db3-3db6d792152f';

    let avatarIcon;

    let statusText = '';
    let statusColor = '';

    if (pago.sysop_esid == 1) {
        statusText = 'Programado';
        statusColor = '#277BC0';
    } else if (pago.sysop_esid == 4) {
        statusText = 'Entregado';
        statusColor = '#3CCF4E';
    } else if (pago.sysop_esid == 5) {
        statusText = 'Cancelado';
        statusColor = '#EB1D36';
    } else {
        statusText = 'Desconocido';
        statusColor = '#EFEFEF';
    }

    function Icono({ type }) {
        if (type == 1) {
            return <Avatar sx={{ bgcolor: '#EFEFEF' }} src={avatarProfile}></Avatar>;
        } else if (type == 2) {
            return (
                <Avatar sx={{ bgcolor: '#EFEFEF' }}>
                    <PictureAsPdfTwoToneIcon sx={{ color: '#FF8D29' }} />
                </Avatar>
            );
        } else {
            return (
                <Avatar sx={{ bgcolor: '#EFEFEF' }}>
                    <NoPhotographyTwoToneIcon sx={{ color: '#F32424' }} />
                </Avatar>
            );
        }
    }

    const btnSX = {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200],
        background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.background.paper
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Card
            sx={{
                p: 2,
                width: matchDownMd ? 250 : 200,
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                border: '1px solid',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100],
                '&:hover': {
                    border: `1px solid${theme.palette.primary.main}`
                }
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Icono type={pago.imgType} />
                        </Grid>
                        <Grid item xs zeroMinWidth>
                            <Typography
                                variant="h5"
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block' }}
                            >
                                {pago.sysId}
                            </Typography>
                            <Stack direction="row" alignItems="center" sx={{ mt: 0.5 }}>
                                <MonetizationOnTwoToneIcon sx={{ mr: 0.5, fontSize: '0.875rem', verticalAlign: 'text-top' }} />
                                <Typography
                                    variant="subtitle2"
                                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block' }}
                                >
                                    {pago.precio_venta}.00
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item>{pago.c == 11 && <InfoIcon sx={{ fontSize: 20, color: '#277BC0' }} />}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mx: 0, my: 0 }}>
                                <Stack direction="row" alignItems="center">
                                    <CheckCircleIcon sx={{ fontSize: 16, color: pago.c == 10 ? '#3CCF4E' : '#EFEFEF' }} />
                                    <Typography
                                        variant="body2"
                                        sx={{ fontSize: 12, fontWeight: 500, color: pago.c == 10 ? '#3CCF4E' : '#EFEFEF', ml: 0.5 }}
                                    >
                                        Confirmado
                                    </Typography>
                                </Stack>
                                <Typography variant="h6" align="right" sx={{ fontSize: 10, fontWeight: 500, color: statusColor }}>
                                    {statusText}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default PagoCard;
