import React, { Component, Fragment } from 'react';
import isEmpty from 'lodash.isempty';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import GoogleMap from 'google-map-react';

import Marker from './components/Marker';
import SearchBox from './components/SearchBox';

const myRef = React.createRef();
const API_KEY ='AIzaSyAz3TTOWepckifYJESha7lck4x6gGVj6ds';

const getCenter = (markers, map, maps) => {
    const center = {};
    
    center.lat = 25.692564964078507;
    center.lng = -100.30887352534988;
  
    return center;
  };



class CoverageMap extends Component {
    constructor(props){
        super(props)
        this.state = {
            mapApiLoaded: false,
            mapInstance: null,
            mapApi: null,
            places: [],
        }
      }

      handleApiLoaded = (map, maps) => {
        //console.log(map);
        //console.log(maps);
    this.setState({
        mapApiLoaded: true,
        mapInstance: map.map,
        mapApi: map.maps,
    });
    };

    addPlace = (place) => {
        this.setState({ places: place });
      };

    selectCenter = () => {
        const center = getCenter();
        return center;
      };

  render() {

    const {
        places, mapApiLoaded, mapInstance, mapApi,
      } = this.state;

    return (
        <Fragment>
         {mapApiLoaded && <SearchBox map={mapInstance} mapApi={mapApi} addplace={this.addPlace} />}   
        <div style={{ height: '85vh', width: '100%' }}>
            <GoogleMap
                bootstrapURLKeys={{ key: API_KEY,libraries: ['places', 'geometry'],}}
                defaultCenter={this.selectCenter()}
                defaultZoom={12}
                ref={myRef}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded({ map, maps })}
                >
                {!isEmpty(places) &&
                    places.map(place => (
                    <Marker
                        key={place.id}
                        text={place.name}
                        lat={place.geometry.location.lat()}
                        lng={place.geometry.location.lng()}
                    />
                ))}
            </GoogleMap>
      </div>
      </Fragment>
    );
  }
}

export default CoverageMap;