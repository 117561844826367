import React,{useState,useEffect} from 'react'
import { useTheme } from '@mui/material/styles';
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// material-ui
import { Grid, Button, Dialog, IconButton,  Slide, Typography, Card, CardActions, CardContent,
     CardHeader, CardMedia , Chip, Link, useMediaQuery,LinearProgress, Fade, Stack, Divider, 
     FormControl, Radio, RadioGroup, FormControlLabel, Avatar } from '@mui/material';




// ===============================|| UI DIALOG - FULL SCREEN ||=============================== //

export default function ReactPdf({fileName}) {

    const pdfFile = 'http://uploads.lamensajeria.mx/fichas/MTY512605-27092022.pdf';
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    function onDocumentLoadSuccess({ numPages }) {
        console.log("object: pdf");

      }

    return (
        <Document 
        options={{ workerSrc: "/pdf.worker.js" }}
        file={{url:`https://sp-proxy.segpak.workers.dev/corsproxy/?apiurl=http://uploads.lamensajeria.mx/fichas/${fileName}`}}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => console.log("Inside Error", error)}
     >
        <Page pageNumber={1} width={840} height={1050}/>   
     </Document>
    )
                       

}