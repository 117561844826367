import React, {useState, useEffect, useRef} from 'react'
import {Typography, Stack} from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
}

const INITIAL_COUNT = 20

export default function PedidoTimer({actions}) {
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT)
  const [status, setStatus] = useState(STATUS.STARTED)

  const secondsToDisplay = secondsRemaining % 60
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60
  const minutesToDisplay = minutesRemaining % 60
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60

  const handleStart = () => {
    setStatus(STATUS.STARTED)
  }
  const handleStop = () => {
    setStatus(STATUS.STOPPED)
  }
  const handleReset = () => {
    setStatus(STATUS.STOPPED)
    setSecondsRemaining(INITIAL_COUNT)
  }
  useInterval(
    () => {
      if (secondsRemaining > 0) {
        if (secondsRemaining  == 10) {
            actions(2);
        }
        setSecondsRemaining(secondsRemaining - 1)
      } else {
        actions(1);
        setStatus(STATUS.STOPPED)
      }
    },
    status === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
  )
  return (
     <Stack direction="row" justifyContent='space-between' alignItems="center">
        <Typography sx={{color: '#FFFFFF', fontSize: 14}}>Tiempo restante para utilizar recolección  10am </Typography>
        <Typography variant="h4" sx={{color: '#FFFFFF', fontSize: 14, ml:2, mt: .3, fontWeight: 800}} align="center">{twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}</Typography>
     </Stack>

  )
}

// source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

// https://stackoverflow.com/a/2998874/1673761
const twoDigits = (num) => String(num).padStart(2, '0')
