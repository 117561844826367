
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
    Svg,
    G,
    Polygon,
    PDFDownloadLink,
    Font
  } from "@react-pdf/renderer";

export  const Home = () => (
    <Svg style={{ padding: 0, width: 18, height: 15 }} >
        <G >
        <Polygon
        fill="#A6A6A6"
        points="12.978 5.192, 11.325 3.845, 11.325 1.691, 10.885 1.251, 9.845 1.251, 9.405 1.691, 9.405 2.280, 7.196 0.481, 6.603 0.279, 6.013 0.479, 0.232 5.192, 0.018 5.685, 0.493 5.931, 1.285 5.931, 1.285 12.129, 2.085 12.931, 4.585 12.931, 5.365 12.129, 5.365 9.587, 5.785 9.171, 7.465 9.171, 7.845 9.587, 7.845 12.129, 8.665 12.931, 11.125 12.931, 11.925 12.129, 11.925 5.931, 12.716 5.931, 13.192 5.685, 12.978 5.192"
      />
    </G>
    </Svg>
   
)