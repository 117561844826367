import other from './other';
import operaciones from './operaciones';

// ==============================|| MENU ITEMS ||============================== //

const OpsMenuItems = {
    items: [operaciones]
};

export default OpsMenuItems;
