import React,{useState,useEffect} from 'react'
import axios from 'utils/axios';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import BloqueTimer from './components/BloqueTimer';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormHelperText,
    InputAdornment,
    Grid,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    Card,
    Link,
    useMediaQuery,
    Box,
    LinearProgress,
    Avatar,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Switch,
    Radio,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import AddressDialog from './dialogs/AddressDialog';
import FavoritoDialog from './dialogs/FavoritoDialog';
import OpcionesDialog from './dialogs/OpcionesDialog';
import ProgramRecDialog from './dialogs/ProgramRecDialog';
import ProgramEntDialog from './dialogs/ProgramEntDialog';
import PaqueteDialog from './dialogs/PaqueteDialog';
import ResumenDialog from './dialogs/ResumenDialog';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SearchIcon from '@mui/icons-material/Search';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { gridSpacing } from 'store/constant';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme, styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SubCard from 'ui-component/cards/SubCard';

import ProgramInfoCard from './components/ProgramInfoCard';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { getFecha, namedDate } from 'utils/helpers';
import { parse, isDate } from "date-fns";
import { set } from 'lodash';

const NumberTextField = styled(TextField)(({ theme }) => ({
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
      },
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const validationSchema = yup.object({
    cliente_id: yup.string().required('El numero de cliente es requerido'),
    origen_contacto_nombre: yup.string().required('El nombre del contacto es requerido'),
    origen_contacto_tel: yup.string().min(10,'El teléfono debe ser de 10 digitos').required('El teléfono del contacto es requerido'),
    entrega_contacto_nombre: yup.string().required('El nombre del contacto es requerido'),
    entrega_contacto_tel: yup.string().min(10,'El teléfono debe ser de 10 digitos').required('El teléfono del contacto es requerido'),
    entrega_codigo_postal: yup.string().min(5,'El código postal debe ser de 5 digitos').required('El código postal es requerido'),
    entrega_municipio: yup.string().required('El municipio es requerido'),
    entrega_colonia: yup.string().required('La colonia es requerida'),
    entrega_calle: yup.string().required('La calle es requerida'),
    entrega_numero: yup.string().required('El numero del domicilio es requerido'),
    entrega_numero_int: yup.string(),
    entrega_numero: yup.string().required('El numero del domicilio es requerido'),
    entrega_entre_calles: yup.string().required('Las entre calles son requeridas'),
    entrega_referencias: yup.string().required('Las referencias son requeridas'),
    paq_alto: yup.number().lessThan(100,'El valor mínimo es 1').positive('Solo se aceptan valores positivos').required('Alto es requerido'),
    paq_ancho: yup.number().moreThan(1,'El valor mínimo es 1').positive('Solo se aceptan valores positivos').required('Ancho es requerido'),
    paq_largo: yup.number().moreThan(1,'El valor mínimo es 1').positive('Solo se aceptan valores positivos').required('Largo es requerido'),
    paq_peso: yup.number().moreThan(1,'El valor mínimo es 1').positive('Solo se aceptan valores positivos').required('El peso es requerido'),
    paq_contenido: yup.string().required('El contenido del paquete es requerido'),
    origen_fecha: yup.string().required('La fecha de recolección es requerida'),
    entrega_fecha: yup.string().required('La fecha de entrega es requerida'),
});



const CrearPedidoMain = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const [fileData, setFileData] = useState([]);
    const [open, setOpen] = useState(false);
    const [openAddress, setOpenAddress] = React.useState(false);
    const [openFavorito, setOpenFavorito] = React.useState(false);
    const [openOpciones, setOpenOpciones] = React.useState(false);
    const [openProgram, setOpenProgram] = React.useState(false);
    const [openPaquete, setOpenPaquete] = React.useState(false);
    const [openResumen, setOpenResumen] = React.useState(false);
    const [openEntProgram, setOpenEntProgram] = React.useState(false);
    const [valueBasic, setValueBasic] = React.useState(new Date());
    const [defaultAddress, setDefaultAddress] = React.useState({});
    const [addressSelected, setAddressSelected] = React.useState(0);
    const [fetchedInitialData, setFetchedInitialData] = useState(false);
    const [destinoDisabled, setDestinoDisabled] = useState(true);
    const [paqueteDisabled, setPaqueteDisabled] = useState(true);
    const [destinoMunicipio, setDestinoMunicipio] = useState('');
    const [destinoColonias, setDestinoColonias] = useState([]);
    const [destinoCpError, setDestinoCpError] = useState(false);
    const [destinoCpErrorText, setDestinoCpErrorText] = useState('');
    const [otraColoniaActive, setOtraColoniaActive] = useState(false);
    const [favoritoUsed, setFavoritoUsed] = useState(false);
    const [paqueteUsed, setPaqueteUsed] = useState(false);
    const [saveFavorito, setSaveFavorito] = useState(false);
    const [savePaquete, setSavePaquete] = useState(false);
    const [codigoState, setCodigoState] = useState('');
    const [coloniaState, setColoniaState] = useState('');
    const [recSelected, setRecSelected] = useState(0);
    const [entSelected, setEntSelected] = useState(0);
    const [claseProgram, setClaseProgram] = useState(0);
    const [tipoProgram, setTipoProgram] = useState(0);
    const [recFecha, setRecFecha] = useState('');
    const [recBloque, setRecBloque] = useState('');
    const [recBloqueText, setRecBloqueText] = useState('');
    const [entFecha, setEntFecha] = useState('');
    const [entBloque, setEntBloque] = useState('');
    const [entBloqueText, setEntBloqueText] = useState('');
    const [fastestUsed, setFastestUsed] = useState(false);
    const [programUsed, setProgramUsed] = useState(false);
    const [blockedDates, setBloquedDates] = useState([]);
    const [recProgramed, setRecProgramed] = useState(false);
    const [entProgramed, setEntProgramed] = useState(false);
    const [fastestLoading, setFastestLoading] = useState(false);
    const [resumenReady, setResumenReady] = useState(false);
    const [snackType, setSnackType] = useState('trouble');
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackRepOpen, setSnackRepOpen] = useState(false);

    const themeVariants = createTheme({
        components: {
          MuiSnackbar: {
            variants: [
              {
                props: { variant: "trouble" },
                style: {
                  "& .MuiSnackbarContent-root": {
                   
                  }
                }
              },
              {
                props: { variant: "bigTrouble" },
                style: {
                  "& .MuiSnackbarContent-root": {
                    background: "red"
                  }
                }
              }
            ]
          }
        }
      });


    const sideAvatarSX = {
        borderRadius: '8px',
        width: 48,
        height: 48,
        fontSize: '1.5rem',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        '&>svg': {
            width: 24,
            height: 24
        }
    };



useEffect(() => {
    fetchDefaultAddress(1);
    fetchBlockedDates();
},[])

const fetchDefaultAddress = async (clienteId) => {
    const apiUrl = `/api/v1/direcciones/c/${clienteId}/default`;
    
    await axios.get(apiUrl).then(response => {
        //console.log(response.data);
        setDefaultAddress(response.data[0]);
        setAddressSelected(response.data[0].id);
        const origen = response.data[0];

       formik.setValues({...formik.values,
            origen_nombre: origen.nombre,
            origen_contacto_nombre: origen.contacto_nombre,
            origen_contacto_tel: origen.contacto_tel,
            origen_codigo_postal: origen.codigo_postal,
            origen_codigo_postal_id: origen.codigo_postal_id,
            origen_colonia: origen.colonia,
            origen_municipio: origen.municipio,
            origen_municipio_id: origen.municipio_id,
            origen_calle: origen.calle,
            origen_numero: origen.numero,
            origen_numero_int: origen.numero_int,
            origen_empresa: origen.empresa,
            origen_referencias: origen.referencias,
            origen_entre_calles: 'aun no hay',
            origen_otra_colonia: 0
        }) 
        setFetchedInitialData(true);
         });
}

const fetchBlockedDates = async () => {
    const apiUrl = `/api/v1/pedidos/bdts`;
    await axios.get(apiUrl).then(response => {
        setBloquedDates(response.data);
         });
}








const formik = useFormik({
    validateOnChange: false,
    initialValues: {
        cliente_id: '1',
        origen_nombre: '',
        origen_contacto_nombre:'',
        origen_contacto_tel: '',
        origen_nombre: '',
        origen_codigo_postal: '',
        origen_colonia: '',
        origen_municipio: '',
        origen_calle: '',
        origen_otra_colonia: '',
        origen_numero: '',
        origen_numero_int: '',
        origen_empresa: '',
        origen_referencias: '',
        origen_entre_calles: '',
        entrega_contacto_nombre: '',
        entrega_contacto_tel:'',
        entrega_codigo_postal: '',
        entrega_municipio: '',
        entrega_colonia: '',
        entrega_calle: '',
        entrega_numero: '',
        entrega_numero_int: '',
        entrega_entre_calles: 'aun no hay',
        entrega_empresa:'',
        entrega_referencias: '',
        paq_tipo_id: '1',
        paq_alto: '',
        paq_ancho: '',
        paq_largo: '',
        paq_peso:'',
        paq_contenido: '',
    },
    validationSchema,
    onSubmit: (values) => {
        
       if (values) {
            //pedidoCreate(values);
            setOpen(true);
        } 
    }
});

const handleDialogOk = () => {
    setOpen(false);
    formik.resetForm();
};

const handleChangeAddress = () => {
    setOpenAddress(true);
};



const closeAddressDialog = (value) => {
    if(value.id){
        setDefaultAddress(value);
    }
    
    setOpenAddress(false);
    
};



const handleChangeFavorito = () => {
    setOpenFavorito(true);
};

const closeFavoritoDialog = (tipo,direccion) => {
    const destino=direccion;
    if (tipo == 1) {
        setDestinoDisabled(false);
        //console.log(formik.values);
        formik.setValues({...formik.values,
            entrega_contacto_nombre: destino.contacto_nombre,
            entrega_contacto_tel: destino.contacto_tel,
            entrega_codigo_postal: destino.codigo_postal,
            entrega_codigo_postal_id: destino.codigo_postal_id,
            entrega_municipio: destino.municipio,
            entrega_municipio_id: destino.municipio_id,
            entrega_colonia: destino.colonia,
            entrega_calle: destino.calle,
            entrega_numero: destino.numero,
            entrega_numero_int: destino.numero_int,
            entrega_entre_calles: 'aun no hay',
            entrega_empresa: destino.empresa,
            entrega_referencias: destino.referencias,
            entrega_otra_colonia: 0
        });
        setFavoritoUsed(true);
        setOpenFavorito(false);
    } else if (tipo == 2) {
        setOpenFavorito(false);
    }
    
    
    
};

const handleFavoritoCancel = (value) => {
    setDestinoDisabled(true);
    formik.setValues({...formik.values,
        entrega_contacto_nombre: '',
        entrega_contacto_tel:'',
        entrega_codigo_postal: '',
        entrega_municipio: '',
        entrega_colonia: '',
        entrega_calle: '',
        entrega_numero: '',
        entrega_numero_int: '',
        entrega_entre_calles: 'aun no hay',
        entrega_empresa:'',
        entrega_referencias: '',
    });
    setCodigoState('');
    setFavoritoUsed(false);
    
};

const handleSaveFavorito = (e) => {
    setSaveFavorito(e.target.checked);
};

const handleChangeOpciones = () => {
    setOpenOpciones(true);
};

const closeOpcionesDialog = (value) => {
    
    
    setOpenOpciones(false);
    
};


const handleDestinoCpChange = (e) => {
    const cp = e.target.value
    setCodigoState(cp);
    if(cp.length >= 5) {
        setDestinoMunicipio('');
        fetchColoniasByCp(cp);
    } else {
        setDestinoCpError(false);
        setDestinoCpErrorText('')
        setDestinoMunicipio('');
        setDestinoDisabled(true);
        setColoniaState(null);
    }

};

const fetchColoniasByCp = async (cp) => {
    const apiUrl = `/api/v1/rutas/cp/${cp}`;
    await axios.get(apiUrl).then(response => {
    //console.log(response.status);
        if (response.status != 200) {
            console.log('error');
            setDestinoCpError(true);
            setDestinoCpErrorText('El código postal no existe')
            setDestinoMunicipio('');
           setDestinoDisabled(true);
        } else {
            setDestinoMunicipio(response.data[0].municipio);
            setDestinoDisabled(false);
            setDestinoColonias(response.data);
            setColoniaState(response.data[0]);
            formik.setValues({...formik.values, 
                entrega_codigo_postal: cp, 
                entrega_colonia: response.data[0].colonia,
                entrega_municipio: response.data[0].municipio,
            });
            //console.log(response.data);
        }
    
       
         });
}

const handleColoniaChange = (e) => {
    const col = e.target.value
    console.log(col);
    setColoniaState(col);
    //console.log(col);
    if (col == 'otra') {
        formik.setValues({...formik.values, entrega_otra_colonia: 1});
        setOtraColoniaActive(true);
    } else {
        formik.setValues({...formik.values, 
            entrega_otra_colonia: 0,
            entrega_codigo_postal_id: col.codigo_postal_id,
            entrega_municipio_id: col.municipio_id
        });
        setOtraColoniaActive(false);
    }

};

const handleChangePaquete = () => {
    setOpenPaquete(true);
};

const closePaqueteDialog = (tipo,paquete) => {
    if (tipo == 1) {
       setOpenPaquete(false);
       setPaqueteUsed(true);

       formik.setValues({...formik.values,
        paq_alto: paquete.paq_alto,
        paq_ancho: paquete.paq_ancho,
        paq_largo: paquete.paq_largo,
        paq_peso: paquete.paq_peso/1000,
        paq_contenido: paquete.paq_contenido,
    });
        
    } else if (tipo ==2) {
        setOpenPaquete(false);
    }
    
};

const handlePaqueteCancel = (value) => {
   setPaqueteUsed(false);
   formik.setValues({...formik.values,
    paq_tipo_id: 1,
    paq_alto: '',
    paq_ancho: '',
    paq_largo: '',
    paq_peso: '',
    paq_contenido: '',
    paq_nombre: '',
});
    
};

const handlePaqueteSave = (e) => {
    //console.log(e.target.checked);
    setSavePaquete(e.target.checked);
};


const handleAutoProgram = (tipo) => {
    resetProgram(3);
    if(tipo === 1) {
        setFastestUsed(true);
        setFastestLoading(true);
        setProgramUsed(false);
        fetchAutoProgram();
    } else if( tipo === 2) {
        setFastestUsed(false);
        setProgramUsed(true);
        //setOpenProgram(true);
    }

};

const handleProgram = (tipo) => {
    
    if(tipo === 1) {
        setClaseProgram(1);
        setOpenProgram(true);
    } else if( tipo === 2) {
        setClaseProgram(2);
        setOpenEntProgram(true);
    }

};

const fetchAutoProgram = async () => {
    const apiUrl = `/api/v1/pedidos/dtvalidator`;
    await axios.get(apiUrl).then(response => {
        const recFechaValue = new Date(response.data.recDiaDisponible);
        const entFechaValue = new Date(response.data.entDiaDisponible);

        setRecFecha(recFechaValue);
        setEntFecha(entFechaValue);


        response.data.recBloqueDisponible == 1 ?  setRecBloqueText('10:00 am a 3:00pm') : setRecBloqueText('4:00 pm a 9:00pm');
        response.data.entBloqueDisponible == 1 ?  setEntBloqueText('10:00 am a 3:00pm') : setEntBloqueText('4:00 pm a 9:00pm');

        setRecBloque(response.data.recBloqueDisponible);
        setEntBloque(response.data.entBloqueDisponible);

        formik.setValues({...formik.values,
            origen_fecha: getFecha(recFechaValue),
            origen_bloque: response.data.recBloqueDisponible,
            entrega_fecha: getFecha(entFechaValue),
            entrega_bloque: response.data.entBloqueDisponible,
            });
       
        const timer = setTimeout(() => {
            setFastestLoading(false);
        }, 500);
         });
}

const closeProgramDialog = (tipo,fecha,bloque) => {
    if (tipo == 1) {
        setRecProgramed(true);
        setOpenProgram(false);
        setRecFecha(fecha);
        setRecBloque(bloque);
        bloque == 1 ?  setRecBloqueText('10:00 am a 3:00pm') : setRecBloqueText('4:00 pm a 9:00pm');
        setEntProgramed(false);

        formik.setValues({...formik.values,
            origen_fecha: getFecha(fecha),
            origen_bloque: bloque,
            });
        
    } else if (tipo ==2) {
        setOpenProgram(false);
    }
   
};

const closeEntProgramDialog = (tipo,fecha,bloque) => {
    if (tipo == 1) {
        setEntProgramed(true);
        setOpenEntProgram(false);
        setEntFecha(fecha);
        setEntBloque(bloque);
        bloque == 1 ?  setEntBloqueText('10:00 am a 3:00pm') : setEntBloqueText('4:00 pm a 9:00pm');

        formik.setValues({...formik.values,
            entrega_fecha: getFecha(fecha),
            entrega_bloque: bloque,
            });

    } else if (tipo ==2) {
        setOpenEntProgram(false);
    }
   
};
const resetProgram = (val) => {
    if(val == 3) {
        setRecFecha('');
        setRecBloque('');
        setEntFecha('');
        setEntBloque('');
    } else if (val == 1) {
        setRecFecha('');
        setRecBloque('');
    } else if (val == 2) {
        setEntFecha('');
        setEntBloque('');
    }
    
}

const closeResumenDialog = (value) => {
        setOpenResumen(value);
};
const handleProgramInfo = (value) => {
   
    console.log(value);
};

const handleResumenDialog = async (value) => {
    
    const val = await formik.validateForm();

    if (Object.keys(val).length === 0) {
        const vars = await pedidoSetData();
        setResumenReady(true);
        setOpenResumen(true);
        //console.log(formik.values);
    } else {
        formik.getFieldHelpers();
        //console.log(val);

    }
   

};


const pedidoSetData = async (values) => {
    const v = formik.values;
    const pesoVol = ((v.paq_alto * v.paq_ancho * v.paq_largo)/5000);
    //console.log(v.paq_peso,pesoVol);
    const pesoMax = Math.max((v.paq_peso),pesoVol);
    //console.log(pesoMax);


    formik.setValues({...formik.values,
    guardar_destino_fav: saveFavorito,
    guardar_paquete_fav: savePaquete,
    paq_volumen_max: pesoMax *1000,
    entrega_colonia: formik.values.entrega_otra_colonia == 1 ? v.entrega_otra_colonia_colonia : v.entrega_colonia
    });

    /* if (formik.values.entrega_otra_colonia == 1) {
        formik.setValues({...formik.values, entrega_colonia: formik.values.entrega_otra_colonia_colonia});
    } */


   /*  const apiUrl = `/api/v1/pedidos/react`;
    const apiData = servicioData;
    await axios.post(apiUrl, apiData).then(response => {
        console.log(response.data);
         }); */
}

const handleSnackClose = (value) => {
   setSnackRepOpen(false);
    //console.log(value);
};

const handleSnackActions = (value) => {
   if (value == 1) {
    setFastestUsed(false);
    setProgramUsed(false);
    setOpenResumen(false);
    setSnackOpen(false);
    setSnackRepOpen(true);
   } else if (value == 2) {
    setSnackType('bigTrouble');
   }
    
};
const vertical= 'top' ;
const horizontal = 'center';

const reProgramSnack = (
    <Stack direction="row" justifyContent='space-between' alignItems="center">
        <Typography sx={{color: '#FFFFFF', fontSize: 14}}>Javier, porfavor vuelve a programar tu envío...</Typography>
     </Stack>
);

const gmapsLink = 'https://www.google.com/maps/search/?api=1&query=';

     return (
           
               <form onSubmit={formik.handleSubmit}>
                {/*Origen */}
                    <Grid container spacing={gridSpacing}>
                    <ThemeProvider theme={themeVariants}>
                    <Snackbar
                        variant={snackType}
                        anchorOrigin={{ vertical, horizontal }}
                        open={snackOpen}
                       
                        message={<BloqueTimer actions={handleSnackActions}/>}
                        key={vertical + horizontal}
                        >
                        </Snackbar></ThemeProvider>
                    <Snackbar
                        variant={snackType}
                        anchorOrigin={{ vertical, horizontal }}
                        open={snackRepOpen}
                        autoHideDuration={4000}
                        onClose={handleSnackClose}
                        message={reProgramSnack}
                        key={vertical + horizontal}
                        >
                    </Snackbar>


                        <Grid item xs={12} md={4}>
                            <Stack>
                                <InputLabel required>No. de cliente</InputLabel>
                                <TextField
                                    id="cliente_id"
                                    disabled
                                    name="cliente_id"
                                    value={formik.values.cliente_id}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.cliente_id && Boolean(formik.errors.cliente_id)}
                                    helperText={formik.errors.cliente_id && formik.errors.cliente_id}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    placeholder="No. de cliente"
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center">
                                <HomeIcon sx={{color: "#f44336", fontSize: 26}}/>
                                <Typography variant="h2" sx={{my:1,mx: .5, fontWeight: 800}}>Origen</Typography>
                            </Stack>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center">
                                <Typography variant="h4" sx={{}}>Contacto de recolección</Typography>
                            </Stack>
                          
                        </Grid>
                        
                        <Grid item xs={12} md={4}>
                            
                            <Stack>
                                <InputLabel required>Nombre</InputLabel>
                                <TextField
                                    fullWidth
                                    id="origen_contacto_nombre"
                                    name="origen_contacto_nombre"
                                    value={formik.values.origen_contacto_nombre}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.origen_contacto_nombre && Boolean(formik.errors.origen_contacto_nombre)}
                                    helperText={formik.errors.origen_contacto_nombre && formik.errors.origen_contacto_nombre}
                                    placeholder="Nombre completo"
                                />
                            </Stack>
                        </Grid>
                        
                        <Grid item xs={12} md={4}>
                            <Stack>
                                <InputLabel required>Teléfono</InputLabel>
                                <NumberTextField
                                    type="number"
                                    fullWidth
                                    id="origen_contacto_tel"
                                    name="origen_contacto_tel"
                                    value={formik.values.origen_contacto_tel}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.origen_contacto_tel && Boolean(formik.errors.origen_contacto_tel)}
                                    helperText={formik.errors.origen_contacto_tel && formik.errors.origen_contacto_tel}
                                    onChange={formik.handleChange}
                                    placeholder="+ 00 00000 00000"
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center">
                                <Typography variant="h4" sx={{}}>Dirección de recolección</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Card 
                                sx={{
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100],
                                    padding: '16px 8px 16px',
                                    mb: 1.25
                                }}>
                                <Stack >
                                   { Object.keys(defaultAddress).length >= 1 && <> <Typography variant="h5" sx={{}}>{defaultAddress.nombre}</Typography>
                                    <Typography variant="caption" sx={{}}>{defaultAddress.calle},{defaultAddress.numero} {defaultAddress.numeroInt ? 'Int'+defaultAddress.numeroInt : ''} </Typography>
                                    <Typography variant="caption" sx={{}}>{defaultAddress.colonia}, {defaultAddress.municipio},{defaultAddress.codigo_postal} </Typography>
                                    <Typography variant="caption" sx={{}}>{defaultAddress.referencias}</Typography></>}
                                    
                                </Stack>
                            </Card>
                            <Typography variant="h5" sx={{mt:1 , color: "#2c88fb", cursor: 'pointer', textDecoration: 'none'}} component={Link} onClick={handleChangeAddress}>Cambiar o agregar dirección</Typography>
                            { addressSelected && <AddressDialog open={openAddress} handleCloseDialog={closeAddressDialog} selected={addressSelected}/>}
                        </Grid>
                         {/*destino */}
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center">
                                <LocationOnIcon sx={{color: "#f44336", fontSize: 26}}/>
                                <Typography variant="h2" sx={{my:1,mx: .5, fontWeight: 800}}>Destino</Typography>
                            </Stack>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            {favoritoUsed 
                            ?    <Grid sx={{mt:1}}>
                                    <Typography variant="h5" sx={{color: "#2c88fb", cursor: 'pointer', textDecoration: 'none'}} component={Link} onClick={handleFavoritoCancel}>Cancelar destino favorito</Typography>
                                </Grid>
                            :   <Grid sx={{mt:1}}>
                                    <Typography variant="h5" sx={{color: "#2c88fb", cursor: 'pointer', textDecoration: 'none'}} component={Link} onClick={handleChangeFavorito}>Usar destino favorito</Typography>
                                    <FavoritoDialog open={openFavorito} handleCloseDialog={closeFavoritoDialog} />
                                </Grid>}
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center">
                                <Typography variant="h4" sx={{}}>Contacto de entrega</Typography>
                            </Stack>
                          
                        </Grid>
                        
                        <Grid item xs={12} md={4}>
                            
                            <Stack>
                                <InputLabel required>Nombre</InputLabel>
                                <TextField
                                    fullWidth
                                    id="entrega_contacto_nombre"
                                    name="entrega_contacto_nombre"
                                    value={formik.values.entrega_contacto_nombre}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.entrega_contacto_nombre && Boolean(formik.errors.entrega_contacto_nombre)}
                                    helperText={formik.errors.entrega_contacto_nombre && formik.errors.entrega_contacto_nombre}
                                    placeholder="Nombre completo"
                                    inputProps={
                                        { readOnly: favoritoUsed, }
                                    }
                                />
                            </Stack>
                        </Grid>
                        
                        <Grid item xs={12} md={4}>
                            <Stack>
                                <InputLabel required>Teléfono</InputLabel>
                                <NumberTextField
                                    type="number"
                                    fullWidth
                                    id="entrega_contacto_tel"
                                    name="entrega_contacto_tel"
                                    value={formik.values.entrega_contacto_tel}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.entrega_contacto_tel && Boolean(formik.errors.entrega_contacto_tel)}
                                    helperText={formik.errors.entrega_contacto_tel && formik.errors.entrega_contacto_tel}
                                    onChange={formik.handleChange}
                                    placeholder="+ 00 00000 00000"
                                    inputProps={
                                        { readOnly: favoritoUsed }
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center">
                                <Typography variant="h4" sx={{}}>Dirección de entrega</Typography>
                            </Stack>
                            
                            
                        </Grid>
                        <Grid item xs={12} md={2} lg={2}>
                            <Stack>
                                <InputLabel required>Código postal</InputLabel>
                                <NumberTextField
                                    fullWidth
                                    type="number"
                                    id="entrega_codigo_postal"
                                    name="entrega_codigo_postal"
                                    value={favoritoUsed ? formik.values.entrega_codigo_postal : codigoState}
                                    onChange={(e) => handleDestinoCpChange(e)}
                                    onBlur={formik.handleBlur}
                                    error={destinoCpError}
                                    helperText={destinoCpErrorText}
                                    placeholder="Alex Z."
                                    inputProps={
                                        { readOnly: favoritoUsed, }
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={1} lg={1}>
                            <Stack direction="row" alignItems='flex-end'>
                                <Stack >
                                    <InputLabel sx={{ml:1, color:'white'}}>inválido</InputLabel>
                                    <Button variant="outlined" onClick={handleChangeOpciones} sx={{ml:2, color: "#2c88fb", fontSize: 12, borderColor: "#2c88fb"}} size="small">Opciones</Button>
                                    <FavoritoDialog open={openOpciones} handleCloseDialog={closeOpcionesDialog} />
                                </Stack>
                               
                                
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={1} lg={1}></Grid>
                        <Grid item xs={12} md={8} lg={8}></Grid>
                        <Grid item xs={12} md={4} >
                            <Stack>
                                <InputLabel required>Municipio</InputLabel>
                                <TextField
                                    fullWidth
                                    id="entrega_municipio"
                                    name="entrega_municipio"
                                    value={favoritoUsed ? formik.values.entrega_municipio : destinoMunicipio}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.entrega_municipio && Boolean(formik.errors.entrega_municipio)}
                                    helperText={formik.errors.entrega_municipio && formik.errors.entrega_municipio}
                                    placeholder="alex@company.com"
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </Stack>
                        </Grid>
                        
                        <Grid item xs={12} md={4}>
                            <Stack>
                                <InputLabel required>Colonia</InputLabel>
                                { favoritoUsed
                                    ? <TextField
                                    fullWidth
                                    id="entrega_colonia"
                                    name="entrega_colonia"
                                    value={formik.values.entrega_colonia}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                                    : <Select
                                    disabled={destinoDisabled}
                                    id="entrega_colonia"
                                    name="entrega_colonia"
                                    onChange={handleColoniaChange}
                                    value={coloniaState}
                                    inputProps={
                                        { readOnly: favoritoUsed, placeholder: 'col holder'}
                                    }
                                >
                                    {destinoColonias.length >=1 && destinoColonias.map((col) => {
                                        return (
                                        <MenuItem key={col.id} value={col}>{col.colonia}</MenuItem>
                                        )
                                    })}
                                        <MenuItem value="otra">Otra colonia</MenuItem>
                                </Select>}
                                {formik.errors.entrega_colonia && <FormHelperText error>{formik.errors.entrega_colonia}</FormHelperText>}
                            </Stack>
                           
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {otraColoniaActive && <Stack>
                                <InputLabel required>Otra colonia</InputLabel>
                                <TextField
                                    fullWidth
                                    disabled={destinoDisabled}
                                    id="entrega_otra_colonia_colonia"
                                    name="entrega_otra_colonia_colonia"
                                    value={formik.values.entrega_otra_colonia_colonia}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.entrega_otra_colonia && Boolean(formik.errors.entrega_otra_colonia_colonia)}
                                    helperText={formik.errors.dentrega_otra_colonia && formik.errors.entrega_otra_colonia_colonia}
                                    placeholder="Ingresa el nombre de la colonia"
                                    inputProps={
                                        { readOnly: favoritoUsed, }
                                    }
                                />
                            </Stack>}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack>
                                <InputLabel required>Calle</InputLabel>
                                <TextField
                                    fullWidth
                                    disabled={destinoDisabled}
                                    id="entrega_calle"
                                    name="entrega_calle"
                                    value={formik.values.entrega_calle}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.entrega_calle && Boolean(formik.errors.entrega_calle)}
                                    helperText={formik.errors.entrega_calle && formik.errors.entrega_calle}
                                    placeholder="Alex Z."
                                    inputProps={
                                        { readOnly: favoritoUsed, }
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack>
                                <InputLabel required>Numero</InputLabel>
                                <NumberTextField
                                    type="number"
                                    fullWidth
                                    disabled={destinoDisabled}
                                    id="entrega_numero"
                                    name="entrega_numero"
                                    value={formik.values.entrega_numero}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.entrega_numero && Boolean(formik.errors.entrega_numero)}
                                    helperText={formik.errors.entrega_numero && formik.errors.entrega_numero}
                                    placeholder="alex@company.com"
                                    inputProps={
                                        { readOnly: favoritoUsed, }
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack>
                                <InputLabel required>Numero Interior</InputLabel>
                                <TextField
                                    fullWidth
                                    disabled={destinoDisabled}
                                    id="entrega_numero_int"
                                    name="entrega_numero_int"
                                    value={formik.values.entrega_numero_int}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.entrega_numero_int && Boolean(formik.errors.entrega_numero_int)}
                                    helperText={formik.errors.entrega_numero_int && formik.errors.entrega_numero_int}
                                    onChange={formik.handleChange}
                                    placeholder="+ 00 00000 00000"
                                    inputProps={
                                        { readOnly: favoritoUsed, }
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel required>Empresa</InputLabel>
                                <TextField
                                    fullWidth
                                    disabled={destinoDisabled}
                                    id="entrega_empresa"
                                    name="entrega_empresa"
                                    value={formik.values.entrega_empresa}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.entrega_empresa && Boolean(formik.errors.entrega_empresa)}
                                    helperText={formik.errors.entrega_empresa && formik.errors.entrega_empresa}
                                    placeholder="Alex Z."
                                    inputProps={
                                        { readOnly: favoritoUsed, }
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel required>Entre calles</InputLabel>
                                <TextField
                                    fullWidth
                                    disabled={destinoDisabled}
                                    id="entrega_entre_calles"
                                    name="entrega_entre_calles"
                                    value={formik.values.entrega_entre_calles}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.entrega_entre_calles && Boolean(formik.errors.entrega_entre_calles)}
                                    helperText={formik.errors.entrega_entre_calles && formik.errors.entrega_entre_calles}
                                    placeholder="alex@company.com"
                                    inputProps={
                                        { readOnly: favoritoUsed, }
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel required>Referencias</InputLabel>
                                <TextField
                                    fullWidth
                                    disabled={destinoDisabled}
                                    id="entrega_referencias"
                                    name="entrega_referencias"
                                    value={formik.values.entrega_referencias}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.entrega_referencias && Boolean(formik.errors.entrega_referencias)}
                                    helperText={formik.errors.entrega_referencias && formik.errors.entrega_referencias}
                                    onChange={formik.handleChange}
                                    placeholder="+ 00 00000 00000"
                                    inputProps={
                                        { readOnly: favoritoUsed, }
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                           
                           </Grid>
   
                        {!favoritoUsed && <Grid item xs={12} md={6}>
                            <Stack direction="row"  alignItems='center'>
                                    <FormControlLabel 
                                    control={ <Switch
                                                size="small"
                                                defaultChecked={false} 
                                                onChange={handleSaveFavorito}
                                                />} 
                                    label={
                                    <Typography align="left" variant="body2" >Guardar como destino favorito</Typography>
                                    } sx={{mb:0}}/> 
                            </Stack>
                        </Grid>}



                        {/*Paquete */}
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center">
                                <ViewInArIcon sx={{color: "#f44336", fontSize: 26}}/>
                                <Typography variant="h2" sx={{my:1,mx: .5, fontWeight: 800}}>Paquete</Typography>
                            </Stack>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            {paqueteUsed 
                            ?    <Grid sx={{mt:0}}>
                                    <Typography variant="h5" sx={{color: "#2c88fb", cursor: 'pointer', textDecoration: 'none'}} component={Link} onClick={handlePaqueteCancel}>Cancelar usar mis paquetes</Typography>
                                </Grid>
                            :   <Grid sx={{mt:0}}>
                                    <Typography variant="h5" sx={{color: "#2c88fb", cursor: 'pointer', textDecoration: 'none'}} component={Link} onClick={handleChangePaquete}>Mis paquetes</Typography>
                                    <PaqueteDialog open={openPaquete} handleCloseDialog={closePaqueteDialog} />
                                </Grid>}
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <Grid item xs={12} md={2}>
                                <Stack>
                                    <InputLabel required>Tipo</InputLabel>
                                    <Select
                                        id="paq_tipo_id"
                                        name="paq_tipo_id"
                                        defaultValue={formik.values.paq_tipo_id}
                                        value={formik.values.paq_tipo_id}
                                        onChange={formik.handleChange}
                                        size="small"
                                        inputProps={
                                            { readOnly: paqueteUsed, }
                                        }
                
                                    >
                                        <MenuItem value="1" >
                                            <Stack direction="row" alignItems="center">
                                                <img 
                                                    alt="Caja"
                                                    src="https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fcaja-foto.png?alt=media&token=c266275d-ece9-4f7f-b5f7-71a37a76828c"
                                                    width="35"
                                                />
                                                <Typography variant="h5" sx={{mx: 1.5, fontWeight: 800}}>Caja</Typography>
                                            </Stack>
                                        </MenuItem>
                                        <MenuItem value="2" >
                                            <Stack direction="row" alignItems="center">
                                                <img 
                                                    alt="Bolsa"
                                                    src="https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fbolsa-foto.png?alt=media&token=ccce87e3-e362-4044-9ded-8fda620fffe0"
                                                    width="35"
                                                />
                                                <Typography variant="h5" sx={{mx: 1.5, fontWeight: 800}}>Bolsa</Typography>
                                            </Stack>
                                        </MenuItem>
                                        <MenuItem value="3" >
                                            <Stack direction="row" alignItems="center">
                                                <img 
                                                    alt="Sobre"
                                                    src="https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fsobre-foto.png?alt=media&token=1e6435d2-454f-4d76-80ca-70a5dcf2db0dc"
                                                    width="35"
                                                />
                                                <Typography variant="h5" sx={{mx: 1.5, fontWeight: 800}}>Sobre</Typography>
                                            </Stack>
                                        </MenuItem>
                                    </Select>
                                    {formik.errors.orderStatus && <FormHelperText error>{formik.errors.orderStatus}</FormHelperText>}
                                </Stack>
                            </Grid>
                        </Grid>
                        

                        <Grid item sx={{width: 150}}>
                            <Stack>
                                <InputLabel required>Alto</InputLabel>
                                    <TextField
                                        type="number"
                                        id="paq_alto"
                                        name="paq_alto"
                                        value={formik.values.paq_alto}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.errors.paq_alto && Boolean(formik.errors.paq_alto)}
                                        helperText={formik.errors.paq_alto && formik.errors.paq_alto}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>
                                          }}
                                        inputProps={
                                            { readOnly: paqueteUsed, }
                                        }
                                    
                                    />
                            </Stack>
                        </Grid>
                        <Grid item sx={{width: 150}}>
                            <Stack>
                                <InputLabel required>Ancho</InputLabel>
                                    <TextField
                                        type="number"
                                        id="paq_ancho"
                                        name="paq_ancho"
                                        value={formik.values.paq_ancho}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.errors.paq_ancho && Boolean(formik.errors.paq_ancho)}
                                        helperText={formik.errors.paq_ancho && formik.errors.paq_ancho}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>
                                          }}
                                        inputProps={
                                            { readOnly: paqueteUsed, }
                                        }
                                    />
                            </Stack>
                        </Grid>
                        <Grid item sx={{width: 150}}>
                            <Stack>
                                <InputLabel required>Largo</InputLabel>
                                    <TextField
                                        type="number"
                                        id="paq_largo"
                                        name="paq_largo"
                                        value={formik.values.paq_largo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.errors.paq_largo && Boolean(formik.errors.paq_largo)}
                                        helperText={formik.errors.paq_largo && formik.errors.paq_largo}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>
                                          }}
                                        inputProps={
                                            { readOnly: paqueteUsed, }
                                        }
                                    />
                            </Stack>
                        </Grid>
                        <Grid item sx={{width: 150}}>
                            <Stack>
                                <InputLabel required>Peso</InputLabel>
                                    <TextField
                                        type="number"
                                        id="paq_peso"
                                        name="paq_peso"
                                        value={formik.values.paq_peso}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.errors.paq_peso && Boolean(formik.errors.paq_peso)}
                                        helperText={formik.errors.paq_peso && formik.errors.paq_peso}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Kg</InputAdornment>
                                          }}
                                        inputProps={
                                            { readOnly: paqueteUsed, }
                                        }
                                    />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel required>Contenido</InputLabel>
                                <TextField
                                    fullWidth
                                    id="paq_contenido"
                                    name="paq_contenido"
                                    value={formik.values.paq_contenido}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.paq_contenido && Boolean(formik.errors.paq_contenido)}
                                    helperText={formik.errors.paq_contenido && formik.errors.paq_contenido}
                                    onChange={formik.handleChange}
                                    placeholder="Zapatos de mujer"
                                    inputProps={
                                        { readOnly: paqueteUsed, }
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                           
                        </Grid>

                        {!paqueteUsed && <Grid item xs={12} md={6}>
                        <Stack direction="row"  alignItems='center'>
                                <FormControlLabel 
                                control={ <Switch
                                            size="small"
                                            defaultChecked={false} 
                                            onChange={handlePaqueteSave}
                                            />} 
                                label={
                                <Typography align="left" variant="body2" >Guardar paquete</Typography>
                                } sx={{mb:0}}/> 
                        </Stack>
                        </Grid>}



                        
                        <Grid item xs={12} md={6}>
                           
                        </Grid>

                        {/*Programación */}
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center">
                                <AccessTimeIcon sx={{color: "#f44336", fontSize: 26}}/>
                                <Typography variant="h2" sx={{my:1,mx: .5, fontWeight: 800}}>Programación</Typography>
                            </Stack>
                            <Divider />
                        </Grid>
                        { <ProgramRecDialog open={openProgram} handleCloseDialog={closeProgramDialog} clase={claseProgram} blocked={blockedDates}/>}
                        { isDate(recFecha) && <ProgramEntDialog open={openEntProgram} handleCloseDialog={closeEntProgramDialog} recFechaVal={recFecha} recBloqueVal={recBloque} />}
                        <Grid item xs={8} md={6} lg={2}>
                            <Stack>
                                <Button size="small" variant={fastestUsed ? "contained": "outlined"} sx={{color: "#f44336",textTransform: 'none'}} onClick={() => handleAutoProgram(1)}>
                                    <Typography sx={{  color: fastestUsed ? "#fff" : "#323232", fontWeight: 800, fontSize: matchDownMd ? 12 :14}}>Lo más pronto posible</Typography>
                                </Button>
                               
                            </Stack>
                        </Grid>
                        <Grid item xs={4} md={6} lg={2}>
                            <Stack>
                                <Button size="small" variant={programUsed ? "contained": "outlined"} sx={{color: "#f44336"}} onClick={() => handleAutoProgram(2)}>
                                    <Typography  sx={{ color: programUsed ? "#fff" : "#323232", fontWeight: 800, fontSize: matchDownMd ? 12 : 14}}>Programar</Typography>
                                </Button>
                                
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        
                        </Grid>
                         
                        
                        {fastestUsed  && <><Grid item xs={12} md={6} lg={2.5} >
                            <SubCard sx={{border:1, borderColor: '#CFD2CF'}}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Box
                                            sx={{
                                                ...sideAvatarSX,
                                                bgcolor: theme.palette.mode === 'dark' ? theme.palette.primary.main + 20 : 'primary.light',
                                                border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                                borderColor: 'primary.main',
                                                color: 'primary.dark'
                                            }}
                                        >
                                           <HomeIcon /> 
                                        </Box>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography variant="h4"  component="div" >Recolección</Typography>                     
                                        {fastestLoading ? <Grid item xs={4} sm={2} lg={6} sx={{ mt: 1, ml: 1}}>
                                                            <LinearProgress />
                                                        </Grid> : 
                                                        <Stack>
                                                            <Typography variant="smallsub" sx={{color: "#2c88fb"}}>{isDate(recFecha) ? namedDate(recFecha) : ''}</Typography>
                                                            <Typography variant="smallsub" sx={{color: "#2c88fb"}}>{recBloqueText}</Typography>
                                                        </Stack>}
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2.5} >
                            <SubCard sx={{border:1, borderColor: '#CFD2CF'}}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Box
                                            sx={{
                                                ...sideAvatarSX,
                                                bgcolor: theme.palette.mode === 'dark' ? theme.palette.primary.main + 20 : 'primary.light',
                                                border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                                borderColor: 'primary.main',
                                                color: 'primary.dark'
                                            }}
                                        >
                                        <HomeIcon /> 
                                        </Box>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography variant="h4"  component="div" >Entrega</Typography>                     
                                        {fastestLoading ? <Grid item xs={4} sm={2} lg={6} sx={{ mt: 1, ml: 1}}>
                                                            <LinearProgress />
                                                        </Grid> : 
                                                        <Stack>
                                                            <Typography variant="smallsub" sx={{color: "#2c88fb"}}>{isDate(entFecha) ? namedDate(entFecha) : ''}</Typography>
                                                            <Typography variant="smallsub" sx={{color: "#2c88fb"}}>{entBloqueText}</Typography>
                                                        </Stack>}
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid></>}
                        {programUsed  && <><Grid item xs={12} md={6} lg={2.5} >
                            <SubCard sx={{border:1, borderColor: '#CFD2CF'}}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Box
                                            sx={{
                                                ...sideAvatarSX,
                                                bgcolor: theme.palette.mode === 'dark' ? theme.palette.primary.main + 20 : 'primary.light',
                                                border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                                borderColor: 'primary.main',
                                                color: 'primary.dark'
                                            }}
                                        >
                                           <HomeIcon /> 
                                        </Box>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography variant="h4"  component="div" >Recolección</Typography>                     
                                        {!recProgramed ? <Button variant="outlined" size="small" onClick={() => handleProgram(1)} 
                                            sx={{fontSize:11,color: "#2c88fb", borderColor: "#2c88fb"}}>Programar</Button> : <Stack>
                                            <Typography variant="smallsub" sx={{}}>{isDate(recFecha) ? namedDate(recFecha) : ''}</Typography>
                                            <Typography variant="smallsub" sx={{}}>{recBloqueText}</Typography>
                                            <Typography variant="h5" sx={{color: "#2c88fb", cursor: 'pointer', textDecoration: 'none', fontSize: 11}} component={Link} onClick={() => handleProgram(1)}>Cambiar</Typography>
                                            
                                        </Stack>}
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12} md={6} lg={2.5} >
                            <SubCard sx={{border:1, borderColor: '#CFD2CF'}}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Box
                                            sx={{
                                                ...sideAvatarSX,
                                                bgcolor: theme.palette.mode === 'dark' ? theme.palette.primary.main + 20 : 'primary.light',
                                                border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                                borderColor: 'primary.main',
                                                color: 'primary.dark'
                                            }}
                                        >
                                        <HomeIcon /> 
                                        </Box>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography variant="h4"  component="div" >Entrega</Typography>                     
                                        {!entProgramed ? <Button variant="outlined" size="small" disabled={!recProgramed} onClick={() => handleProgram(2)} 
                                                sx={{fontSize:11,color: "#2c88fb", borderColor: "#2c88fb"}}>Programar</Button> : <Stack>
                                            <Typography variant="smallsub" sx={{}}>{isDate(entFecha) ? namedDate(entFecha) : ''}</Typography>
                                            <Typography variant="smallsub" sx={{}}>{entBloqueText}</Typography>
                                            <Typography variant="h5" sx={{color: "#2c88fb", cursor: 'pointer', textDecoration: 'none', fontSize: 11}} component={Link} onClick={() => handleProgram(2)}>Cambiar</Typography>
                                        </Stack>}
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid></>}
                        <Grid item xs={12} md={6} lg={6}>
                           
                           </Grid>
                           <Grid item xs={12} md={6} lg={6}>
                           
                           </Grid>
                        <Grid item xs={12} md={6}>
                            {formik.errors.origen_fecha && <FormHelperText error>{formik.errors.origen_fecha}</FormHelperText>}
                            {formik.errors.entrega_fecha && <FormHelperText error>{formik.errors.entrega_fecha}</FormHelperText>}
                       
                        </Grid>
                        


                         {/*Origen */}
                         {resumenReady && <ResumenDialog open={openResumen} handleCloseDialog={closeResumenDialog} pedidoData={formik.values}/>}
                        <Grid item sx={{ display: 'flex', justifyContent: 'flex-start' }} xs={12}>
                            <Button variant="contained"  sx={{backgroundColor: "#2c88fb"}} onClick={() => handleResumenDialog(true)}>
                               Siguiente 
                            </Button>
                            {/* <Button variant="contained" type="submit" sx={{backgroundColor: "#2c88fb"}}>
                                Generar pedido
                            </Button> */}
                        </Grid>
                        <Grid item>
                            <Dialog open={open}>
                                <DialogContent>
                                    <DialogContentText sx={{ fontWeight: 500, color: `secondary.dark` }}>
                                        Pedido creado exitosamente
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ pr: '20px' }}>
                                    <Button autoFocus variant="contained" onClick={handleDialogOk}>
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Grid>
               </form>
            
   
            
        );




    }
    


export default CrearPedidoMain