import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';



// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn, user, logged, setLogged } = useAuth();
    const navigate = useNavigate();


  

    useEffect(() => {
        //console.log('use auth');
        //console.log(user);
        //console.log(logged);
        if (!isLoggedIn) {
            navigate('/login', { replace: true });
        }

    
        /* if (isLoggedIn && !logged && user.roleId === 3) {
           
            navigate('/ops', { replace: true });
           setLogged();
        } */
    }, [isLoggedIn, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
