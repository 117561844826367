import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Typography, Stack, Link, useMediaQuery, LinearProgress, IconButton, Collapse, Box } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { namedDateFull, namedDateFullPunteo } from 'utils/helpers';

import StarRoundedIcon from '@mui/icons-material/StarRounded';
import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import OfflinePinRoundedIcon from '@mui/icons-material/OfflinePinRounded';
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';
import PaidIcon from '@mui/icons-material/Paid';
import WhereToVoteRoundedIcon from '@mui/icons-material/WhereToVoteRounded';

import EvidenciaDialog from './EvidenciaDialog';

const ActividadColumn = ({ data, guia }) => {
    let params = useParams();
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('md'));
    const componentRef = useRef(null);
    const [pedidoId, setPedidoId] = useState(params.id);
    const [isView, setIsView] = React.useState(false);
    const [pedidosData, setPedidosData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [pdfDisplay, setPdfDisplay] = React.useState('none');

    React.useEffect(() => {
        console.log(data);
    }, []);

    const handleOnRender = (e) => {
        console.log(e);
        setIsLoading(false);
    };

    return (
        <>
            <Stack direction="column" alignItems="flex-start" sx={{ ml: small ? 0 : 2, width: '100%' }}>
                {data.map((a, i) => {
                    const [open, setOpen] = React.useState(false);
                    const [openEvDialog, setOpenEvDialog] = useState(false);

                    const handleOpenDialog = (event) => {
                        setOpenEvDialog(true);
                    };

                    const handleClose = (event) => {
                        setOpenEvDialog(false);
                    };

                    const renderIcon = () => {
                        switch (a.cat) {
                            case 1:
                                return <StarRoundedIcon sx={{ fontSize: 22, color: '#FFB200' }} />;

                            case 2:
                                return <OfflinePinRoundedIcon sx={{ fontSize: 22, color: '#73777B' }} />;
                            case 3:
                                return <RateReviewRoundedIcon sx={{ fontSize: 20, color: '#73777B' }} />;
                            case 4:
                                return <CheckCircleIcon sx={{ fontSize: 20, color: '#019267' }} />;
                            case 5:
                                return <CancelRoundedIcon sx={{ fontSize: 20, color: '#FF1E1E' }} />;
                            case 6:
                                return <FmdBadOutlinedIcon sx={{ fontSize: 20, color: '#F49D1A' }} />;
                            case 7:
                                return <PaidIcon sx={{ fontSize: 20, color: '#019267' }} />;
                            case 9:
                                return <WhereToVoteRoundedIcon sx={{ fontSize: 24, color: '#019267' }} />;
                            default:
                                return <CircleIcon sx={{ fontSize: 20, color: '#5F6F94' }} />;
                        }
                    };

                    return (
                        <Grid key={i} sx={{ my: 1.5, mx: 0, width: '100%' }}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mx: 0, my: 0 }}>
                                <Stack direction="column" alignItems="center">
                                    <Stack direction="row" alignItems="center" sx={{ my: 0, mx: 0, width: '100%' }}>
                                        {renderIcon()}
                                        <Stack direction="column" alignItems="flex-start" sx={{ my: 0, ml: 1 }}>
                                            <Typography variant="h6" sx={{ fontSize: 12, fontWeight: 600 }}>
                                                {a.act_nombre}
                                            </Typography>
                                            <Typography variant="smallsub" sx={{ mt: 0 }}>
                                                {namedDateFull(new Date(a.act_dtime))}
                                            </Typography>
                                            {a.act_info && a.act_info.zona && (
                                                <Typography variant="h6" sx={{ mt: 0, color: theme.palette.text.secondary, fontSize: 10 }}>
                                                    {a.act_info.zona}
                                                </Typography>
                                            )}
                                            {a.act_info && a.act_info.motivo && (
                                                <Typography variant="h6" sx={{ mt: 0, color: '#E64848', fontSize: 10 }}>
                                                    {a.act_info.motivo}
                                                </Typography>
                                            )}
                                            {a.act_info && a.act_info.entregado_a && (
                                                <Typography variant="h6" sx={{ mt: 0, color: '#019267', fontSize: 10 }}>
                                                    Recibió: {a.act_info.entregado_a}
                                                </Typography>
                                            )}
                                            {a.act_info && a.act_info.operador && (
                                                <Typography variant="h6" sx={{ mt: 0, color: '#019267', fontSize: 10 }}>
                                                    Despachado a: {a.act_info.operador}
                                                </Typography>
                                            )}
                                            <Stack direction="row" alignItems="center" sx={{ my: 0, mx: 0 }}>
                                                <Typography variant="h6" color="secondary" sx={{ fontSize: 10, fontWeight: 600 }}>
                                                    {a.act_by}
                                                </Typography>
                                                <div style={{ display: 'flex' }}>
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            ml: 1,
                                                            pt: 0.3,
                                                            pb: 0.24,
                                                            px: 0.6,
                                                            fontWeight: 400,
                                                            fontSize: 9,
                                                            lineHeight: 1,
                                                            backgroundColor: a.cat == 1 ? '#203461' : '#774360',
                                                            borderRadius: 1,
                                                            color: '#FFF',
                                                            display: 'flex'
                                                        }}
                                                    >
                                                        {a.cat == 1 ? 'Cliente' : 'Operaciones'}
                                                    </Typography>
                                                </div>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                {(a.nuevo || a.tipo == 62 || a.tipo == 63 || a.tipo == 9 || a.tipo == 6 || a.tipo == 7) && (
                                    <IconButton aria-label="expand row" size="small" sx={{ mr: 1 }} onClick={() => setOpen(!open)}>
                                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                )}
                            </Stack>
                            {openEvDialog && (a.tipo == 62 || a.tipo == 63 || a.tipo == 9) && (
                                <EvidenciaDialog open={openEvDialog} handleCloseDialog={handleClose} data={a} guia={guia} />
                            )}
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                {a.nuevo && (
                                    <Grid sx={{ ml: small ? 4 : 4, mt: 0.5 }}>
                                        <Stack>
                                            <Typography variant="smallsub" sx={{ mt: 0, fontSize: 10, color: '#203461', fontWeight: 600 }}>
                                                Anterior
                                            </Typography>
                                            <Typography variant="smallsub" sx={{ mt: 0 }}>
                                                {a.anterior}
                                            </Typography>
                                            <Typography variant="h6" sx={{ mt: 0, fontSize: 10, color: '#203461', fontWeight: 600 }}>
                                                Nuevo
                                            </Typography>
                                            <Typography variant="smallsub" sx={{ mt: 0 }}>
                                                {a.nuevo}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                )}

                                {(a.tipo == 62 || a.tipo == 63) && (
                                    <Grid container sx={{ ml: 4, cursor: 'pointer' }} onClick={handleOpenDialog}>
                                        {a.act_info.fotos.map((f, i) => {
                                            return (
                                                <Grid key={i} sx={{ mx: 0.5 }}>
                                                    <img src={f.foto_url} width={70}></img>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                )}

                                {a.tipo == 9 && (
                                    <>
                                        <Grid container sx={{ ml: 4, cursor: 'pointer' }} onClick={handleOpenDialog}>
                                            <Grid item sx={{ mx: 1 }}>
                                                {a.act_info?.gps_image && <img src={a.act_info.gps_image} width={70}></img>}
                                            </Grid>
                                            {a.act_info.fotos.map((f, i) => {
                                                return (
                                                    <Grid key={i} sx={{ mx: 0.5 }}>
                                                        <img src={f.foto_url} width={70}></img>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </>
                                )}

                                {a.tipo == 6 ||
                                    (a.tipo == 7 && (
                                        <>
                                            <Stack direction="column" sx={{ ml: 4 }}>
                                                <Typography variant="h6" sx={{ mt: 2 }}>
                                                    Despacho:
                                                </Typography>
                                                <Typography variant="smallsub" sx={{ mt: 0 }}>
                                                    {a.act_info.punteo_bloque_nombre}
                                                </Typography>
                                                <Typography variant="smallsub" sx={{ mt: 0 }}>
                                                    Bloque {a.act_info.punteo_bloque_bloque}
                                                </Typography>
                                                <Typography variant="smallsub" sx={{ mt: 0 }}>
                                                    Despacho id: {a.act_info.punteo_bloque_id}
                                                </Typography>
                                            </Stack>
                                        </>
                                    ))}
                            </Collapse>
                        </Grid>
                    );
                })}
            </Stack>
        </>
    );
};

export default ActividadColumn;
