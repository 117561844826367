// third-party
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

// assets
import {
    IconDashboard,
    IconDeviceAnalytics,
    IconFileExport,
    IconListDetails,
    IconSquarePlus,
    IconPrinter,
    IconListCheck,
    IconSearch,
    IconBarcode,
    IconRoad,
    IconPackgeImport,
    IconMap,
    IconMap2
} from '@tabler/icons';

import DatabaseIcon from '../ui-component/icons/DatabaseIcon';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconFileExport,
    IconListDetails,
    IconSquarePlus,
    AddCircleOutlineIcon,
    IconPrinter,
    IconListCheck,
    IconSearch,
    IconBarcode,
    IconRoad,
    IconPackgeImport,
    IconMap,
    IconMap2
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const operaciones = {
    id: 'operaciones',
    title: <Typography variant="body">Operaciones</Typography>,
    type: 'group',
    children: [
        {
            id: 'punteo',
            title: <Typography variant="body">Punteo</Typography>,
            type: 'collapse',
            icon: icons.IconBarcode,
            children: [
                {
                    id: 'punteorec',
                    title: <Typography variant="body">Punteo recolecciones</Typography>,
                    type: 'item',
                    url: '/opspvrec',
                    icon: icons.IconBarcode,
                    breadcrumbs: false
                },
                {
                    id: 'punteorec',
                    title: <Typography variant="body">Punteo entregas</Typography>,
                    type: 'item',
                    url: '/opspvrec',
                    icon: icons.IconBarcode,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'recibo',
            title: <Typography variant="body">Recibo</Typography>,
            type: 'collapse',
            icon: icons.IconPackgeImport,
            children: [
                {
                    id: 'routes',
                    title: <Typography variant="body">Recorridos</Typography>,
                    type: 'item',
                    url: '/recorridos',
                    icon: icons.IconRoad,
                    breadcrumbs: false
                },
                {
                    id: 'reporteDespacho',
                    title: <Typography variant="body">Reporte despacho</Typography>,
                    type: 'item',
                    url: '/repdespacho',
                    icon: icons.IconListDetails,
                    breadcrumbs: false
                }
                /*  {
                    id: 'routes-map',
                    title: <Typography variant='body'>Mapa recorridos</Typography>,
                    type: 'item',
                    url: '/routesmap',
                    icon: icons.IconMap2,
                    breadcrumbs: false
                } */
            ]
        },
        {
            id: 'reportes',
            title: <Typography variant="body">Reportes</Typography>,
            type: 'collapse',
            icon: icons.IconPackgeImport,
            children: [
                {
                    id: 'routes',
                    title: <Typography variant="body">Guías sin movimiento</Typography>,
                    type: 'item',
                    url: '/repopsguias',
                    icon: icons.IconRoad,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default operaciones;
