import React, { useState, useEffect, Fragment } from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
    BlobProvider,
    Svg,
    G,
    Polygon,
    PDFDownloadLink,
    Font
  } from "@react-pdf/renderer";

import { Grid, Button, Typography, Link, Avatar, InputAdornment } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IconRotate, IconShare} from '@tabler/icons';
import hawkLogo from 'assets/images/hawk4.png';
import lmLogoNew from 'assets/images/lm-logo-new2.png';
import { useTheme } from '@mui/material/styles';
import {useMediaQuery} from '@mui/material';
import { namedDateFull } from 'utils/helpers';
import {shouldForwardProp } from '@mui/system';
import { styled } from '@mui/material/styles';


  const EvidenciaPdf = ({evento,guia, create}) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));



    Font.register({
        family: 'Poppins',
        fonts: [
            {
                src: "https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2FPoppins-Regular.ttf?alt=media&token=1ccd49d2-0ff6-42c5-a692-4e79a0850f7b",
                fontWeight: 400
            },
            {
                src: "https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2FPoppins-Bold.ttf?alt=media&token=856876c7-1f10-4d2f-8baf-6101415a74dc",
                fontWeigth: 600
            }
        ]
    });

    React.useEffect(() => {
        //console.log(evento.fotos)
       //getBase64FromUrl(evento.gpsImage);
        
        //console.log(getBase64FromUrl(evento.gpsImage));
        }, []);

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            const base64data = reader.result;  
            resolve(base64data);
          }
        });
      }

    const guiaDoc = (
        <Document>
            <Page size="" style={styles.page} pageNumber={0} key={0}>
                <View style={styles.section}>
                    <View >
                        <Image style={styles.logoPortada} src={lmLogoNew} />
                    </View>
                    <View style={styles.divider} />
                    <View style={styles.rowContainer}>
                        <View style={styles.mainColumnContainer}>
                            <Text style={styles.titleText}>{evento?.nombre}</Text>
                            <Text style={styles.bodyText}>No. Guía: {evento?.guia}</Text>
                            <View style={styles.dataBox}>
                                <Text style={styles.addrText}>{evento?.calleNum}</Text>
                                <Text style={styles.addrText}>{evento?.colMun}</Text>
                                <View style={{paddingTop: 4}} />
                                <Text style={styles.boxText}>{namedDateFull(new Date(evento?.time))}</Text>
                                <Text style={styles.boxText}>{evento?.text}</Text>
                                <Text style={styles.boxText}>{evento?.notas}</Text>
                                <View style={{paddingTop: 4}} />
                                <Text style={styles.boxTitleText}>Entregado por:</Text>
                                <View style={{paddingTop: 2}} />
                                <View style={styles.rowSimple}>
                                    <Image style={styles.byImage} src={evento?.byImage}/>
                                    <Text style={styles.boxText}>{evento?.by}</Text>
                                </View>
                            </View>
                        </View>
                        {evento.gpsImage && <Image style={styles.gpsImage} src={evento?.gpsImage}/>}
                    </View>
                    <View style={styles.divider} />
                    <View style={styles.rowEvImage}>
                        
                            {evento.fotos.map((f,i) => {
                                return (
                                    <View style={styles.evImageColumn}>
                                        <Image style={styles.evImage} src={f.b64}/>
                                    </View>
                                )
                            })}
                        
                    </View>
                
                    <View style={styles.footerContainer}>
                        <View style={styles.logoRow}>
                        <Text style={styles.hawkText} ></Text>
                        <View style={styles.rowSimple}>
                            <Image style={styles.logoFooter} src={hawkLogo}/>
                            <Text style={styles.hawkText} >HAWK</Text>
                        </View>
                            
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );

    return (
        <>
            {create && <PDFDownloadLink
                document={guiaDoc}
                fileName={`guiareporte-${evento.tipo}_${evento.guia}.pdf`}>
                        {({ blob, url, loading, error }) => {
                            //console.log(loading);
                            //console.log(error);
                            return (
                                <InputAdornment position="end" >
                                    <HeaderAvatarStyle variant="rounded">
                                        <IconShare stroke={2} size="1.5rem" color ={theme.palette.secondary.main} sx={{p:1, color: theme.palette.primary.main}}/>
                                    </HeaderAvatarStyle>
                                </InputAdornment>
                            );
                        }
                        
                        }
            </PDFDownloadLink>}
        </>
    );

    
  }
  const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Poppins',
        fontSize: 11,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    section: {
        height: '98%',
        width: '100%',
        position: 'relative'
      },
    divider: {
        height:1,
        borderBottom: .5,
        borderColor: '#DDDDDD',
        marginTop: 10,
        marginBottom: 10
      },    
    logoPortada: {
        width: 130,
        marginBottom: 0
      },
    rowContainer:{
        flexDirection: 'row',
        padding: 0, 
        justifyContent: 'space-between',
        width: '100%'
    },
    rowSimple:{
        flexDirection: 'row',
        padding: 0, 
        alignItems: 'center'
    },
    mainColumnContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginRight: 15,
        width: '55%'
        },
    titleText: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1.2
    },
    bodyText: {
        fontSize: 12,
        color: '#616161'
    },
    boxText: {
        fontSize: 9,
        color: '#616161'
    },
    boxTitleText: {
        fontSize: 9,
        fontWeight: 400,
    },
    addrText: {
        fontSize: 8,
        color: '#1c2f59'
    },
    dataBox: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: 8,
        borderRadius: 6,
        backgroundColor: '#EEEEEE',
        marginVertical: 10, 
        width: '80%'
    },
    byImage: {
        width: 30,
        borderRadius: '50%',
        marginLeft: 2,
        marginRight: 4
      },
    gpsImage: {
        width: 200,
        marginLeft: 2,
        marginRight: 4
      },
    evImageColumn: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginHorizontal: 8,
        width: '30%',
        height: '100%'
        },
    rowEvImage:{
            flexDirection: 'row',
            padding: 0, 
            alignItems: 'flex-start'
        },
    evImage : {
        width: '100%',
        borderRadius: 4,
        //transform: 'rotate(90deg)'
    },
    footerContainer: {
        position: 'absolute',
        bottom: 0,
        borderTop: 1,
        borderColor: '#DDDDDD',

        width: '100%'
    },
    logoRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: 4, 
        alignItems: 'center',
        padding: 4
    },
    logoFooter: {
        height: 30,
    },
    hawkText: {
        fontSize: 11,
        lineHeight: 1,
        fontWeight: 600,
        color: "000",
        marginBottom: 5,
        marginLeft: 0
    },
});

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    background: theme.palette.grey[200],
    color: theme.palette.secondary.main,
    '&:hover': {
        background: theme.palette.grey[400],
        color: theme.palette.secondary.main
    }
}));
  export default EvidenciaPdf;