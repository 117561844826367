// material-ui
import { useTheme } from '@mui/material/styles';
import logoDark from 'assets/images/lm-logo-new.png';
import logo from 'assets/images/lm-logo-n.png';


/**
 * if you want to use image instead of <svg> uncomment following.
 *

 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
         <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" height="40" />
    
    );
};

export default Logo;
