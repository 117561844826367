import React, { useState } from "react";
import { Grid, Button, Dialog, IconButton,  Slide, Typography, Card, CardActions, CardContent,
    CardHeader, CardMedia , Chip, useMediaQuery,LinearProgress, Fade, Stack, Divider, 
    FormControl, Radio, RadioGroup, FormControlLabel, Avatar} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTheme, styled } from '@mui/material/styles';
import { keyframes } from "@emotion/react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import hawkEagle from 'assets/images/hawk-lm.png';
import animatedCheck from 'assets/images/icons/acheck2.gif';

import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
    EmailIcon
  } from "react-share";







export default function PedidoConfirm({pedidoId}) {

    const [isLoading,setIsLoading] = useState(true);
    const [successTitle,setSuccessTitle] = useState(false);

    const shareUrl = `http://hawk.lamensajeria.mx/rastreo/${pedidoId}`;
    const title = `Rastreo La mensajería MX`;

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
            const timer = setTimeout(() => {
                setSuccessTitle(true);
            }, 1500);
        }, 2000);
    }, [pedidoId]);

    React.useEffect(() => {
        
    }, []);

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const handleFinishPedido = () => {
    
    };
    


    return (
       <Grid container sx={{mt:0}}>
            <Grid item xs={12} md={8} lg={3} ></Grid>
            <Grid item xs={12} md={8} lg={6} sx={{mx: matchDownMd ? 1.5 : 0}} >
            
                {isLoading ? 
                    <Stack>
                        <Typography variant="h2" sx={{mt:2}} align="center">Generando pedido...</Typography>
                        <LinearProgress />
                        
                    </Stack>

                : <Stack>
                        <div style={{textAlign: 'center'}}>
                        <img src={animatedCheck} height={matchDownMd ? 150 : '100%'} width={matchDownMd ? 200 : '100%'}></img>
                        </div>
                        {successTitle &&  <Grid>
                                <Typography variant="body2" sx={{mt:0}} align="center">Gracias por confiar en La Mensajería.mx, tu envío  
                                ha sido programado exitosamente.  </Typography>
                                <Typography variant="body2" sx={{mt:2}} align="center">No. de guía</Typography>
                                <Typography variant="h2" sx={{mt:0}} align="center">{pedidoId}</Typography>

                                <Typography variant="body2" sx={{mt:2}} align="center">Rastreo</Typography>
                                <Stack direction="row" alignItems="center" justifyContent="center">
                                    <Typography variant={matchDownMd ? "h6": "h4"} sx={{mt:0}} align="center">{'http://hawk.lamensajeria.mx/rastreo/'+pedidoId}</Typography>
                                    <IconButton>
                                        <ContentCopyIcon sx={{fontSize: 20}}/>
                                    </IconButton>
                                </Stack>
                               
                                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{mt:1}}>
                                    <WhatsappShareButton
                                        url={shareUrl}
                                        title={title}
                                        separator=":: "
                                    >
                                        <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>
                                    <EmailShareButton
                                        url={shareUrl}
                                        subject={title}
                                        body={'Rastreo del número de guía ' +pedidoId}
                                    >
                                        <EmailIcon size={32} round />
                                    </EmailShareButton>
                                    <FacebookShareButton
                                        url={shareUrl}
                                        quote={title}
                                    >
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                </Stack>
                       
                                <Stack direction="row" alignItems="center" justifyContent='center' sx={{mt:3}}>
                                                
                                                <Button 
                                                    variant="contained" 
                                            
                                                    color= 'primary'
                                                    component={Link}
                                                     to={`/pedidos/${pedidoId}`}
                                                    sx={{textTransform: 'none'}}
                                                    >
                                                    Cerrar
                                                </Button>
                                            </Stack>

                            </Grid>}
                 </Stack>
                    
                    }
            </Grid>
            <Grid item xs={12} md={8} lg={3} ></Grid>
       </Grid>
    );

}