
import { useTheme } from '@mui/material/styles';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import {
    Grid,
    Stack,
    Typography,
    useMediaQuery,

} from '@mui/material';

export default function TipoPaquete({tipo,tipoNombre}) {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

return (
    <Grid >
        <Stack direction="row" alignItems="flex-end">
            <ViewInArIcon sx={{fontSize: matchDownMd ? 15 : 20, color: tipo == 1? "#f44336" : theme.palette.grey[400]}}/>
            <ViewInArIcon sx={{fontSize: matchDownMd ? 20 : 28, color: tipo == 2? "#f44336" : theme.palette.grey[400]}} />
            <ViewInArIcon sx={{fontSize: matchDownMd ? 28 : 34, color: tipo == 3? "#f44336" : theme.palette.grey[400]}}/>
            <ViewInArIcon sx={{fontSize: matchDownMd ? 34 : 42, color: tipo == 4? "#f44336" : theme.palette.grey[400]}}/>
        </Stack>
        <Typography variant={matchDownMd ? "h5" :"h4"}>Paquete {tipoNombre}</Typography>
    </Grid>
);
}