
import React,{useState,useEffect, useRef, createRef} from 'react'
// material-ui
import { Card, CardContent, Grid, Typography, Box, Stack, Button } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';

// ===========================|| HOVER SOCIAL CARD ||=========================== //

const NumPad = ({ handleOutput, size, color, cantidad }) => {
    const [numero,setNumero] = React.useState('0');
    const [amount,setAmount] = React.useState(cantidad);
    const [clickedBgColor, setClickedBgColor] = React.useState({
        cero: "#FFF",
        uno: "#FFF",
        dos: "#FFF",
        tres: "#FFF",
        cuatro: "#FFF",
        cinco: "#FFF",
        seis: "#FFF",
        siete: "#FFF",
        ocho: "#FFF",
        nueve: "#FFF",
        back: "#FFF",
    });

    const [clickedColor, setClickedColor] = React.useState({
        cero: "#413F42",
        uno: "#413F42",
        dos: "#413F42",
        tres: "#413F42",
        cuatro: "#413F42",
        cinco: "#413F42",
        seis: "#413F42",
        siete: "#413F42",
        ocho: "#413F42",
        nueve: "#413F42",
        back: "#413F42",
    });


    let cardWidth = 260;
    let widthNums = 70;
    let heightNums = 50;
    let paddingNums = 1;
    let marginNums = .5; 
    let padbgColor = "#FFF"
    let bcNums = '#413F42';
    let numsColor = '#413F42';

    if (size == 'large') {
        cardWidth = 300;
        widthNums = 60;
        heightNums = 60;
        paddingNums = 1;
        marginNums = 0; 
    }

    if (color == 'dark') {
        padbgColor = "#121212"
        bcNums = '#413F42';
        numsColor = '#413F42';
    }

    React.useEffect(() => {
       console.log(cantidad);
    }, [cantidad]);

    const handleNumClick = (num,name) => {
        setClickedColor({...clickedColor,[name]: '#FFF'});
        setClickedBgColor({...clickedBgColor,[name]: '#413F42'});
        const timer = setTimeout(() => {
            setClickedBgColor({...clickedBgColor,[name]: '#FFF'});
            setClickedColor({...clickedColor,[name]: '#413F42'});
            }, 100);

        var numeroString = numero;
        var edited;

        if (num === 10) {
            edited = numeroString.slice(0,-1);
            setNumero(edited);
            setAmount(edited);
            
        } else {
            var numString = num.toString();
            if (numero == '0') {
                setNumero(numString);
                setAmount(numString);
            } else {
                setNumero(numero+numString);
                setAmount(numero+numString);
            }
           
        }
       
        //console.log(parseFloat(numeroString+numString));

       
    
        //handleOutput(parseFloat(numero+numString));
    }

    const handleSave = () => {
        var saveAmount = amount;
        setAmount(0);
        handleOutput(amount);
        
    }
   

    return (
        <Card
           sx={{
            width: cardWidth,
            bgcolor: padbgColor
           }}
        >
            
                <Stack sx={{p:1}}>
                    <Box sx={{height: 55 ,border: 2, borderColor: "#323232", borderRadius: 2, mb: 1 } }>
                        <Typography variant="h1" align="right" sx={{color: clickedColor.uno, mt:1, mr:1}}>{amount}</Typography>
                    </Box>
                    <Grid sx={{p:1,border: 2, borderColor: "#323232", borderRadius: 2}}>
                    <Stack direction="row"   justifyContent="space-between" alignItems="center" sx={{mx:0, my: 0}} >
                        <Box sx={{border:2,borderRadius: 2,borderColor: bcNums,  width: widthNums, height: heightNums , m: marginNums,
                            pt:paddingNums,
                            cursor: 'pointer',
                            bgcolor: clickedBgColor.uno,
                            }}
                            onClick={() => handleNumClick(1,'uno')}
                            >
                            <Typography variant="h1" align="center" sx={{color: clickedColor.uno}}>1</Typography>
                        </Box>
                        <Box sx={{border:2,borderRadius: 2,borderColor: bcNums,  width: widthNums, height: heightNums , m: marginNums,
                            pt:paddingNums,
                            cursor: 'pointer',
                            bgcolor: clickedBgColor.dos
                            }}
                            onClick={() => handleNumClick(2,'dos')}
                            >
                            <Typography variant="h1" align="center" sx={{color: clickedColor.dos}}>2</Typography>
                        </Box>
                        <Box sx={{border:2,borderRadius: 2,borderColor: bcNums,  width: widthNums, height: heightNums , m: marginNums,
                            pt:paddingNums,
                            cursor: 'pointer',
                            bgcolor: clickedBgColor.tres
                            }}
                            onClick={() => handleNumClick(3,'tres')}
                            >
                            <Typography variant="h1" align="center" sx={{color: clickedColor.tres}}>3</Typography>
                        </Box>
                    </Stack>
                    <Stack direction="row"   justifyContent="space-between" alignItems="center" sx={{mx:0, my: 0}}>
                    <Box sx={{border:2,borderRadius: 2,borderColor: bcNums,  width: widthNums, height: heightNums , m: marginNums,
                            pt:paddingNums,
                            cursor: 'pointer',
                            bgcolor: clickedBgColor.cuatro,
                            }}
                            onClick={() => handleNumClick(4,'cuatro')}
                            >
                            <Typography variant="h1" align="center" sx={{color: clickedColor.cuatro}}>4</Typography>
                        </Box>
                        <Box sx={{border:2,borderRadius: 2,borderColor: bcNums,  width: widthNums, height: heightNums , m: marginNums,
                            pt:paddingNums,
                            cursor: 'pointer',
                            bgcolor: clickedBgColor.cinco
                            }}
                            onClick={() => handleNumClick(5,'cinco')}
                            >
                            <Typography variant="h1" align="center" sx={{color: clickedColor.cinco}}>5</Typography>
                        </Box>
                        <Box sx={{border:2,borderRadius: 2,borderColor: bcNums,  width: widthNums, height: heightNums , m: marginNums,
                            pt:paddingNums,
                            cursor: 'pointer',
                            bgcolor: clickedBgColor.seis
                            }}
                            onClick={() => handleNumClick(6,'seis')}
                            >
                            <Typography variant="h1" align="center" sx={{color: clickedColor.seis}}>6</Typography>
                        </Box>
                    </Stack>
                    <Stack direction="row"   justifyContent="space-between" alignItems="center" sx={{mx:0, my: 0}}>
                        <Box sx={{border:2,borderRadius: 2,borderColor: bcNums,  width: widthNums, height: heightNums , m: marginNums,
                            pt:paddingNums,
                            cursor: 'pointer',
                            bgcolor: clickedBgColor.siete,
                            }}
                            onClick={() => handleNumClick(7,'siete')}
                            >
                            <Typography variant="h1" align="center" sx={{color: clickedColor.siete}}>7</Typography>
                        </Box>
                        <Box sx={{border:2,borderRadius: 2,borderColor: bcNums,  width: widthNums, height: heightNums , m: marginNums,
                            pt:paddingNums,
                            cursor: 'pointer',
                            bgcolor: clickedBgColor.ocho
                            }}
                            onClick={() => handleNumClick(8,'ocho')}
                            >
                            <Typography variant="h1" align="center" sx={{color: clickedColor.ocho}}>8</Typography>
                        </Box>
                        <Box sx={{border:2,borderRadius: 2,borderColor: bcNums,  width: widthNums, height: heightNums , m: marginNums,
                            pt:paddingNums,
                            cursor: 'pointer',
                            bgcolor: clickedBgColor.nueve
                            }}
                            onClick={() => handleNumClick(9,'nueve')}
                            >
                            <Typography variant="h1" align="center" sx={{color: clickedColor.nueve}}>9</Typography>
                        </Box>
                    </Stack>
                    <Stack direction="row"   justifyContent="space-between" alignItems="center" sx={{mx:0, my: 0}}>
                        <Box sx={{border:2,borderRadius: 2,borderColor: bcNums,  width: widthNums, height: heightNums , m: marginNums,
                            pt:paddingNums,
                            cursor: 'pointer',
                            bgcolor: clickedBgColor.siete,
                            }}
                            >
                            <Typography variant="h1" align="center" sx={{color: clickedColor.siete}}></Typography>
                        </Box>
                        <Box sx={{border:2,borderRadius: 2,borderColor: bcNums,  width: widthNums, height: heightNums , m: marginNums,
                            pt:paddingNums,
                            cursor: 'pointer',
                            bgcolor: clickedBgColor.cero
                            }}
                            onClick={() => handleNumClick(0,'cero')}
                            >
                            <Typography variant="h1" align="center" sx={{color: clickedColor.cero}}>0</Typography>
                        </Box>
                        <Box sx={{border:2,borderRadius: 2,borderColor: bcNums,  width: widthNums, height: heightNums , m: marginNums,
                            pt:paddingNums,
                            cursor: 'pointer',
                            bgcolor: clickedBgColor.back
                            }}
                            onClick={() => handleNumClick(10,'back')}
                            >
                            <Typography variant="h1" align="center" sx={{color: clickedColor.back}}><BackspaceIcon /></Typography>
                        </Box>
                    </Stack>
                    <Button  variant="contained" size="small" align="center" sx={{mt:1, background: '#413F42', color: '#FFF'}} onClick={() => handleSave()}>Terminar</Button>
                    </Grid>
                </Stack>

              
           
        </Card>
    );
};



export default NumPad;
