import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RoutesMap from '../views/recorridos/routesMap/RoutesMap';

// sample page routing
const PdfViewer = Loadable(lazy(() => import('views/pedidos/PedidoView/components/PedidoPdf')));
const PrintCorteView = Loadable(lazy(() => import('views/pedidos/PedidosPrint/PrintCorteView')));
const PrintCorteViewZebra = Loadable(lazy(() => import('views/pedidos/PedidosPrint/PrintCorteViewZebra')));
const PrintGuiaView = Loadable(lazy(() => import('views/pedidos/PedidosPrint/PrintGuiaView')));
const PrintPunteoView = Loadable(lazy(() => import('views/pedidos/PedidosPrint/PrintPunteoView')));
const PunteoVirtual = Loadable(lazy(() => import('views/pedidos/PunteoVirtual/PunteoVirtual')));
const SearchPrintPedido = Loadable(lazy(() => import('views/pedidos/PedidosSearch/SearchPrintPedido')));
const SearchPrintPedidoZebra = Loadable(lazy(() => import('views/pedidos/PedidosPrint/SearchPrintPedidoZebra')));
const PrintCobrosView = Loadable(lazy(() => import('views/pedidos/PedidosCobros/components/PrintCobrosView')));
const PrintCobrosViewZebra = Loadable(lazy(() => import('views/pedidos/PedidosPrint/PrintCobrosViewZebra')));
const ReporteCobrosView = Loadable(lazy(() => import('views/pedidos/PedidosCobros/components/ReporteCobrosView')));
// ==============================|| MAIN ROUTING ||============================== //

const BlankRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MinimalLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <PdfViewer />
        },
        {
            path: '/pdf-viewer/:id',
            element: <PdfViewer />
        },
        {
            path: '/printcorte/:id',
            element: <PrintCorteView />
        },
        {
            path: '/printcortezebra/:id',
            element: <PrintCorteViewZebra />
        },
        {
            path: '/printguia/:id',
            element: <PrintGuiaView />
        },
        {
            path: '/searchprint/:id',
            element: <SearchPrintPedido />
        },
        {
            path: '/searchprintzebra/:id',
            element: <SearchPrintPedidoZebra />
        },
        {
            path: '/printpunteo/:id',
            element: <PrintPunteoView />
        },
        {
            path: '/punteovirtual/:id',
            element: <PunteoVirtual />
        },
        {
            path: '/cobros/print/:id',
            element: <PrintCobrosView />
        },
        {
            path: '/cobros/printzebra/:id',
            element: <PrintCobrosViewZebra />
        },
        {
            path: '/cobros/reporte/:id',
            element: <ReporteCobrosView />
        },
        {
            path: '/routesmapblank',
            element: <RoutesMap />
        }
    ]
};

export default BlankRoutes;
