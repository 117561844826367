import React, { useEffect, useState } from "react";




import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';


import { Grid, Button, Typography,Stack,Link, Divider, useMediaQuery,Box, Collapse, IconButton, Table, TableBody, 
    TableCell, TableContainer, TableHead, TableRow, Chip, LinearProgress} from '@mui/material';




const toggleClass = () => {
  
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 4,
    background: '#323232',
    top: 6,
    fontSize: '9px',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 1px',
  },
}));



const SideBarInline = ({name}) => {

    return (
        <Grid >
            <Typography>SideBar</Typography>
        </Grid>
           
  

      
    
      

      
    );
    
 

  
};

export default SideBarInline;
