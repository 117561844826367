import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { styled } from '@mui/material/styles';
import { Button, Dialog, IconButton, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';


// assets
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    color: (theme) => theme.palette.grey[500]
                }}
            >
                <CloseIcon />
            </IconButton>
        ) : null}
    </DialogTitle>
);

BootstrapDialogTitle.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node
};

export default function NoAddressDialogs(data) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                variant="outlined"
                
                fullWidth
                size="small"
                sx={{ minWidth: 120 }}
                startIcon={<ContentPasteIcon />}
                onClick={handleClickOpen}
            >
                Sin dirección
            </Button>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                   Tiendas sin dirección
                </BootstrapDialogTitle>
                <DialogContent dividers>
                <TableContainer>
                    <Table sx={{ minWidth: 250 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }}>CR</TableCell>
                                <TableCell align="right">Sub plaza</TableCell>
                                <TableCell align="right">Nombre tienda</TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.props.sdData.map((row) => (
                                <TableRow hover key={row.row.CrDeTienda}>
                                    <TableCell sx={{ pl: 3 }} component="th" scope="row">
                                        {row.row.CrDeTienda}
                                    </TableCell>
                                    <TableCell align="right">{row.row.NombreDeSubPlaza}</TableCell>
                                    <TableCell align="right">{row.row.NombreDeTienda}</TableCell>
                                    
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Salir
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
