import React,{useState,useEffect} from 'react'
import axios from 'utils/axios';

import { Grid, Button, Typography,Autocomplete,InputAdornment, TextField, Divider, Box, Container,LinearProgress, useMediaQuery, Card   } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import { useTheme } from '@mui/material/styles';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Link } from 'react-router-dom';





const ClientesMain = () => {
const [fileData, setFileData] = useState([]);
const [options, setOptions] = React.useState([]);
const [value, setValue] = React.useState('');
const [isLoading, setIsLoading] = React.useState(false);


useEffect(() => {
    
},[])

const queryClientes = async (query) => {
        
    const apiUrl = "/api/v1/clientes/clientessearch";
    const apiData = {word: query};
    setIsLoading(true);
    await axios.post(apiUrl,apiData).then(response => {

    
        
            if(response.data.length >=1) {
                setOptions(response.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setOptions([]);
            }
           
        
           
    });
}

const handleSearch = (e) => {
    const newString = e.target.value;
   
    if (newString.length >= 3) {
        queryClientes(newString);
    } else {
       setOptions([]);
    }
}



     return (
            <MainCard title="Buscar cliente">
                <Grid item xs={12} md={6} lg={4}>
                            <TextField label="" onChange={handleSearch} placeholder='Buscar CR, tienda'/>
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={{mt:4}}>
                    {options.length >=1 ? 
                        <Grid container direction='column' >    
                            {options.map((option) => (
                    
                            <Grid container direction = "column" component={Link} to={`/cliente-detail/${option.id}`} sx={{m:1, width: 350, textDecoration: 'none'}}>
                            <Typography variant="h4">{option.label}</Typography>
                            <Typography variant="caption">{option.nombre}</Typography>
                            <Divider sx={{my:.5}}/>
                            
                        </Grid>
                            ))}
                        
                        </Grid>
                        : <Typography>No hay datos</Typography>}
                </Grid>

            </MainCard>
   
            
        );
    }
    


export default ClientesMain