import React, { useState } from "react";
import { Grid, Button, Avatar } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { styled } from '@mui/material/styles';
import axios from 'utils/axios';
import Resizer from "react-image-file-resizer";

const Input = styled('input')({
    display: 'none',
  });


export const FileInput = ({handleFileInput}) => {
  const [image, setImage] = useState(null);
  const [fileRaw,setFileRaw] = useState('');
  const [resizedImage, setResizedImage] = useState('');

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function doSomethingWithFiles(fileList)  {
    let file = null;

    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].type.match(/^image\//)) {
        file = fileList[i];
        break;
      }
    }

    if (file !== null) {
      //console.log(URL.createObjectURL(file));
      setFileRaw(file);
      setImage(URL.createObjectURL(file));
      //const resizedFile = await resizeFile(fileRaw);
      handleFileInput(URL.createObjectURL(file));
      //toBase64(file).then((result) => console.log(result));
    }
  }

  function test(image) {
    const files = image.target.files;

    doSomethingWithFiles(files);
  }

  const handleUpload = () => {
    
};

  const handleUploadFile = async (pedido) => {
    const b64 = await toBase64(fileRaw);
    const resizedFile = await resizeFile(fileRaw);
    //console.log(b64);
    //console.log(resizedFile);

     const apiUrl = `/api/v1/pedidos/uploadfile`;
    const apiData = {base64Data: resizedFile }
    await axios.post(apiUrl, apiData).then(response => {
    
        console.log(response.data);
         });  
 }

 const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

  return (
    <div>
        <label htmlFor="file">
            <Input id="file" type="file" accept="image/*"  onChange={test} name='file'/>
                <Grid sx={{m:1}}>
                    <Button 
                        variant="outlined" 
                        size="small" 
                        color= 'primary'
                        startIcon={<DriveFolderUploadIcon />} 
                        component="span">
                        Seleccionar
                        
                    </Button>
                </Grid>
            </label>
        <div style={{ display: "flex", flexDirection: "row" }}>

            
        </div>
    </div>

  );
};
