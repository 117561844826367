
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
    Svg,
    G,
    Polygon,
    PDFDownloadLink,
    Font
  } from "@react-pdf/renderer";

export  const BoxIcon2 = () => (
    <Svg style={{ padding: 0, width: 70, height: 70 }} viewBox={'0 0 512 491.96'}>
        <G >
          <Polygon
          fill="#A6A6A6"
          points="243.9000 135.5900, 105.4800 181.6900, 256.3100 250.1000, 407.1400 183.6200, 294.5200 145.0300, 294.5200 124.1900, 437.3900 173.1200, 444.0500 183.3100, 444.0800 184.0100, 444.0800 364.4800, 476.6800 383.2900, 470.3100 364.0800, 495.9100 360.1700, 511.4600 406.1100, 501.9700 422.8700, 454.0700 432.6500, 447.3000 407.3100, 464.8400 404.1700, 348.4200 336.9700, 344.0300 320.5700, 360.4200 316.1800, 424.2900 353.0500, 424.2900 197.6100, 266.1200 267.3100, 266.1200 466.0100, 399.3400 398.3000, 419.7200 410.0600, 261.3600 490.5300, 250.8900 490.3800, 94.4800 408.7900, 114.6900 397.1300, 246.3600 465.8200, 246.3600 267.2200, 87.6400 195.2600, 87.6400 353.0800, 151.5800 316.1800, 167.9700 320.5700, 163.5800 336.9700, 47.1600 404.1700, 64.7000 407.3100, 57.9300 432.6500, 10.0300 422.8700, 0.5400 406.1100, 16.0900 360.1700, 41.6900 364.0800, 35.3200 383.2900, 67.9200 364.4800, 67.8900 180.4200, 76.3700 170.6300, 243.9000 114.8600, 243.8900 45.8200, 230.4300 60.9400, 214.2500 40.7200, 246.2800 4.2900, 265.5300 4.1300, 297.9400 40.7200, 279.3900 59.2500, 267.9000 45.6400, 267.9100 180.0600, 255.9000 192.0600, 243.9000 180.0600, 243.9000 135.5900"
          />
         
    </G>
    </Svg>
   
)