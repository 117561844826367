import other from './other';
import pedidos from './pedidos';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [pedidos]
};

export default menuItems;
