import { lazy } from 'react';

// project imports
import AdminLayout from 'layout/AdminLayout';
import OpsLayout from 'layout/OpsLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import PedidosMain from '../views/pedidos';
import ClientesMain from '../views/clientes/ClientesMain';
import ClienteDetail from '../views/clientes/ClienteDetail/ClienteDetail';
import CoverageMap from '../views/mapCoverage/MapComponent';
import PreviewMap from '../views/pedidos/CrearPedido/components/PreviewMap';
import PedidoView from '../views/pedidos/PedidoView/PedidoView';
import PedidosSearch from 'views/pedidos/PedidosSearch/PedidosSearch';
import MigratePedidos from '../views/pedidos/MigratePedidos/MigratePedidos';
import PedidosPrint from '../views/pedidos/PedidosPrint/PedidosPrint';
import PrintViewA4 from '../views/pedidos/PedidosPrint/PrintView-a4';
import PrintView from 'views/pedidos/PedidosPrint/PrintCorteView';
import PunteoPagos from '../views/pedidos/PunteoPagos/PunteoPagos';
import PedidoPdf from '../views/pedidos/PedidoPdf/PedidoPdf';
import MapComponent from '../views/recorridos/map/TrackingMap';
import CobrosCaptura from 'views/pedidos/PedidosCobros/CobrosCaptura';
import CobrosMain from 'views/pedidos/PedidosCobros/CobrosMain';

const PunteoEntMain = Loadable(lazy(() => import('views/operaciones/PunteoEntrega/PunteoEntMain')));
const ReporteDespacho = Loadable(lazy(() => import('views/operaciones/ReporteDespacho/ReporteDespachoPage')));
const ReporteMonitoreo = Loadable(lazy(() => import('views/operaciones/ReporteMonitoreo/ReporteMonitoreoPage')));
const PunteoRecoleccion = Loadable(lazy(() => import('views/operaciones/PunteoRecoleccion/PunteoRecoleccion')));
const DespachoRecMain = Loadable(lazy(() => import('views/operaciones/PunteoRecoleccion/DespachoRecMain')));
const ReporteBloque = Loadable(lazy(() => import('views/operaciones/PunteoRecoleccion/ReporteBloque')));
const PunteoEntrega = Loadable(lazy(() => import('views/operaciones/PunteoEntrega/PunteoEntrega')));
const UtilScreenSize = Loadable(lazy(() => import('views/utils/ScreenSize')));
const UtilRealTime = Loadable(lazy(() => import('views/utils/RealTime')));
const RecorridosMain = Loadable(lazy(() => import('views/operaciones/Recorridos/RecorridosMain')));
const RoutesMap = Loadable(lazy(() => import('views/recorridos/routesMap/RoutesMap')));
const TrackingMap = Loadable(lazy(() => import('views/recorridos/map/TrackingMap')));
const PunteoVirtual = Loadable(lazy(() => import('views/pedidos/PunteoVirtual/PunteoVirtual')));
const OperacionesMain = Loadable(lazy(() => import('views/operaciones/OperacionesMain')));
const PunteoRecMain = Loadable(lazy(() => import('views/operaciones/PunteoRecoleccion/PunteoRecMain')));
const PedidosSemanal = Loadable(lazy(() => import('views/reportes/PedidosSemanal/PedidosSemanal')));

//solo admin

const MonitoreoIndex = Loadable(lazy(() => import('views/monitoreo/MonitoreoIndex')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //
const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <AdminLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <PedidosPrint />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/pedido-add',
            element: <PedidosMain />
        },
        {
            path: '/clientes',
            element: <ClientesMain />
        },
        {
            path: '/cliente-detail/:id',
            element: <ClienteDetail />
        },
        {
            path: '/coverage',
            element: <CoverageMap />
        },
        {
            path: '/premap',
            element: <PreviewMap />
        },
        {
            path: '/pedidos/:id',
            element: <PedidoView />
        },
        {
            path: '/migrate',
            element: <MigratePedidos />
        },
        {
            path: '/print',
            element: <PrintView />
        },
        {
            path: '/printa4',
            element: <PrintViewA4 />
        },
        {
            path: '/pedidosprint',
            element: <PedidosPrint />
        },
        {
            path: '/pedidossearch',
            element: <PedidosSearch />
        },
        {
            path: '/punteopagos',
            element: <PunteoPagos />
        },
        {
            path: '/ppdf',
            element: <PedidoPdf />
        },
        {
            path: '/map',
            element: <MapComponent />
        },
        {
            path: '/routesmap',
            element: <RoutesMap />
        },
        {
            path: '/week',
            element: <PedidosSemanal />
        },
        {
            path: '/cobros/main',
            element: <CobrosMain />
        },
        {
            path: '/cobros/main/:fecha',
            element: <CobrosMain />
        },
        {
            path: '/cobros/main/:fecha/:bloque',
            element: <CobrosMain />
        },
        {
            path: '/cobros/captura/:id',
            element: <CobrosCaptura />
        },
        {
            path: '/',
            element: <OperacionesMain />
        },
        {
            path: '/punteovirtual',
            element: <PunteoVirtual />
        },
        {
            path: '/opspvrec',
            element: <PunteoRecMain />
        },
        {
            path: '/opspvrec/:fecha',
            element: <PunteoRecMain />
        },
        {
            path: '/opspvrec/:fecha/:bloque',
            element: <PunteoRecMain />
        },
        {
            path: '/punteorec/:id',
            element: <PunteoRecoleccion />
        },
        {
            path: '/despachorec/:id',
            element: <DespachoRecMain />
        },
        {
            path: '/punteoreporterec',
            element: <ReporteBloque />
        },
        {
            path: '/punteoreporterec/:id',
            element: <ReporteBloque />
        },
        {
            path: '/punteoreporterec/:id/:otro',
            element: <ReporteBloque />
        },
        {
            path: '/opspvent',
            element: <PunteoEntMain />
        },
        {
            path: '/repdespacho',
            element: <ReporteDespacho />
        },
        {
            path: '/repopsguias',
            element: <ReporteMonitoreo />
        },
        {
            path: '/opspvent/:fecha',
            element: <PunteoEntMain />
        },
        {
            path: '/opspvent/:fecha/:bloque',
            element: <PunteoEntMain />
        },
        {
            path: '/punteoent/:id',
            element: <PunteoEntrega />
        },
        {
            path: '/utils-screen',
            element: <UtilScreenSize />
        },
        {
            path: '/utils-realtime',
            element: <UtilRealTime />
        },
        {
            path: '/recorridos',
            element: <RecorridosMain />
        },
        {
            path: '/recorridos/:id',
            element: <RecorridosMain />
        },
        {
            path: '/trackmap',
            element: <TrackingMap />
        },
        {
            path: '/trackmap/:id',
            element: <TrackingMap />
        },
        {
            path: '/routesmap',
            element: <RoutesMap />
        },
        {
            path: '/monitoreo',
            element: <MonitoreoIndex />
        }
    ]
};

export default AdminRoutes;
