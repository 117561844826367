
import { useTheme } from '@mui/material/styles';
import { Grid, Typography, useMediaQuery } from '@mui/material';

// project imports

import MainCard from 'ui-component/cards/MainCard';
import RevenueCard from 'ui-component/cards/RevenueCard';
import UserCountCard from 'ui-component/cards/UserCountCard';
import { gridSpacing } from 'store/constant';

// assets
import { IconShare, IconAccessPoint, IconCircles, IconCreditCard, IconTruckDelivery } from '@tabler/icons';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import AccountCircleTwoTone from '@mui/icons-material/AccountCircleTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import { borderRight } from '@mui/system';

const PedidosStats = () => {

    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

    const blockSX = {
        p: 2.5,

        border: '1px solid ',
        
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200],
       
    };
   


    return (
        <MainCard
            content={false}
            sx={{
                '& svg': {
                    width: 50,
                    height: 50,
                    color: theme.palette.secondary.main,
                    borderRadius: '14px',
                    p: 1.25,
                    bgcolor: theme.palette.mode === 'dark' ? theme.palette.background.default : 'primary.light'
                }
            }}
        >
            <Grid container alignItems="center" spacing={0} sx={{m:0}}>
                <Grid item xs={12} sm={6}  sx={blockSX}>
                    <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        justifyContent={matchDownXs ? 'space-between' : 'center'}
                    >
                        <Grid item>
                            <IconTruckDelivery stroke={1.5} />
                        </Grid>
                        <Grid item sm zeroMinWidth>
                            <Typography variant="h5" align="center">
                                1000
                            </Typography>
                            <Typography variant="subtitle2" align="center">
                                TOTAL PEDIDOS
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}  sx={blockSX}>
                    <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        justifyContent={matchDownXs ? 'space-between' : 'center'}
                    >
                        <Grid item>
                            <IconTruckDelivery stroke={1.5} />
                        </Grid>
                        <Grid item sm zeroMinWidth>
                            <Typography variant="h5" align="center">
                                600
                            </Typography>
                            <Typography variant="subtitle2" align="center">
                                ÚLTIMO MES
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={0} sx={{m:0}}>
                <Grid item xs={12} sm={6}  sx={blockSX}>
                    <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        justifyContent={matchDownXs ? 'space-between' : 'center'}
                    >
                        <Grid item>
                            <IconTruckDelivery stroke={1.5} />
                        </Grid>
                        <Grid item sm zeroMinWidth>
                            <Typography variant="h5" align="center">
                                3550
                            </Typography>
                            <Typography variant="subtitle2" align="center">
                                ÚLTIMOS 6 MESES
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}  sx={blockSX}>
                    <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        justifyContent={matchDownXs ? 'space-between' : 'center'}
                    >
                        <Grid item>
                            <IconTruckDelivery stroke={1.5} />
                        </Grid>
                        <Grid item sm zeroMinWidth>
                            <Typography variant="h5" align="center">
                                100%
                            </Typography>
                            <Typography variant="subtitle2" align="center">
                                ÚLTIMO AÑO
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
}

export default PedidosStats;















