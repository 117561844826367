import React,{useState,useEffect} from 'react'
import { useTheme } from '@mui/material/styles';
import axios from 'utils/axios';
import { FileInput } from "./components/Comprobante/FileInput";
import PedidoConfirm from './components/PedidoConfirm';

// material-ui
import { Grid, Button, Dialog, IconButton,  Slide, Typography, Card, CardActions, CardContent,
     CardHeader, CardMedia , Chip, Link, useMediaQuery,LinearProgress, Fade, Stack, Divider, 
     FormControl, Radio, RadioGroup, FormControlLabel, Avatar } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
// assets
import CloseIcon from '@mui/icons-material/Close';
import { gridSpacing } from 'store/constant';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PreviewMap from '../components/PreviewMap';
import TipoPaquete from './components/TipoPaquete';
import ResumenBox from './components/ResumenBox';
import PedidoDirectoBox from './components/PedidoDirectoBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeIcon from '@mui/icons-material/Home';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaidIcon from '@mui/icons-material/Paid';
import InfoIcon from '@mui/icons-material/Info';

// slide animation
const Transition = React.forwardRef((props, ref) => <Fade in={true} timeout={4000} ref={ref} {...props} />);

// ===============================|| UI DIALOG - FULL SCREEN ||=============================== //

export default function ResumenDialog({open, handleCloseDialog,pedidoData}) {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };


    
    const [rows, setRows] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isLoading,setIsLoading] = useState(true);
    const [cotizacion, setCotizacion] = useState({});
    const [paso, setPaso] = useState(1);
    const [metodoState, setMetodoState] = useState(1);
    const [isTransfer, setIsTransfer] = useState(false);
    const [compImage, setCompImage] = useState(null);
    const [precio, setPrecio] = useState(0);
    const [tipoPaquete, setTipoPaquete] = useState({});
    const [pedidoFinalData, setPedidoFinalData] = useState({});
    const [pedidoDirecto, setPedidoDirecto] = useState(false);
    const [pedidoCreadoId, setPedidoCreadoId] = useState('');


    React.useEffect(() => {
        fetchData(pedidoData);
    }, [open]);


    const fetchData = async (pedido) => {
        setIsLoading(true);
        const apiUrl = `/api/v1/cotiza`;
        const apiData = {cp_origen: pedido.origen_codigo_postal, cp_destino: pedido.entrega_codigo_postal, tipo_producto_id: 1, peso_max: pedido.paq_volumen_max}
        //console.log(apiData);
        await axios.post(apiUrl, apiData).then(response => {
            setCotizacion(response.data);
            setPrecio(response.data.precio);
            setTipoPaquete({tipo_id: response.data.tipo_id, tipo: response.data.tipo});

            if (response.data.tipo_id == 0) {
                setPedidoDirecto(true);
            }
            const timer = setTimeout(() => {
                setIsLoading(false);
            }, 2500);
             });  
     }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setPedidoDirecto(false);
        handleCloseDialog(false);
    };

    const handleSiguiente = (paso) => {
        
        setPaso(paso);
        if (paso == 2) {
            let newData = pedidoData;
            newData = {...newData,
            forma_pago_id: metodoState,
            precio_venta: precio,
            paq_tipo_id: tipoPaquete.tipo_id,
            paq_tipo: tipoPaquete.tipo,
            producto_id: cotizacion.id,
            created_by: 1
            }
            setPedidoFinalData(newData);
            setIsLoading(true);
            const timer = setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
       
    };

    const handleFileInputChange = (resizedb64) => {
        setCompImage(resizedb64);
    };

    


    const handleMetodoChange = (e) => {
        const met = e.target.value;
        setMetodoState(met);

        if (met == 1) {
            setIsTransfer(false);
        } else if (met == 2) {
            setIsTransfer(true);
        }
        
    };


 const handleCrearPedido = () => {
        //console.log(pedidoFinalData);
        fetchPedido(pedidoFinalData);
        setPaso(3);
    };

const fetchPedido = async (pedido) => {
    setIsLoading(true);
    const apiUrl = `/api/v1/pedidos`;
    const apiData = pedido;
    //console.log(apiData);
    await axios.post(apiUrl, apiData).then(response => {
        setPedidoCreadoId(response.data.pedidoId);
        console.log(response.data);
     
            });  
    }

      
    return (
        <div>
           
            <Dialog 
            open={open} 
            TransitionComponent={Transition} 
            scroll='body'
            sx={{
                '&>div:nth-of-type(3)': {
                    justifyContent: 'flex-end',
                    '&>div': {
                        m: 0,
                        p:0,
                        borderRadius: '0px',
                        minWidth: matchDownMd ? .95 : .60,
                        minHeight: '100%',
                    }
                }
            }}
            >
                {open && (
                    <>
                        
                        {paso == 1 && (
                            <Grid>
                                <Grid sx={{ml:0}}>
                                
                                {/* <PreviewMap data={pedidoData} loading={isLoading} />  */}
                                
                                </Grid>
                                
                                <Grid container sx={{mt:2}}>
                                    <Grid item xs={12} md={8} lg={3} ></Grid>
                                    <Grid item xs={12} md={8} lg={6} sx={{mx: matchDownMd ? 1.5 : 0}} >
                                        {isLoading ? <Typography variant="h2" align='center'>Cotizando...</Typography> : 
                                           !pedidoDirecto ? (<Grid>
                                                <Grid container  direction="row" justifyContent="space-between" alignItems='flex-end' sx={{mb:1}}>
                                                    <Stack direction="column"  sx={{mt:2}}>
                                                        <Typography sx={{fontSize: matchDownMd ?25:40, fontWeight: 600, color: '#212121', lineHeight: 1}}>${cotizacion.precio}</Typography>
                                                        <Typography variant={matchDownMd ? 'caption' : 'body2'}>{cotizacion.descripcion}</Typography>
                                                    </Stack>
                                                    <TipoPaquete tipo={cotizacion.tipo_id} tipoNombre={cotizacion.tipo} />
                                                </Grid>
                                                <Divider />
                                                <Grid sx={{mt:2}}>
                                                    <Typography variant={matchDownMd ? 'h6' : 'h5'}>Selecciona tu método de pago</Typography>
                                                    <FormControl sx={{ml:3}}>
                                                        <RadioGroup
                                                            aria-labelledby="lista-tiendas-radio-buttons-group-label"
                                                            name="lista_tiendas_id"
                                                            value={metodoState}
                                                            onChange={handleMetodoChange}
                                                        >
                                                            <FormControlLabel value={1} control={<Radio  size="small" /> }  label='Efectivo' />
                                                            <FormControlLabel value={2} control={<Radio size="small" />} label='Transferencia/Depósito' />
                                                        </RadioGroup>
                                                    </FormControl> 
                                                    {isTransfer && <Grid sx={{m:2}}>
                                                        <Typography variant="h6" sx={{color: "#2c88fb", cursor: 'pointer', textDecoration: 'none'}}>Subir comprobante de depósito o transferencia</Typography>
                                                            <FileInput handleFileInput={handleFileInputChange}/>
                                                            <Grid sx={{ml:3,mt:1}}>
                                                                {compImage && <Avatar src={compImage} style={{ width: 80, height: 110, borderRadius: 6 }} variant="square"/>}
                                                                <Button 
                                                                        variant="outlined" 
                                                                        size="small" 
                                                                        color= 'primary'
                                                                        component="span"
                                                                        onClick={() => {handleUploadFile()}}
                                                                        >
                                                                        Upload
                                                                        
                                                                    </Button>
                                                            </Grid>
                                                        </Grid>}
                                                        <Grid item xs={12}  sx={{mt:3}}>
                                                        <Stack direction="row" alignItems="center" justifyContent='space-between'>
                                                            <Stack direction="row" alignItems={'center'}  sx={{color: "#2c88fb", cursor: 'pointer', textDecoration: 'none'}} 
                                                                component={Link} onClick={() => {handleClose()}}>
                                                                    <ArrowBackIcon sx={{fontSize: 22}}/>
                                                                    <Typography variant="body2" sx={{color: "#2c88fb", fontSize: 15, ml:.5}} align="center">Regresar</Typography>
                                                            </Stack>   
                                                            <Button 
                                                                variant="contained" 
                                                                size="small" 
                                                                color= 'primary'
                                                                component="span"
                                                                onClick={() => {handleSiguiente(2)}}
                                                                >
                                                                Siguiente
                                                            </Button>
                                                        </Stack>
                                                       
                                                        </Grid>

                                                </Grid>
                                            </Grid>) : 
                                            <Grid>
                                                 <Grid sx={{mt:2}}>
                                                    <Stack direction="row" alignItems={'flex-start'}  sx={{color: "#2c88fb", cursor: 'pointer', textDecoration: 'none', mb:3}} 
                                                                component={Link} onClick={() => {handleClose()}}>
                                                        <InfoIcon sx={{fontSize: 22}}/>
                                                        <Typography variant={'body2'} sx={{ml:.5}}>Este pedido excede el tamaño o peso máximos y requiere 
                                                    una cotización.</Typography>
                                                    </Stack>  
                                                    
                                                    <PedidoDirectoBox pedido={pedidoData}/>
                                                    <Grid item xs={12}  sx={{mt:3}}>
                                                        <Stack direction="row" alignItems="center" justifyContent='space-between'>
                                                            <Stack direction="row" alignItems={'center'}  sx={{color: "#2c88fb", cursor: 'pointer', textDecoration: 'none'}} 
                                                                component={Link} onClick={() => {handleClose()}}>
                                                                    <ArrowBackIcon sx={{fontSize: 22}}/>
                                                                    <Typography variant="body2" sx={{color: "#2c88fb", fontSize: 15, ml:.5}} align="center">Regresar</Typography>
                                                            </Stack>   
                                                            <Button 
                                                                variant="contained" 
                                                                size="small" 
                                                                color= 'primary'
                                                                component="span"
                                                                onClick={() => {handleSiguiente(2)}}
                                                                >
                                                                Solicitar cotización
                                                            </Button>
                                                        </Stack>
                                                       
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        
                                        }
                                    </Grid>

                                    <Grid item xs={12} md={8} lg={3} ></Grid>
                                    
                                </Grid>
                            </Grid>
                        )}
                        {paso == 2 && (
                            <Fade in={true} timeout={2000}>
                                <Grid>
                                    <Stack direction="row" alignItems={'center'}  sx={{color: "#2c88fb", cursor: 'pointer', textDecoration: 'none', m:3}} component={Link} onClick={() => {handleSiguiente(1)}}>
                                        <ArrowBackIcon sx={{fontSize: 22}}/>
                                        <Typography variant="body2" sx={{color: "#2c88fb", fontSize: 15, ml:.5}} align="center">Regresar</Typography>
                                    </Stack>
                                    <Grid container sx={{mt:2}}>
                                        <Grid item xs={12} md={8} lg={3} ></Grid>
                                        <Grid item xs={12} md={8} lg={6} sx={{mx: matchDownMd ? 1.5 : 0}} >
                                            <Stack direction="row" alignItems="center">
                                                <ShoppingCartIcon sx={{color: "#f44336", fontSize: 26}}/>
                                                <Typography variant="h2" sx={{mx: .5, fontWeight: 800,lineHeight: 1}}>Resumen</Typography>
                                            </Stack>
                                            <Typography variant="caption" sx={{ lineHeight: 1, ml: .5}} >Revisa y confirma tu pedido</Typography>
                                            <Divider sx={{mt:1, mb: 4}}/>
                                            <ResumenBox pedido={pedidoFinalData}/>
                                            <Grid item xs={12}  sx={{mt:3}}>
                                                <Stack direction="row" alignItems="center" justifyContent='center'>
                                                
                                                    <Button 
                                                        variant="contained" 
                                                
                                                        color= 'primary'
                                                        component="span"
                                                        onClick={() => {handleCrearPedido()}}
                                                        sx={{textTransform: 'none'}}
                                                        >
                                                        Confirmar pedido
                                                    </Button>
                                                </Stack>
                                                
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={8} lg={3} ></Grid>
                                    </Grid>
                                </Grid>
                            </Fade>
                        )}
                         {paso == 3 && (
                            <Grid>
                                <Grid sx={{ml:0}}>
                                <Stack direction="row" alignItems={'center'}  sx={{color: "#2c88fb", cursor: 'pointer', textDecoration: 'none', ml:1}} component={Link} onClick={() => {handleSiguiente(1)}}>
                                        <ArrowBackIcon sx={{fontSize: 16}}/>
                                    </Stack>
                                    {<PedidoConfirm pedidoId={pedidoCreadoId} />}
                                
                                </Grid>
                                
                               
                            </Grid>
                        )}
                        

                    </>
                )}
            </Dialog>
        </div>
    );
}
