import React,{useState,useEffect, useRef} from 'react'
import axios from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Typography,Stack, Link, useMediaQuery } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';




const PrintViewA4 = ({}) => {
    let params = useParams();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const componentRef = useRef(null);

    const [pedidoId, setPedidoId] = useState(params.id);
    const [isView, setIsView] = React.useState(false);
    const [pedidosData, setPedidosData] = React.useState([]);

    React.useEffect(() => {
        fetchData(params.id);
    }, []);


    const fetchData = async (fecha) => {
        const apiUrl = `/api/v1/pedidos/c/1`;
        
        //console.log(apiData);
        await axios.get(apiUrl).then(response => {
           //console.log(response.data);
           setPedidosData(response.data);
           setIsView(true);
             });  
     }

    let canvas;
    canvas = document.createElement('canvas');
    JsBarcode(canvas, pedidoId, {
        width: 2,
        height: 60,
        displayValue: false,
    });
    const barcode = canvas.toDataURL();

    const handleBarcodeDialog = () => {
            setOpenBarcode(true);
    };

    const closeBarcodeDialog = (val) => {
        console.log(val);
        setOpenBarcode(false);
    };

    return (
        <MainCard>
            <Stack direction="row"  justifyContent="space-between" alignItems="center" sx={{mt:1}}>
                <Stack direction="column" >
                <Typography variant={matchDownMd ? "h6" : "h4"} align="center" >Impresión de guías</Typography>
                <Typography variant="captio" align="center">fecha</Typography>
                </Stack>   
            </Stack>
            {isView && <div ref={componentRef} >
                <PedidosPdfA4 pedidosData={pedidosData} />
            </div>}
        </MainCard>
    );
}

export default PrintViewA4;