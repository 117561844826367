
import React,{useState,useEffect} from 'react'
import axios from 'utils/axios';
import { Grid, Button, Typography, TextField, Divider, Box, Stack,LinearProgress, useMediaQuery, Card   } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import { Link } from 'react-router-dom';
import QRGenerator from './components/qrGenerator';
import CreatePdf from './components/createPdf';

const PedidoPdf = ({  }) => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('md'));
   
    const [options, setOptions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [rendered, setRenderer] = React.useState(false);

    useEffect(() => {
       setRenderer(true);
    }, []);
       

   

    return (
        <MainCard title="QR Pdf ">
            <Stack>
            <Grid item xs={12} md={6} lg={4}>
                <div style={{display: 'none'}}>
                    <QRGenerator value={JSON.stringify({
                        id: 512363,
                        oc: 'Torremolinos, MTY',
                        on: '08-Nov (Bloque 1)',
                        ec: 'Contry Sol, GPE',
                        en: '08-Nov (Bloque 2)',
                        pt: 'MINI',
                        pp: 58,
                        })} documentId={512363} />
                </div>
            </Grid>
                {rendered && <CreatePdf id={512363} />}
            </Stack>
        </MainCard>
    );

}



export default PedidoPdf;