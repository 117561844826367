import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// action - state management
import { LOGIN, LOGOUT, LOGGED } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { FIREBASE_API } from 'config';

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_API);
}

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    logged: true,
    user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const fetchData = async (uid) => {
        const db = firebase.firestore();
        const snap = await db.collection('users').doc(uid).get();
        const result = snap.data();
        //const result = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        console.log(result);
        return result;
    };

    useEffect(() => {
        const getData = async (user) => {
            const data = await fetchData(user.uid);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: {
                        id: user.uid,
                        email: user.email,
                        name: data.nombre || 'John Doe',
                        eid: data.eid,
                        roleId: data.roleId,
                        imgUrl: data.imgUrl
                    }
                }
            });
        };
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                getData(user);
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
        });

        //const data = await fetchData();
    }, [dispatch]);

    const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const logout = () => firebase.auth().signOut();

    const resetPassword = async (email) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    const setLogged = () => {
        dispatch({
            type: LOGGED
        });
    };

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => {},
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile,
                setLogged
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
