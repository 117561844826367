import React,{useState,useEffect} from 'react'
import axios from 'utils/axios';

import { Grid, Button, Typography,InputAdornment, TextField, Divider, Box, Container,LinearProgress, useMediaQuery, Chip  } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import { useTheme } from '@mui/material/styles';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PedidosStats from './components/PedidosStats';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import RevenueCard from 'ui-component/cards/RevenueCard';
import PhoneIcon from '@mui/icons-material/Phone';



const ClienteDetail = () => {
    const { id } = useParams();
    const history = useNavigate();
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [isLoading, setIsLoading] = React.useState(false);
    const [clienteData, setClienteData] = React.useState({});




useEffect(() => {
    fetchCliente(id);
},[])

const fetchCliente = async (clienteId) => {
        
    const apiUrl = `/api/v1/clientes/id/${clienteId}`;

    setIsLoading(true);
    await axios.get(apiUrl).then(response => {

        console.log(response.data);
        
            if(response.data) {
                setClienteData(response.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
           
        
           
    });
}





     return (
            <MainCard >
               
                <>
                    {clienteData ? 

                    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12} sm={6} lg={2} sx={{mb:1}}>
                            <Grid container direction = "column" >
                                
                                <Typography variant="h3">{clienteData.nombre}</Typography>
                                <Typography variant="caption">{clienteData.correo}</Typography>
                                <Chip size="small" label='Bronce' 
                                                        sx={{
                                                            backgroundColor: '#CD7F32', 
                                                            color: 'white', 
                                                            fontSize: 11,
                                                            height: '16px',
                                                            padding: '0px 0px',
                                                            pt:.25,
                                                            width: 60
                                                            }}>
                                                    
                                                    </Chip>
                                <Typography variant="subtitle2" sx={{mt:.5}}>No. cliente: {clienteData.id}</Typography>
                                <Typography variant="subtitle2">Tipo cliente: {clienteData.tipo}</Typography>
                                <Grid container direction="row" alignItems="center" sx={{my:.5}}>
                                    <PhoneIcon sx={{fontSize: 20}} color="secondary" />
                                    <Typography variant="h5" sx={{ml:1}}>81 83174000</Typography></Grid>
                                <Button variant='contained' component={Link} to={`/pedido-add`} color='secondary' size="small" sx={{my: 1, width: 150}}>Agregar pedido</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={5} sx={{mb:1}}>
                             <PedidosStats />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} sx={{mb:1}} >
                            <Grid >
                                <RevenueCard
                                        primary="Ventas"
                                        secondary="$42,562"
                                        content="$8,457 Este año"
                                        iconPrimary={MonetizationOnTwoToneIcon}
                                        color={theme.palette.secondary.main}
                                        
                                    />
                            </Grid>
                        </Grid>
                           
                                

                           
                            
                            
                       
                    </Grid>
                        : <Typography>Cargando...</Typography>}
                </>

            </MainCard>
   
            
        );
    }
    


export default ClienteDetail