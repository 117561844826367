import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import OpsLayout from 'layout/OpsLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const PunteoVirtual = Loadable(lazy(() => import('views/pedidos/PunteoVirtual/PunteoVirtual')));
const OperacionesMain = Loadable(lazy(() => import('views/operaciones/OperacionesMain')));
const ReporteDespacho = Loadable(lazy(() => import('views/operaciones/ReporteDespacho/ReporteDespachoPage')));
const ReporteMonitoreo = Loadable(lazy(() => import('views/operaciones/ReporteMonitoreo/ReporteMonitoreoPage')));
const PunteoRecMain = Loadable(lazy(() => import('views/operaciones/PunteoRecoleccion/PunteoRecMain')));
const PunteoEntMain = Loadable(lazy(() => import('views/operaciones/PunteoEntrega/PunteoEntMain')));
const PunteoRecoleccion = Loadable(lazy(() => import('views/operaciones/PunteoRecoleccion/PunteoRecoleccion')));
const DespachoRecMain = Loadable(lazy(() => import('views/operaciones/PunteoRecoleccion/DespachoRecMain')));
const ReporteBloque = Loadable(lazy(() => import('views/operaciones/PunteoRecoleccion/ReporteBloque')));
const PunteoEntrega = Loadable(lazy(() => import('views/operaciones/PunteoEntrega/PunteoEntrega')));
const UtilScreenSize = Loadable(lazy(() => import('views/utils/ScreenSize')));
const UtilRealTime = Loadable(lazy(() => import('views/utils/RealTime')));
const RecorridosMain = Loadable(lazy(() => import('views/operaciones/Recorridos/RecorridosMain')));
const RoutesMap = Loadable(lazy(() => import('views/recorridos/routesMap/RoutesMap')));
const TrackingMap = Loadable(lazy(() => import('views/recorridos/map/TrackingMap')));

// ==============================|| MAIN ROUTING ||============================== //

const OpsRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <OpsLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <OperacionesMain />
        },
        {
            path: '/punteovirtual',
            element: <PunteoVirtual />
        },
        {
            path: '/opspvrec',
            element: <PunteoRecMain />
        },
        {
            path: '/opspvrec/:fecha',
            element: <PunteoRecMain />
        },
        {
            path: '/opspvrec/:fecha/:bloque',
            element: <PunteoRecMain />
        },
        {
            path: '/punteorec/:id',
            element: <PunteoRecoleccion />
        },
        {
            path: '/despachorec/:id',
            element: <DespachoRecMain />
        },
        {
            path: '/repdespacho',
            element: <ReporteDespacho />
        },
        {
            path: '/repopsguias',
            element: <ReporteMonitoreo />
        },
        {
            path: '/punteoreporterec',
            element: <ReporteBloque />
        },
        {
            path: '/punteoreporterec/:id',
            element: <ReporteBloque />
        },
        {
            path: '/punteoreporterec/:id/:otro',
            element: <ReporteBloque />
        },
        {
            path: '/opspvent',
            element: <PunteoEntMain />
        },
        {
            path: '/opspvent/:fecha',
            element: <PunteoEntMain />
        },
        {
            path: '/opspvent/:fecha/:bloque',
            element: <PunteoEntMain />
        },
        {
            path: '/punteoent/:id',
            element: <PunteoEntrega />
        },
        {
            path: '/utils-screen',
            element: <UtilScreenSize />
        },
        {
            path: '/utils-realtime',
            element: <UtilRealTime />
        },
        {
            path: '/recorridos',
            element: <RecorridosMain />
        },
        {
            path: '/recorridos/:id',
            element: <RecorridosMain />
        },
        {
            path: '/trackmap',
            element: <TrackingMap />
        },
        {
            path: '/trackmap/:id',
            element: <TrackingMap />
        },
        {
            path: '/routesmap',
            element: <RoutesMap />
        }
    ]
};

export default OpsRoutes;
