import other from './other';
import admin from './admin';

// ==============================|| MENU ITEMS ||============================== //

const AdminMenuItems = {
    items: [admin]
};

export default AdminMenuItems;
