import React, { useState, useEffect, useRef } from 'react';
import axios from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Grid,
    Button,
    Typography,
    Stack,
    Link,
    useMediaQuery,
    Divider,
    Box,
    Collapse,
    IconButton,
    TextField,
    LinearProgress,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconArrowLeft } from '@tabler/icons';
import PedidoView from 'views/pedidos/PedidoView/PedidoView';

const NoPedidoTab = ({ pedido }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const componentRef = useRef(null);

    const [openPrint, setOpenPrint] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [pedidoData, setPedidoData] = React.useState({});
    const [printDate, setPrintDate] = React.useState(new Date());
    const [municipios, setMunicipios] = React.useState({});
    const [printingData, setPrintingData] = React.useState([]);
    const [cortesData, setCortesData] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [guiaFound, setGuiaFound] = useState(false);

    React.useEffect(() => {}, []);

    const dateHandler = (date) => {
        const fecha = new Date(fecha);
        return fecha;
    };

    const queryClientes = async (query, tipo) => {
        const apiUrl = `/api/v1/pedidos/pedidofullfull/${query}`;
        setIsLoading(true);
        await axios.get(apiUrl).then((response) => {
            if (response.status == 201) {
                setPedidoData(response.data);
                const timer = setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            } else if (response.status == 204) {
                setPedidoData({});
                const timer = setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }
        });
    };

    const handleSearch = (e) => {
        const newString = e.target.value;

        if (!isNaN(newString) && newString.length > 5 && newString.length < 8) {
            queryClientes(newString, 'id');
            console.log('Pedido id: ' + newString);
        } else {
            setPedidoData({});
        }
    };

    const handleDialog = async (guia) => {
        window.open(`/searchprint/${guia}`, '_blank');
        //console.log(value);
        //window.open('http://www.google.com');
    };
    const closePrintDialog = (value) => {
        console.log(value);
    };

    return (
        <>
            {!guiaFound ? (
                <Grid>
                    <Grid item xs={12} md={6} lg={4} sx={{ mt: 2 }}>
                        <TextField label="" onChange={handleSearch} placeholder="No. Guía" />
                    </Grid>
                    <Grid sx={{ mt: 4 }}>
                        {Object.keys(pedidoData).length >= 1 ? (
                            <Grid container direction="column">
                                <TableContainer>
                                    <Table size="small" aria-label="collapsible table">
                                        <TableBody>
                                            <Row guia={pedidoData.guia} />
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        ) : (
                            <Typography>No hay datos</Typography>
                        )}
                    </Grid>
                </Grid>
            ) : (
                <Stack>
                    <Stack direction="row" alignItems="center" sx={{ my: 1, ml: 3, cursor: 'pointer' }} onClick={() => setGuiaFound(false)}>
                        <IconArrowLeft stroke={2.5} size="1.5rem" color={theme.palette.secondary.main} />
                    </Stack>
                    <PedidoView guia={pedidoData.guia.id} standalone={false} data={pedidoData} />
                </Stack>
            )}
        </>
    );

    function Row({ guia }) {
        const theme = useTheme();
        const [open, setOpen] = React.useState(false);

        return (
            <>
                <TableRow hover sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }} onClick={() => setGuiaFound(true)}>
                    <TableCell align="center">
                        <Typography variant="h4">{guia.id} </Typography>
                        <Typography variant="smallsub">{guia.estatus} </Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                            {guia.cliente_nombre}{' '}
                        </Typography>
                        <Typography variant="smallsub">Cliente</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                            {guia.origen_fecha} - Bloque {guia.origen_bloque}{' '}
                        </Typography>
                        <Typography variant="smallsub">Recoleccion</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                            {guia.entrega_fecha} - Bloque {guia.entrega_bloque}{' '}
                        </Typography>
                        <Typography variant="smallsub">Entrega</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                            {guia.paq_tipo}{' '}
                        </Typography>
                        <Typography variant="smallsub">Paquete</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                            {guia.precio_venta}{' '}
                        </Typography>
                        <Typography variant="smallsub">Precio</Typography>
                    </TableCell>
                </TableRow>
            </>
        );
    }
};

export default NoPedidoTab;
