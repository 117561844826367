import React,{useState,useEffect} from 'react'
import { Grid, Button, Typography, TextField, Divider, Box, Stack,LinearProgress, useMediaQuery, Card   } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
    BlobProvider,
    Svg,
    G,
    Polygon,
    PDFDownloadLink,
    Font
  } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Poppins',
        fontSize: 11,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        lineHeight: 1.5,
        flexDirection: 'column',
        width: 200
    },
    section: {
      marginLeft: 10,
      padding: 0,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
    tableContainer: {
     
        flexWrap: 'wrap',
        marginLeft: 40,
        paddingLeft: 2,
        marginTop: 24,
        marginRight: 5,
        borderLeftWidth: 3,
        borderColor: '#A6A6A6',
        
    },
    qrImage: {
        width: 80,
        height: 80
      }
})

const CreatePdf = ({ id }) => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('md'));
    const dataUrl = document.getElementById(id).toDataURL();


    useEffect(() => {
       
    }, []);

    Font.register({
        family: 'Poppins',
        fonts: [
            {
                src: "https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2FPoppins-Regular.ttf?alt=media&token=1ccd49d2-0ff6-42c5-a692-4e79a0850f7b",
                fontWeight: 400
            },
            {
                src: "https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2FPoppins-Bold.ttf?alt=media&token=856876c7-1f10-4d2f-8baf-6101415a74dc",
                fontWeigth: 600
            }
        ]
    });

    const guiaDoc = (
        <Document onRender={(blob) => handleCallback('renderizado...')}>
            <Page size="LETTER" style={styles.page} pageNumber={1} key={1}>
                <View style={styles.section}>
                    <View style={styles.tableContainer}>
                        <Image allowDangerousPaths src={dataUrl} style={styles.qrImage} />
                    </View>
                </View>
            </Page>
        </Document>
    );

    const GuiaDocument = () => (
        guiaDoc
    );

    const handleCallback = () => {
      
    };

    return (
        <>
            <PDFViewer style={{width: '100%', height: 800}} showToolbar={true}>
                <GuiaDocument />
            </PDFViewer>
        </>
    );

}



export default CreatePdf;