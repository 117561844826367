import  {useEffect, useState} from 'react';
import axios from '../../utils/axios';
import { Button, IconButton, Typography, Grid} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import PdfTest from 'views/pdf/pdfTest';
import CrearPedidoMain from './CrearPedido/CrearPedidoMain';



const PedidosMain = () => {
const [fileData, setFileData] = useState([]);

useEffect(() => {
    
},[])

     return (
            <MainCard >
                <CrearPedidoMain />
            </MainCard>
   
            
        );
    }
    


export default PedidosMain