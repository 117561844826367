import React,{forwardRef,useState,useEffect,createRef} from 'react'
import { useTheme, styled } from '@mui/material/styles';
import axios from 'utils/axios';
// material-ui
import { Grid, Button, Dialog,  Slide, Typography, useMediaQuery,LinearProgress, Stack, Divider, 
  InputAdornment, Box,OutlinedInput, Link} from '@mui/material';


import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'



import SearchIcon from '@mui/icons-material/Search';
import {shouldForwardProp } from '@mui/system';
import { namedDateFull } from 'utils/helpers';
import Avatar from 'ui-component/extended/Avatar';

import { IconX } from '@tabler/icons';

import EvidenciaPdf from './EvidenciaPdf';
import Compress from "compress.js";
import { first } from 'lodash';

// slide animation
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const bcImg = "https://firebasestorage.googleapis.com/v0/b/hawk-admin.appspot.com/o/hawk-users%2Fbarcode-enabled10.png?alt=media&token=06581c33-fd26-4085-96b7-deb905815b27";
// ===============================|| UI DIALOG - FULL SCREEN ||=============================== //

export default function SearchDialog({open, handleCloseDialog, data, guia}) {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const [searchResult,setSearchResult] = useState({searchStatus: -1, searchMessage:''});
    const [guiaSearch, setGuiaSearch] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [evento, setEvento] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [pdfView, setPdfView] = useState(false);
    const [pdfGen, setPdfGen] = useState(false);
    const [pdfCreate, setPdfCreate] = useState(false);
    const [guiaData, setGuiaData] = useState({});

   
    React.useEffect(() => {
        dataProcess(data,guia);
        }, []);

    const removeExif = async (fotoUrl) => {
        const b64 = await getBase64FromUrl(fotoUrl);
        console.log(b64);
        const splitted = b64.split(',');
        const firstChar = splitted[1].charAt(0);
        let type = '';

        switch(firstChar) {
            case '/':
                type='image/jpeg';
                break;
            case 'i':
                type='image/png';
                break;
            case 'R':
                type='image/gif';
                break;
            case 'U':
                type='webp';
                break;
            default: 
                type='image/jpeg';
        }

        
        let file = new File([Compress.convertBase64ToFile(splitted[1],type)], 'converted-1.jpeg');
        const files = [file];

        return new Promise((resolve) => {
            const compress = new Compress();
            compress.compress(files, {
                size: 4,
                quality: 0.75,
                maxWidth: 1920,
                maxHeight: 1920,
                resize: true
               }).then(modFiles => { 
                    const base64 = `data:image/jpeg;base64,${modFiles[0].data}`;
                    resolve(base64);
               });
        });
    }

    const dataProcess = async (e, guiaDatos) => {

             let fotosArray = [];
            let ev = {};
            for (const foto of e.act_info.fotos) {
                const noExif = await removeExif(foto.foto_url);
                console.log(noExif);
                fotosArray.push({b64: noExif});
            }

            if (e.tipo == 62) {
                ev={tipo: 1, nombre: e.act_nombre, guia: guiaDatos.id, time: e.act_dtime, 
                   calleNum: `${guia.origen_calle} ${guia.origen_numero} ${guia.origen_numero_int}`,
                   colMun:`${guia.origen_colonia}, ${guia.origen_municipio}, ${guia.origen_codigo_postal}`,
                   text: e.act_info.motivo, 
                   notas: e.act_info.comentarios,
                   by: e.act_by,
                   byImage: e.act_info.by_foto_url,
                   gpsImage: null,
                   fotos: fotosArray,
                }
            } else if (e.tipo == 63) {
                ev={tipo: 2, nombre: e.act_nombre, guia: guiaDatos.id, time: e.act_dtime, 
                    calleNum: `${guia.entrega_calle} ${guia.entrega_numero} ${guia.entrega_numero_int}`,
                    colMun:`${guia.entrega_colonia}, ${guia.entrega_municipio}, ${guia.entrega_codigo_postal}`,
                    text: e.act_info.motivo, 
                    notas: e.act_info.comentarios,
                    by: e.act_by,
                    byImage: e.act_info.by_foto_url,
                    gpsImage: null,
                    fotos: fotosArray,
                 }
            } else if (e.tipo == 9) {
                //const gpsImg = await getBase64FromUrl(e.act_info.gpsImage);
                


                ev={tipo: 3, nombre: e.act_nombre, guia: guiaDatos.id, time: e.act_dtime, 
                    calleNum: `${guia.entrega_calle} ${guia.entrega_numero} ${guia.entrega_numero_int}`,
                    colMun:`${guia.entrega_colonia}, ${guia.entrega_municipio}, ${guia.entrega_codigo_postal}`,
                    text: `Recibió: ${e.act_info.entregado_a}` ,
                    notas: e.act_info.comentarios,
                    by: e.act_by,
                    byImage: e.act_info.by_foto_url,
                    gpsImage: e.act_info.gps_image,
                    fotos: fotosArray,
                 }

                
            }

            

            setEvento(ev);
            setPdfGen(true);
            setPdfCreate(true);
        }

    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = () => {
            const base64data = reader.result;  
            //console.log(base64data); 
            resolve(base64data);
            }
        });
        }

    const handleClickOpen = () => {
        
        setOpen(true);
    };

    const handleClose = () => {
        setPdfCreate(false);
        setPdfGen(false);
        setGuiaData({})
        handleCloseDialog(false);
        
    };

 const handleCrearPedido = () => {
       
    };



      
    return (
        <div>
           
            <Dialog 
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            keepMounted
            sx={{
                '&>div:nth-of-type(3)': {
                    justifyContent: 'center',
                    '&>div': {
                        m: 0,
                        p:0,
                        borderRadius: '0px',
                        minWidth: matchDownMd ? .9 : .45 ,
                        minHeight: '100%',
                    }
                }
            }}
            >
                {open && (
                    <>
                       
                    <Grid>
                        <Stack sx={{mx:2}}>
                            <Stack direction="row" justifyContent="space-between" alignItems= "center" sx={{my:.5, mx: 0}}>
                                <Stack direction="row"  alignItems="center" sx={{mt:1, cursor: 'pointer'}} onClick={handleClose}>
                                    <IconX stroke={2.5} size="1.8rem"  />
                                </Stack>
                                <Stack direction="row"  alignItems="center" sx={{mt:1}}>
                                    {pdfGen && <EvidenciaPdf evento={evento} guia={guia} create={pdfCreate}/>}
                                </Stack>
                            </Stack>
                            
                            <Divider sx={{my: 2 }}/>
                                <Stack sx={{mx:2, my:2}} >
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <Stack >
                                                <Stack direction='row' alignItems='center'>
                                                    <Stack sx={{ml: 1}}>
                                                        <Typography variant={"h3"} align="left" >{evento.nombre}</Typography>
                                                        <Typography variant={"body2"} align="left" >No. Guía: {evento.guia}</Typography>
                                                    </Stack>
                                                    
                                                </Stack>
                                                <Box sx={{width: '90%', py: 2, px: 2, mt: 2,  bgcolor: theme.palette.grey[200], borderRadius: 2}}>
                                                    
                                                    <Typography variant={"h6"} color="secondary">{evento.calleNum}</Typography>
                                                    <Typography variant={"h6"} color="secondary">{evento.colMun}</Typography>
                                                    <Typography variant={"body2"} sx={{mt:.5,fontSize: 12}}>{evento.time ? namedDateFull(new Date(evento.time)) : ''}</Typography>
                                                    <Typography variant={"body2"} sx={{ fontSize: 12}} >{evento.text}</Typography>
                                                    <Typography variant={"caption"} sx={{ fontSize: 11}} >{evento.notas}</Typography>
                                                    <Typography variant={"h6"} sx={{mt:1, ml:0}} >Entregado por:</Typography>
                                                    <Stack direction='row'  alignItems="center" sx={{mt:.5, ml:1}}>
                                                        {/* <Avatar alt="User 1" src={data.act_info.by_foto_url} size="sm"/> */}
                                                        <img src={evento.byImage} width={30}  style={{borderRadius: '50%'}}></img>
                                                    <Typography variant={"body2"} sx={{ ml: 1,fontSize: 11}} >{evento.by}</Typography>
                                                </Stack>
                                                </Box>
                                                
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={11} md={5} sx={{p:2}}>
                                            <Zoom>
                                                <img src={evento.gpsImage} width={250} ></img>
                                            </Zoom>
                                            
                                        </Grid>
                                        
                                    
                                    </Grid>
                                    <Divider sx={{my: 2 }}/>
                                    {data.act_info.fotos && data.act_info.fotos.length >= 1 && 
                                        <Grid container >
                                            {data.act_info.fotos.map((f,i) => {
                                                return (
                                                        <Grid item key={i} xs={5} md={3} sx={{ml:2}}>
                                                            <Zoom >
                                                                <img src={f.foto_url} width={'100%'} ></img>
                                                            </Zoom>
                                                            {/* <Avatar src={f.foto_url} variant="rounded" sx={{width: '100%', height: '80%'}}></Avatar> */}
                                                        </Grid>
                                                );
                                            })}   

                                        </Grid>}



                                </Stack>
    
                        </Stack>  
                    </Grid>
                     
                    </>
                )}
            </Dialog>
        </div>
    );
}

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    background: theme.palette.grey[200],
    color: theme.palette.secondary.main,
    '&:hover': {
        background: theme.palette.grey[400],
        color: theme.palette.secondary.main
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 220,
    marginLeft: 2,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
    }
}));

