import React from "react";
import { MapContainer, TileLayer, FeatureGroup, Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from "leaflet";
import {
  Grid,
  LinearProgress,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';


const PreviewMap = ({data,loading}) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const [isLoaded, setIsLoaded] = React.useState(false);


  React.useEffect(() => {
    
  }, []);

  React.useEffect(() => {
        if(!loading) {
          const map = mapRef.current.leafletElement
          var bounds = L.latLngBounds(latlng1,latlng2).pad(.3);
         
          //var bounds = layer.latLngBounds(latlng1,latlng2).pad(.3);
          map.fitBounds(bounds);
          map.dragging.disable();
        }
        
  }, [loading]);

 let mapRef = React.createRef();
 let groupRef = React.createRef();

  var latlngs = [];
  const origenLat = 25.676093182540075;
  const origenLng = -100.32210416812627;
  const destinoLat = 25.76069557444423;
  const destinoLng = -100.18410491008419;

  var latlng1 = [origenLat, origenLng];
  var latlng2 = [destinoLat, destinoLng];

  var offsetX = latlng2[1] - latlng1[1];
  var offsetY = latlng2[0] - latlng1[0];

  var r = Math.sqrt( Math.pow(offsetX, 2) + Math.pow(offsetY, 2) ),
  theta = Math.atan2(offsetY, offsetX);

  var thetaOffset = (3.14/10);
  
  var r2 = (r/2)/(Math.cos(thetaOffset)),
    theta2 = theta + thetaOffset;
  
  var midpointX = (r2 * Math.cos(theta2)) + latlng1[1],
    midpointY = (r2 * Math.sin(theta2)) + latlng1[0];

  var midpointLatLng = [midpointY, midpointX];

latlngs.push(latlng1, midpointLatLng, latlng2);

const pathTwo = ['M', latlng1,
'Q', midpointLatLng,
  latlng2]


  const fitToCustomLayer = () => {
    if (mapRef.current ) { //we will get inside just once when loading
        const map = mapRef.current.leafletElement
        var bounds = L.latLngBounds(latlng1,latlng2).pad(.3);
        //var bounds = layer.latLngBounds(latlng1,latlng2).pad(.3);
        map.fitBounds(bounds);
        
    }
}

var myIcon = L.icon({
  iconUrl: 'https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2F2.png?alt=media&token=4dc789d0-61e4-4402-91f7-f98e3a476fdf',
  iconSize: [35, 35],
  iconAnchor: [19, 32],
  popupAnchor: [-3, -76],
  shadowUrl: 'https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fmarker-shadow.png?alt=media&token=0ff7331c-b49f-4802-bb6e-b65c2a178b9a',
  shadowSize: [32, 32],
  shadowAnchor: [11, 30],
 
});
var iconDiv = new L.DivIcon({
    html: '<span>HOLA</span>'
});

/* var myIcon = L.icon({
  iconUrl: 'https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2F2.png?alt=media&token=4dc789d0-61e4-4402-91f7-f98e3a476fdf',
  iconSize: [50, 50],
  iconAnchor: [25, 47],
  popupAnchor: [-3, -76],
  shadowUrl: 'https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fmarker-shadow.png?alt=media&token=0ff7331c-b49f-4802-bb6e-b65c2a178b9a',
  shadowSize: [41, 41],
  shadowAnchor: [13, 38]
}); */
      

    return (
      <Grid>
         <MapContainer ref={mapRef} center={[25.652066083542213, -100.19368340384861]} attributionControl={false}  zoom={11} zoomControl={false}
          scrollWheelZoom={false} style={{height: matchDownMd ? '220px': '250px',overflow: 'hidden'}} >
          <TileLayer
            url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
            
          />
        {!loading &&
          <FeatureGroup ref={groupRef}>
            

           
              <><Marker position={[origenLat, origenLng]} icon={myIcon} ></Marker>
            <Marker position={[destinoLat, destinoLng]} icon={myIcon}></Marker></>
          </FeatureGroup>}

        </MapContainer>
         {loading && <LinearProgress />}</Grid>
    );

}

export default PreviewMap;