import PropTypes from 'prop-types';
import React,{ forwardRef, useEffect, useRef, useState, Fragment } from 'react';
import axios from 'utils/axios';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import esLocale from 'date-fns/locale/es';
import {format, addDays} from 'date-fns';

import { namedDate } from 'utils/helpers';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme, styled } from '@mui/material/styles';
import {makeStyles} from '@mui/styles';


import {
    Button,
    Dialog,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    LinearProgress,
    Link,
    useMediaQuery,
    Slide,
    Autocomplete,
} from '@mui/material';


import NoAddressDialogs from 'views/upload-file/components/NoAddressDialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';





const ProgramRecDialog = ({open, handleCloseDialog, clase, blocked}) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const [options, setOptions] = React.useState([]);
    const [direccion, setDireccion] = React.useState({});
    const [value, setValue] = React.useState('');
    const [initialIndex, setInitialIndex] = React.useState(0);
    const [valueBasic, setValueBasic] = React.useState(null);
    const [claseTitle, setClaseTitle] = React.useState('');
    const [diaTitle, setDiaTitle] = React.useState('');
    const [fechaTitle, setFechaTitle] = React.useState('');
    const [fechaState, setFechaState] = React.useState(null);
    const [bloqueState, setBloqueState] = React.useState(0);
    const [errorText, setErrorText] = React.useState('');
    const [dateSelected, setDateSelected] = React.useState(false);
    const [bloquesDisp, setBloquesDisp] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [dpOpen, setDpOpen] = useState(false);
  

    React.useEffect(() => {
    
    }, []);

    const fetchDateValidator = async (date) => {
        
        const apiUrl = `/api/v1/pedidos/bloquevalrec`;
        const apiData = {fecha: date};
    
        await axios.post(apiUrl, apiData).then(response => {

           setBloquesDisp(response.data);
            const timer = setTimeout(() => {
                setIsLoading(false);
            }, 500);
            
                });
    }

    const handleDateChange = (e) => {
        setBloqueState(0);
        setIsLoading(true);
        console.log(e);
        setValueBasic(e);
        fetchDateValidator(e);
        setFechaState(e);
        setDateSelected(true);
     };

    const handleBloqueChange = (e) => {

       const bloque = e.target.value;
       console.log(bloque);
       setBloqueState(bloque);
       setErrorText('');

    };

    const disableWeekends = (date) => {
       let bMatched = false;
        if(date.getDay() === 0) {
            return true;
        } else {
            blocked.map((val) => {
                
                if (val == date.toISOString().slice(0, 10)) {
                   bMatched = true;
                
                }
            });
        }
        return bMatched;
      }

      const handleClose = (action) => {
        if(action == 'usar') {
            if (fechaState == null) {
                setErrorText('La fecha es requerida');
            }
            else if (bloqueState == 0) {
                setErrorText('El horario es requerido');
            } else {
                handleCloseDialog(1,fechaState, bloqueState);
            }
        } else if (action == 'cancelar') {
            handleCloseDialog(2);
        }
        
        
      }

    return (
        <Dialog
            open={open}
            keepMounted
        >
            {open && (
                    <Grid sx={{height: 400, mx:3}}>
                        <Typography variant="smallsub" sx={{mb:0, lineHeight: '1.5rem', color: "#323232", fontSize: 12}}>Programar</Typography>
                        <Typography variant="h1" color="primary" sx={{mb:0, lineHeight: '1.4rem', fontSize: 26}}>RECOLECCIÓN</Typography>
                        
                        <Grid sx={{mb:0, mt:0}}>
                            
                        </Grid>
                       <Grid sx={{mt:1}}>
                            <Stack>
                                    <Typography variant="h6" sx={{mb:1}}>Selecciona la fecha de recolección</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                        <DesktopDatePicker
                                            inputFormat="dd/MM/yyyy"
                                            open={dpOpen}
                                            onOpen={() => setDpOpen(true)}
                                            onClose={() => setDpOpen(false)}
                                            disablePast
                                            renderInput={(props) => <TextField  fullWidth {...props} onClick={(e) => setDpOpen(true)} />}
                                            inputProps={{placeholder :'Selecciona la fecha', readOnly: true}}
                                            value={valueBasic}
                                            closeOnSelect
                                            onChange={(newValue) => {handleDateChange(newValue)}}
                                            shouldDisableDate={disableWeekends}
                                        />
                                    </LocalizationProvider>
                            </Stack>
                        </Grid>
                        { isLoading ? 
                            <Grid sx={{height: 120, mt:1}}> 
                                <Grid item xs={8} sm={8} lg={10} sx={{ mt: 1, ml: 1}}>
                                    <LinearProgress />
                                </Grid>
                            </Grid> 
                            :
                        <Grid sx={{height: 120, mt:1}}>
                        { dateSelected && bloquesDisp == 12 && 
                            <Grid>
                                <Stack>
                                    <Typography variant="h6" sx={{my:1}}>Selecciona el horario de recolección</Typography>
                                    <FormControl sx={{ml:3}}>
                                        <RadioGroup
                                            aria-labelledby="lista-tiendas-radio-buttons-group-label"
                                            name="lista_tiendas_id"
                                            value={bloqueState}
                                            onChange={handleBloqueChange}
                                        >
                                            <FormControlLabel value={1} control={<Radio  size="small" /> }  label='10:00am a 3:00pm' />
                                            <FormControlLabel value={2} control={<Radio size="small" />} label='4:00pm a 9:00pm' />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>}
                            { dateSelected && bloquesDisp == 1 && 
                            <Grid>
                                <Stack>
                                    <Typography variant="h6" sx={{mb:1}}>Selecciona el horario de recolección</Typography>
                                    <FormControl sx={{ml:3}}>
                                        <RadioGroup
                                            aria-labelledby="lista-tiendas-radio-buttons-group-label"
                                            name="lista_tiendas_id"
                                            value={bloqueState}
                                            onChange={handleBloqueChange}
                                        >
                                            <FormControlLabel value={1} control={<Radio  size="small" /> }  label='10:00am a 3:00pm' />

                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>}
                            { dateSelected && bloquesDisp == 2 && 
                            <Grid>
                                <Stack>
                                    <Typography variant="h6" sx={{mb:1}}>Selecciona el horario de recolección</Typography>
                                    <FormControl sx={{ml:3}}>
                                        <RadioGroup
                                            aria-labelledby="lista-tiendas-radio-buttons-group-label"
                                            name="lista_tiendas_id"
                                            value={bloqueState}
                                            onChange={handleBloqueChange}
                                        >
                                            <FormControlLabel value={2} control={<Radio size="small" />} label='4:00pm a 9:00pm' />
                                        </RadioGroup>
                                    </FormControl>
                                </Stack>
                            </Grid>}
                        </Grid>}
                        <Grid sx={{my:2}
                    }>
                            <Typography variant="smallsub" color="error">{errorText}</Typography>
                        </Grid>
                      
                        <Grid sx={{my:2}}>
                            <Button variant="contained" onClick={()=> {handleClose('usar')}}>
                               Usar
                            </Button>
                            <Button  onClick={()=> {handleClose('cancelar')}} sx={{ml:1}}>
                               Cancelar
                            </Button>
                        </Grid>
                    </Grid>
            )}
        </Dialog>
    );
}
export default ProgramRecDialog;

