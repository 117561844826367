import React, { useEffect, useState, useRef } from "react";
import axios from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import L, { divIcon } from "leaflet";
import {
  TileLayer,
  MapContainer,
  LayersControl,
  Marker,
  Polyline,
  Popup,
  useMapEvents,
  Circle,
  FeatureGroup
} from "react-leaflet";

import { Grid, Button, Typography, ToggleButton, ToggleButtonGroup} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import {IconArrowLeft } from '@tabler/icons';


import RoutingControl from './RoutingControl'
import './markers.css';
import './styles.css'

const maps = {
  base: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
};

var myIcon = L.icon({
  iconUrl: 'https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2F2.png?alt=media&token=4dc789d0-61e4-4402-91f7-f98e3a476fdf',
  iconSize: [35, 35],
  iconAnchor: [19, 32],
  popupAnchor: [-3, -76],
  shadowUrl: 'https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fmarker-shadow.png?alt=media&token=0ff7331c-b49f-4802-bb6e-b65c2a178b9a',
  shadowSize: [32, 32],
  shadowAnchor: [11, 30],
  name: 'Hola'
 
});



const Map = ({routeId, handleBack}) => {
  let params = useParams();
  const [map, setMap] = useState(null);
  const featureGroupRef = useRef();
  const [start, setStart] = useState(false)
  const [end, setEnd] = useState([25.692775871197192, -100.290930734875]);
  const [positions,setPositions] = useState([]);
  const [positionsOps,setPositionsOps] = useState([]);
  const [multiPoly,setMultiPoly] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [sizeIcon, setSizeIcon] = useState(40);
  const [animatedPos, setAnimatedPos] = useState({});
  const [play,setPlay] = useState(false);
  const [paused,setPaused] = useState(false);
  const [animation,setAnimation] = useState(0);
  const [hasData,setHasData] = useState(false);


  useEffect(() => {
    if (!map) return;
    if(hasData) {
      map.fitBounds(featureGroupRef.current.getBounds());
      console.log(featureGroupRef.current.getBounds());
    }
   
  }, [fetched]);

  React.useEffect(() => {
    if(params.id) {
      fetchData(params.id);
    } else if (routeId) {
      fetchData(routeId);
    }
    
}, []);

const fetchData = async (id) => {
    
    const apiUrl = `/api/v1/app/gpsfbg/id/${id}`;
    //const apiData = {fecha: fecha, bloque: bloque};

    await axios.get(apiUrl).then(response => {
        console.log(response.data);
        setPositions(response.data.positions);
        setPositionsOps(response.data.operations);
        setMultiPoly(response.data.multipoly);

        if(response.data.positions.length >=1) {
          setHasData(true);
        }
        setFetched(true);
        const timer = setTimeout(() => {
           
        }, 500);
        
            });
}

const playAnimation = async (id) => {

  setPlay(true);
  setPaused(false);
  let cursor = 0;
  console.log(positions[cursor]);
  setAnimatedPos(positions[cursor]);

  const interval = setInterval(() => {
   if (!paused) {
    if (cursor === positions.length - 1) {
      cursor = 0;
      clearInterval(interval);
      return;
    }

    cursor += 1;
    setAnimatedPos(positions[cursor]);
   }
  }, 100);
  return () => {
    clearInterval(interval);
  };

}

const handleAnimation = (event, newAnimation) => {

  setAnimation(newAnimation);

  if (newAnimation == 0) {
    setPlay(false);
    setPaused(false);
  } else if (newAnimation == 1) {
    playAnimation();
  } else if (newAnimation == 2) {
    setPaused(true);
  }
};



function MyComponent() {
  const [zoomLevel, setZoomLevel] = useState(5); // initial zoom level provided for MapContainer
  
  const mapEvents = useMapEvents({
    
   
      zoomend: () => {
        const zoomLev = mapEvents.getZoom();
        const size = (2.8*zoomLev);
          setSizeIcon(size);
          console.log(zoomLev);
          console.log(`${size}px ${size}px`);
    },
    
  });

  //console.log(zoomLevel);

  return null
}

var dinamySize = '40px 40px'


var iconAnim = new L.DivIcon({
  //html: '<span></span>'
  //className: 'recoleccion-marker',
  //<div class="circle">08:54</div>
  iconSize: null,
  html: `<div class="moto-marker" style="background-size: 40px 40px; width:60px; height: 60px">
        </div>`
});

  return (
    <>
    {hasData && <div className='play-button'>
      <AnimationControls />
    </div>}
    <div className='back-button'>
      <Grid  >
        <IconArrowLeft stroke={2.5} size="2.1rem" onClick={handleBack} />
      </Grid>
    </div>
      <MapContainer
        center={[25.685743333589414, -100.30255446079279]}
        zoom={15}
        zoomControl={false}
        style={{ height: "100vh", width: "100%", padding: 0 }}
        whenCreated={map => {
          console.log('map ready');
          setMap(map)}}
      >
        <MyComponent />
        {/* *************** */}
        {/* Pass in our custom control layer here, inside of the map container */}
        {/* *************** */}
        {start &&<RoutingControl 
          position={'topleft'} 
          positions={positions} 
          color={'#757de8'} 
        />}
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Map">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url={maps.base}
            />
            <FeatureGroup ref={featureGroupRef}>
            {fetched && positionsOps.map((pos,i) => {
              console.log(pos);
              console.log(`${pos.lat},${pos.lng}`);


              let markerClass = '';

              if (pos.tipo_id === 1 || pos.tipo_id === 3) {
                markerClass = 'recoleccion-marker';
              } else if (pos.tipo_id === 2 || pos.tipo_id === 4) {
                markerClass = 'entrega-marker';
              }
               
              var iconDiv = new L.DivIcon({
                //html: '<span></span>'
                //className: 'recoleccion-marker',
                iconSize: null,
                html: `<div class="${markerClass}" style="background-size: ${sizeIcon}px ${sizeIcon}px; width: ${sizeIcon}px; height: ${sizeIcon}px">
                      <div class="circle">${i+1}</div>
                      </div>`
              });
              return (
                
                  <Marker position={[pos.lat,pos.lng]} icon={iconDiv} >
                    <Popup>
                      
                    </Popup>
                  </Marker>
              )
            })}

                  <Polyline positions={[positions]} color= '#323232'>             
                  </Polyline>

            {fetched && multiPoly.map((pos) => {
              //console.log(pos);
              return (
                <>
                  {/* <Polyline positions={[pos.ini,pos.fin]} color='red' eventHandlers={{
                    mouseover: (event) => event.target.openPopup(),
                  }}>             
                  </Polyline> */}
                  {/* <Marker position={pos.ini} icon={iconDiv} eventHandlers={{
                    mouseover: (event) => event.target.openPopup(),
                  }}>
                    <Popup>
                        {`${pos.dt}`}
                    </Popup>
                  </Marker> */}

                  <Circle center={pos.ini} pathOptions={{color: 'orange', fillColor: '#323232', fillOpacity: 1, weight: 3}} radius={2}>

                    <Popup>
                      <b>{pos.fecha+'-'+pos.hora}</b><br/>
                      {'Velocidad: '+pos.vel+' Km/h'}<br/>
                      {'Recorrido: '+pos.km+' Km'}<br/>
                      {'Orientación: '+pos.heading+' grados'}
                    </Popup>
                  </Circle>


                  </>

              )
            })}

            {play && 
              <Marker position={animatedPos} icon={iconAnim}></Marker>
            }

          
           
              </FeatureGroup>
          </LayersControl.BaseLayer>
        </LayersControl>
      </MapContainer>
    </>
  );

  function AnimationControls({ }) {

    return (
      <Grid container justifyContent="center">
        <ToggleButtonGroup value={animation} exclusive onChange={handleAnimation} aria-label="text alignment" color="primary">
          <ToggleButton value={1} aria-label="left aligned">
              <PlayArrowIcon />
          </ToggleButton>
          <ToggleButton value={2} aria-label="centered">
              <PauseIcon />
          </ToggleButton>
          <ToggleButton value={0} aria-label="right aligned">
              <StopIcon />
          </ToggleButton>
      </ToggleButtonGroup>
      </Grid>

    );
  }


};

export default Map;
