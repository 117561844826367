import React, { useState, useEffect, useRef } from 'react';
import axios from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Grid,
    Button,
    Typography,
    Stack,
    Link,
    useMediaQuery,
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    LinearProgress
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import PedidosPdf from './components/PedidosPdf';
import { namedDate } from 'utils/helpers';
import PrintIcon from '@mui/icons-material/Print';
import SubCard from 'ui-component/cards/SubCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import PrintViewDialog from './PrintViewDialog';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { deepOrange, deepPurple } from '@mui/material/colors';
import CorteXls from './components/CorteXls';

function Row({ corte, dialog, zebra }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow key={corte.id} hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ pl: 3, width: 50 }} onClick={() => setOpen(!open)}>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" onClick={() => setOpen(!open)}>
                    <Typography variant="h4">{corte.nombre.toUpperCase()} </Typography>
                    <Typography variant="caption">{corte.created_at} </Typography>
                </TableCell>
                <TableCell align="left">
                    <Stack alignItems="center" onClick={() => setOpen(!open)}>
                        <Typography variant="body2" sx={{ fontSize: 20, lineHeight: 1.2 }}>
                            {corte.cantidad}{' '}
                        </Typography>
                        <Typography variant="smallsub">{'Guías'} </Typography>
                    </Stack>
                </TableCell>
                <TableCell align="right">
                    <CorteXls corte={corte.id} corteLetra={corte.letra} />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <Box sx={{ m: 2, md: { ml: 5 } }}>
                                <TableContainer>
                                    <SubCard
                                        sx={{
                                            bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.100',
                                            mb: 2,
                                            width: { md: '100%' }
                                        }}
                                        content={false}
                                    >
                                        <Table size="small" aria-label="purchases">
                                            <TableBody>
                                                {corte.subcortes.map((sub) => (
                                                    <TableRow key={sub.id} hover>
                                                        <TableCell component="th" scope="row">
                                                            <Typography variant="caption">{sub.id}</Typography>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Typography variant="caption">
                                                                {sub.nombre} {sub.desc}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="caption">{sub.cantidad}</Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Chip
                                                                label={sub.estatus}
                                                                sx={{ backgroundColor: sub.color, color: '#FFFFFF' }}
                                                                size="small"
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ pr: 3 }}>
                                                            <Button variant="contained" size="small" onClick={() => dialog(sub.id)}>
                                                                Imprimir
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ pr: 3 }}>
                                                            <Button variant="contained" size="small" onClick={() => zebra(sub.id)}>
                                                                Zebra
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </SubCard>
                                </TableContainer>
                            </Box>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const BloquePrintTab = ({ bloque, fechaInfo }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const componentRef = useRef(null);

    const [openG1, setOpenG1] = React.useState(false);
    const [openG2, setOpenG2] = React.useState(false);
    const [openG3, setOpenG3] = React.useState(false);
    const [openPrint, setOpenPrint] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [pedidosData, setPedidosData] = React.useState([]);
    const [printDate, setPrintDate] = React.useState(new Date());
    const [municipios, setMunicipios] = React.useState({});
    const [printingData, setPrintingData] = React.useState([]);
    const [cortesData, setCortesData] = React.useState([]);

    React.useEffect(() => {
        fetchData(fechaInfo, bloque);
    }, [fechaInfo]);

    const dateHandler = (date) => {
        const fecha = new Date(fecha);
        return fecha;
    };

    const fetchData = async (fecha, bloque) => {
        const apiUrl = `/api/v1/print/getcortes`;
        const apiData = { fecha: fecha, bloque: bloque };

        //console.log(apiData);
        await axios.post(apiUrl, apiData).then((response) => {
            setCortesData(response.data);
        });
    };

    const setCorteSent = async (subcorte) => {
        const apiUrl = `/api/v1/print/setcortesent`;
        const apiData = { subcorte: subcorte };

        //console.log(apiData);
        await axios.post(apiUrl, apiData).then((response) => {
            console.log(response.data);
        });
    };

    const corteCreate = async (fecha, noBloque) => {
        setIsLoading(true);
        const apiUrl = `/api/v1/print/cortes`;
        const apiData = { fecha: fecha, bloque: noBloque };

        await axios.post(apiUrl, apiData).then((response) => {
            console.log(response.data);
            const timer = setTimeout(() => {
                console.log(apiData);
                fetchData(fechaInfo, bloque);
                setIsLoading(false);
            }, 1000);
        });
    };

    const handleDialog = async (value) => {
        const sent = await setCorteSent(value);
        //window.open(`https://hawk-admin.web.app/printcorte/${value}`);
        window.open(`/printcorte/${value}`, '_blank');
        fetchData(fechaInfo, bloque);
        //console.log(value);
        //window.open('http://www.google.com');
    };
    const closePrintDialog = (value) => {
        console.log(value);
    };

    const handleDialogZebra = async (value) => {
        const sent = await setCorteSent(value);
        //window.open(`https://hawk-admin.web.app/printcorte/${value}`);
        window.open(`/printcortezebra/${value}`, '_blank');
        fetchData(fechaInfo, bloque);
        //console.log(value);
        //window.open('http://www.google.com');
    };

    return (
        <Grid container direction="column" spacing={2}>
            {/*     {printingData.length >= 1 && <PrintViewDialog open={openPrint} handleCloseDialog={closePrintDialog} data={printingData}/>} */}
            <Grid item xs={12} md={8} sx={{ mb: 2 }}>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ color: '#2c88fb', borderColor: '#2c88fb', textTransform: 'none' }}
                    onClick={() => corteCreate(fechaInfo, bloque)}
                >
                    Crear corte
                </Button>
                {isLoading && <LinearProgress sx={{ width: 300, my: 1 }} />}
            </Grid>
            <Grid item xs={12} md={12} lg={8} sx={{ mb: 2, width: { xs: '100%', md: '80%' } }}>
                <TableContainer>
                    <Table size="small" aria-label="collapsible table">
                        <TableBody>
                            {cortesData &&
                                cortesData.map((corte) => (
                                    <Row key={corte.id} corte={corte} dialog={handleDialog} zebra={handleDialogZebra} />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default BloquePrintTab;
