import  {useEffect, useState} from 'react';
import axios from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Typography,Stack, Link, useMediaQuery,Box, Tab, Tabs, TextField, Card } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
//Dates
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import esLocale from 'date-fns/locale/es';
import { getFecha, namedDate,namedDateShort } from 'utils/helpers';
import {isValid} from 'date-fns';

import HomeIcon from '@mui/icons-material/Home';
import { IconArrowLeft} from '@tabler/icons';
import CobrosBloqueTab from './CobrosBloqueTab';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

const CobrosMain = () => {
    let params = useParams();
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();


    const [bloqueSelected, setBloqueSelected] = useState(1);
    const [hoyString, setHoyString] = useState('');
    const [punteoDate, setPunteoDate] = useState(null);
    const [isView, setIsView] = useState(false);
    const [dpOpen, setDpOpen] = useState(false);
    const [valueBasic, setValueBasic] = useState(null);
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

useEffect(() => {
    if(params.fecha) {
        if (isValid(new Date(params.fecha))) {
            var hoy = new Date(params.fecha);
            var hoyDate = new Date(hoy.getFullYear(),hoy.getMonth(),hoy.getDate() + 1,);
     
            setHoyString(params.fecha);
            setPunteoDate(hoyDate);
            setIsView(true);
        } else {
            fetchData();
        }

        if (params.bloque) {
            if (params.bloque == 1 || params.bloque ==2 ) {
                setValue(params.bloque -1);
            } 
        }
        
    }  else {
        fetchData();
    }

   
},[])

const fetchData = async (fecha) => {
    const apiUrl = `/api/v1/print/hoy`;
    await axios.get(apiUrl).then(response => {
       var serverDate = response.data;
       var hoy = new Date(serverDate);
       var hoyDate = new Date(hoy.getFullYear(),hoy.getMonth(),hoy.getDate() + 1,);

       setHoyString(serverDate);
       setPunteoDate(hoyDate);
       setIsView(true);
         });  
 }
 const handleDateChange = (e) => {
    setPunteoDate(e);
    setHoyString(getFecha(e));
  };




useEffect(() => {
    
},[])



        return (
        <Grid>
            
            <MainCard sx={{height: '100%'}}>
                <Stack direction="row"  justifyContent="space-between" alignItems="center" sx={{mt:0}}>
                <Stack direction="column" >
                    <Stack direction="row"  alignItems="center" sx={{mt:0}}>
                        <HomeIcon color="primary" sx={{fontSize: 30, mr: 1}}/>
                        <Typography variant={small ? "h6" : "h4"} align="left" >Cobros contra entrega</Typography>
                    </Stack>
                    <Typography variant="caption" align="left" sx={{fontSize: 15}}>{punteoDate && namedDate(punteoDate)}</Typography>
                </Stack>
            
                    <Stack>
                        <Typography variant="h6" sx={{mb:1}}>Cambiar de fecha</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DesktopDatePicker
                                inputFormat="dd/MM/yyyy"
                                open={dpOpen}
                                onOpen={() => setDpOpen(true)}
                                onClose={() => setDpOpen(false)}
                                renderInput={(props) => <TextField  fullWidth {...props} onClick={(e) => setDpOpen(true)} />}
                                inputProps={{placeholder :'Selecciona la fecha', readOnly: true}}
                                value={valueBasic}
                                closeOnSelect
                                onChange={(newValue) => {handleDateChange(newValue)}}
                            />
                        </LocalizationProvider>
                    </Stack>

            </Stack>
            <Grid container spacing={2} sx={{mt:2}}>
                <Grid item xs={12} md={6}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        sx={{
                            mb: 3,
                            '& a': {
                                minHeight: 'auto',
                                minWidth: 10,
                                py: 1.5,
                                px: 1,
                                mr: 2.25,
                                color: theme.palette.grey[600],
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            },
                            '& a.Mui-selected': {
                                color: theme.palette.primary.main
                            },
                            '& .MuiTabs-indicator': {
                                bottom: 2
                            },
                            '& a > svg': {
                                marginBottom: '0px !important',
                                mr: 1.25
                            }
                        }}
                    >
                            <Tab component={Link} to="#"  label='Bloque 1'  />
                            <Tab component={Link} to="#"  label='Bloque 2'  />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        
                        {hoyString && <CobrosBloqueTab fechaInfo={hoyString} bloque={1} fechaName={punteoDate && namedDateShort(punteoDate)} />}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {hoyString && <CobrosBloqueTab fechaInfo={hoyString} bloque={2} fechaName={punteoDate && namedDateShort(punteoDate)} />}
                    </TabPanel>
                
                </Grid>
            </Grid>
            </MainCard>
        </Grid>
            
        );
    }

    
    


export default CobrosMain