import PropTypes from 'prop-types';
import React,{ forwardRef, useEffect, useRef, useState, Fragment } from 'react';
import axios from 'utils/axios';

import InputLabel from 'ui-component/extended/Form/InputLabel';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme, styled } from '@mui/material/styles';
import {makeStyles} from '@mui/styles';


import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    Card,
    Link,
    useMediaQuery,
    Slide,
    Autocomplete,
} from '@mui/material';

import { useFormik } from 'formik';
import * as yup from 'yup';
import NoAddressDialogs from 'views/upload-file/components/NoAddressDialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const FavoritoDialog = ({open, handleCloseDialog, selected}) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const [options, setOptions] = React.useState([]);
    const [direccion, setDireccion] = React.useState({});
    const [value, setValue] = React.useState('');
    const [initialIndex, setInitialIndex] = React.useState(0);

    React.useEffect(() => {
        fetchData(1);
    }, []);

    const fetchData = async (clienteId) => {
        
        const apiUrl = `/api/v1/dirfav/c/${clienteId}`;
        
    
        await axios.get(apiUrl).then(response => {
               // console.log(response.data);
                //setRows(response.data);
                response.data.map((val,index) => {
                    console.log(selected);
                    if (val.id == selected) {
                        setDireccion(val);
                        setInitialIndex(index);
                    }
                });
                setOptions(response.data);
               
                });
    }

    const handleClose = (action) => {
        if(action == 'usar') {
            handleCloseDialog(1,direccion);
        } else if (action == 'cancelar') {
            handleCloseDialog(2);
        }
        
        
      }


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            sx={{
                '&>div:nth-of-type(3)': {
                    justifyContent: 'flex-end',
                    '&>div': {
                        m: 0,
                        p:0,
                        borderRadius: '0px',
                        width: matchDownMd ? .8 : .45,
                        minHeight: '100%',
                    }
                }
            }}
        >
            {open && (
                    <Grid sx={{m:3}}>
                        <Typography variant="h2" sx={{mb:0}}>Destinos favoritos</Typography>
                        <Grid sx={{mb:2, mt:2}}>
                           
                        
                        </Grid>
                        <Grid>
                        <Autocomplete
                                noOptionsText="No se encontraron coincidencias"
                                options={options}
                                
                                onChange={(event, newValue, reason) => {
                                    
                                    if(newValue) {
                                        setValue(newValue);
                                        setDireccion(newValue);
                                    } else {
                                        
                                    }
                                   
               
 
                                  }}
                                renderInput={(params) => <TextField {...params} label=""  placeholder='Buscar dirección favorita'/>}
                                renderOption={(props, option, {selected}) => (
                                    <li {...props}>
                                    <Grid container direction = "column" sx={{m:1, width: 350}}>
                                        <Typography variant="h4">{option.label}</Typography>
                                        <Typography variant="caption">{option.calle},{option.municipio}</Typography>
                                        <Divider />
                                        
                                    </Grid>
                                    </li>
                                )}
                            />
                        </Grid>
                        
                        <Grid sx={{mt:3}}>
                            <Card 
                                sx={{
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100],
                                    padding: '16px 8px 16px',
                                    mb: 1.25
                                }}>
                                <Stack >
                                    {Object.keys(direccion).length >= 1 && <><Typography variant="h5" sx={{}}>{direccion.nombre}</Typography>
                                    <Typography variant="caption" sx={{}}>{direccion.calle}  {direccion.numero} {direccion.numeroInt ? 'Int'+direccion.numeroInt : ''} </Typography>
                                    <Typography variant="caption" sx={{}}>{direccion.colonia}, {direccion.municipio} </Typography>
                                    <Typography variant="caption" sx={{}}>{direccion.codigo_postal}</Typography>
                                    <Typography variant="caption" sx={{}}>{direccion.referencias}</Typography></>}
                                    
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid sx={{my:2}}>
                            <Button variant="contained" onClick={()=> {handleClose('usar')}}>
                               Usar
                            </Button>
                            <Button  onClick={()=> {handleClose('cancelar')}} sx={{ml:1}}>
                               Cancelar
                            </Button>
                        </Grid>
                    </Grid>
            )}
        </Dialog>
    );
}
export default FavoritoDialog;

