import React, { useState, useEffect, useRef } from 'react';
import axios from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Typography, Stack, Link, useMediaQuery, Box, Tab, Tabs, TextField } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import PedidosPdf from './components/PedidosPdf';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import esLocale from 'date-fns/locale/es';

import { getFecha, namedDate } from 'utils/helpers';
import PrintIcon from '@mui/icons-material/Print';
import BloquePrintTab from './BloquePrintTab';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

const PedidosPrint = ({}) => {
    let params = useParams();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const componentRef = useRef(null);
    const [valueBasic, setValueBasic] = React.useState(null);
    const [isView, setIsView] = React.useState(false);
    const [dpOpen, setDpOpen] = useState(false);
    const [hoyString, setHoyString] = React.useState('');
    const [printDate, setPrintDate] = React.useState(null);
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const dateHandler = (date) => {
        const fecha = new Date(fecha);
        return fecha;
    };

    const fetchData = async (fecha) => {
        const apiUrl = `/api/v1/print/hoy`;

        //console.log(apiData);
        await axios.get(apiUrl).then((response) => {
            //console.log(response.data);
            var serverDate = response.data;
            var hoy = new Date(serverDate);
            var hoyDate = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() + 1);

            setPrintDate(hoyDate);
            setHoyString(serverDate);
            setIsView(true);
        });
    };

    const handleDateChange = (e) => {
        setPrintDate(e);
        setHoyString(getFecha(e));
    };

    const closeBarcodeDialog = (val) => {
        console.log(val);
        setOpenBarcode(false);
    };

    return (
        <MainCard>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
                <Stack direction="column">
                    <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
                        <PrintIcon color="primary" sx={{ fontSize: 30, mr: 1 }} />
                        <Typography variant={matchDownMd ? 'h6' : 'h4'} align="left">
                            Impresión de guías
                        </Typography>
                    </Stack>
                    <Typography variant="caption" align="left" sx={{ fontSize: 15 }}>
                        {printDate && namedDate(printDate)}
                    </Typography>
                </Stack>

                <Stack>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Selecciona la fecha de recolección
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                        <DesktopDatePicker
                            inputFormat="dd/MM/yyyy"
                            open={dpOpen}
                            onOpen={() => setDpOpen(true)}
                            onClose={() => setDpOpen(false)}
                            renderInput={(props) => <TextField fullWidth {...props} onClick={(e) => setDpOpen(true)} />}
                            inputProps={{ placeholder: 'Selecciona la fecha', readOnly: true }}
                            value={valueBasic}
                            closeOnSelect
                            onChange={(newValue) => {
                                handleDateChange(newValue);
                            }}
                        />
                    </LocalizationProvider>
                </Stack>
            </Stack>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={10}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        sx={{
                            mb: 3,
                            '& a': {
                                minHeight: 'auto',
                                minWidth: 10,
                                py: 1.5,
                                px: 1,
                                mr: 2.25,
                                color: theme.palette.grey[600],
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            },
                            '& a.Mui-selected': {
                                color: theme.palette.primary.main
                            },
                            '& .MuiTabs-indicator': {
                                bottom: 2
                            },
                            '& a > svg': {
                                marginBottom: '0px !important',
                                mr: 1.25
                            }
                        }}
                    >
                        <Tab component={Link} to="#" label="Bloque 1" />
                        <Tab component={Link} to="#" label="Bloque 2" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        {hoyString && <BloquePrintTab fechaInfo={hoyString} bloque={1} />}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {hoyString && <BloquePrintTab fechaInfo={hoyString} bloque={2} />}
                    </TabPanel>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default PedidosPrint;
