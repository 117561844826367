import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import OpsLayout from 'layout/OpsLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import PedidosMain from '../views/pedidos';
import ClientesMain from '../views/clientes/ClientesMain';
import ClienteDetail from '../views/clientes/ClienteDetail/ClienteDetail';
import CoverageMap from '../views/mapCoverage/MapComponent';
import PreviewMap from '../views/pedidos/CrearPedido/components/PreviewMap';
import PedidoView from '../views/pedidos/PedidoView/PedidoView';
import PedidosSearch from 'views/pedidos/PedidosSearch/PedidosSearch';
import MigratePedidos from '../views/pedidos/MigratePedidos/MigratePedidos';
import PedidosPrint from '../views/pedidos/PedidosPrint/PedidosPrint';
import PrintViewA4 from '../views/pedidos/PedidosPrint/PrintView-a4';
import PrintView from 'views/pedidos/PedidosPrint/PrintCorteView';
import PunteoPagos from '../views/pedidos/PunteoPagos/PunteoPagos';
import PedidoPdf from '../views/pedidos/PedidoPdf/PedidoPdf';
import MapComponent from '../views/recorridos/map/TrackingMap';
import CobrosCaptura from 'views/pedidos/PedidosCobros/CobrosCaptura';
import CobrosMain from 'views/pedidos/PedidosCobros/CobrosMain';
import RoutesMap from '../views/recorridos/routesMap/RoutesMap';

const PunteoVirtual = Loadable(lazy(() => import('views/pedidos/PunteoVirtual/PunteoVirtual')));
const OperacionesMain = Loadable(lazy(() => import('views/operaciones/OperacionesMain')));
const PunteoRecMain = Loadable(lazy(() => import('views/operaciones/PunteoRecoleccion/PunteoRecMain')));
const PedidosSemanal = Loadable(lazy(() => import('views/reportes/PedidosSemanal/PedidosSemanal')));


// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));


// ==============================|| MAIN ROUTING ||============================== //
    const MainRoutes = {
        path: '/',
        element: (
            <AuthGuard>
                <MainLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '/',
                element: <PedidosPrint />
            },
            {
                path: '/sample-page',
                element: <SamplePage />
            },
            {
                path: '/pedido-add',
                element: <PedidosMain />
            },
            {
                path: '/clientes',
                element: <ClientesMain />
            },
            {
                path: '/cliente-detail/:id',
                element: <ClienteDetail />
            },
            {
                path: '/coverage',
                element: <CoverageMap />
            },
            {
                path: '/premap',
                element: <PreviewMap />
            },
            {
                path: '/pedidos/:id',
                element: <PedidoView />
            },
            {
                path: '/migrate',
                element: <MigratePedidos />
            },
            {
                path: '/print',
                element: <PrintView />
            },
            {
                path: '/printa4',
                element: <PrintViewA4 />
            },
            {
                path: '/pedidosprint',
                element: <PedidosPrint />
            },
            {
                path: '/pedidossearch',
                element: <PedidosSearch />
            },
            {
                path: '/punteopagos',
                element: <PunteoPagos />
            },
            {
                path: '/ppdf',
                element: <PedidoPdf />
            },
            {
                path: '/map',
                element: <MapComponent />
            },
            {
                path: '/routesmap',
                element: <RoutesMap />
            },
            {
                path: '/week',
                element: <PedidosSemanal />
            },
            {
                path: '/cobros/main',
                element: <CobrosMain />
            },
            {
                path: '/cobros/main/:fecha',
                element: <CobrosMain />
            },
            {
                path: '/cobros/main/:fecha/:bloque',
                element: <CobrosMain />
            },
            {
                path: '/cobros/captura/:id',
                element: <CobrosCaptura />
            },
        ]
    };

 




export default MainRoutes;
