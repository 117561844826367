import React,{forwardRef,useState,useEffect} from 'react'
import { useTheme } from '@mui/material/styles';

import { Grid, Typography,  Stack,  Avatar} from '@mui/material';



    export default function ConfirmWidget({component, confirm, message, siFunction, noFunction, width}) {
        const theme = useTheme();

        React.useEffect(() => {
        }, []);


        const handleChange = (e) => {
            //console.log(e);
        };

        return (
            <Grid sx={{width: width ? width : null}}>
                {confirm ? 
                <Stack sx={{mx: 4}}>
                    <Typography variant="h6" sx={{mb:1}}>{message}</Typography>
                    <Stack direction="row" justifyContent="space-evenly" alignItems= "center" sx={{mb: .5}}>
                        <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    transition: 'all .2s ease-in-out',
                                    background:  theme.palette.grey[200],
                                    color:  '#E64848' ,
                                    '&[aria-controls="menu-list-grow"],&:hover': {
                                        background: '#E64848',
                                        WebkitTextFillColor: '#FFF',
                                        color: '#FFF'
                                    }
                                }}
                                color="inherit"
                                onClick={siFunction}
                            ><Typography sx={{color: theme.palette.text.primary}}>Si</Typography></Avatar>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.grey[200],
                                color:'#E64848' ,
                                '&[aria-controls="menu-list-grow"],&:hover': {
                                    background:  theme.palette.secondary.main,
                                    WebkitTextFillColor: '#FFF',
                                        color: '#FFF'
                                },
                                ml:1
                            }}
                            color="inherit"
                            onClick={noFunction}
                        ><Typography sx={{color: theme.palette.text.primary}}>No</Typography></Avatar>
                    </Stack>
                </Stack>: <>{component}</>}
            </Grid>
        );

    }