import React, { useState, useEffect, Fragment } from 'react';

import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
    Svg,
    G,
    Polygon,
    PDFDownloadLink,
    Font
  } from "@react-pdf/renderer";

  import JsBarcode from 'jsbarcode';
  import axios from '../../utils/axios';
import { borderRadius, lineHeight } from '@mui/system';
import { IconRotate } from '@tabler/icons';
import {Home} from './homeIcon-g';
import {BoxIcon2} from './boxIcon2-g';
import hawkLogo from 'assets/images/hawk4.png';
import lmLogoNew from 'assets/images/lm-logo-new2.png';



  // Create styles
  const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Poppins',
        fontSize: 11,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    section: {
      marginLeft: 10,
      padding: 0,
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
    barcodeTop: {
        width: 80,
        height: 30,
      },
    barcodeBottom: {
        width: 150,
        height: 30,
        alignContent: 'flex-start',
        textAlign: 'left'
      },
    tableContainer: {
     
        flexWrap: 'wrap',
        marginLeft: 28,
        paddingLeft: 15,
        marginTop: 24,
        marginRight: 20,
        borderLeftWidth: 3,
        borderColor: '#A6A6A6',
        
    },
    rowContainer:{
        flexDirection: 'row',
        padding: 0, 
        justifyContent: 'space-between',
        width: '100%'
    },

    simpleRow:{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingLeft: 0,
        paddingTop: 0, 
        alignItems: 'center',
        width: 120,
    },
    mainColumnContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '48%'
        },
    columnContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
       
        },
    addressContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        fontSize:8,
        fontWeight: 400,
        width: '100%',
        lineHeight: 1
        },

    comentarios: {
        height: 50,
        margin: 0,
        border: 1,
        borderColor: '#000000',
        width: '97%',
        marginTop: 6,
        padding: 3,
        borderRadius: 2,
        fontSize: 8
    },
    titleText: {
        fontSize: 12,
        fontWeight: 600,
        
    },
    captionText: {
        fontSize: 8,
        color:'grey',
        textAlign: 'right',
    },
    refText: {
        fontSize: 8,
        color:'grey',
        paddingTop: 1,

    },
    numeroGuiaContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        lineHeight: 1.2
    },
    logoPortada: {
        height: 40,
        width: 170,
        marginBottom: 0
      },
    logoHawk: {
        height: 35,
        width: 35,
        marginBottom: 0
      },
    hawkText: {
        fontSize: 11,
        lineHeight: 1,
        fontWeight: 600,
        color: "000",
        marginBottom: 5
    },
    efectivoContainer: {
        position: 'absolute',
        left: 0,
        top: 140,
    },
    cobroContainer: {
        position: 'absolute',
        left: 0,
        bottom: 140,
    },
    efectivoImage: {
        height:140
    },
    
    contactoContainer: {
        fontSize: 8,
        width: '100%',
        lineHeight: 1.2,
        padding: 4,
        textAlign: 'right'
    },
    contactoTitle: {
      fontSize:9,
      color: '#fff',
      fontWeight: 600,
      backgroundColor: '#323232',
      paddingLeft: 4
    },
    textNaranjaBold: {
        color: '#000',
        fontWeight: 600
    },
    lineaMitad: {
        position: 'absolute',
        left: 0,
        top: '48%',
        borderBottomWidth: 2,
        borderColor: '#000',
        width: '100%',
    },
    fechaDia: {
        fontSize: 16,
        lineHeight: 1.1,
        fontWeight: 600
    },
    fechaMes: {
        fontSize: 8,
        fontWeight: 400,
    },
    fechaBloque: {
        fontSize: 11,
        fontWeight: 600,
    },
    paqSize:{
        backgroundColor: "#A6A6A6",
        color: '#FFF',
        width: '26%',
        borderRadius: 3,
        alignItems: 'center'
    },
    paqNombre: {
        fontSize: 9,
        fontWeight: 600,
    },
    paqMedidas: {
        fontSize: 9,
        fontWeight: 400,
    },
    medidasRow:{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: 180,
    },
     medidasContainer: {
        flexDirection: 'column',
        width: 30,
        },
    medidasPesoContainer: {
            flexDirection: 'column',
            width: 25,
            backgroundColor: "#000",
            color: '#FFF'
            },
    medidaNum: {
        fontSize: 9,
        fontWeight: 600,
        lineHeight: 1.1,
        textAlign: 'center'
    },
    medidaTipo: {
        fontSize: 7,
        fontWeight: 400,
        textAlign: 'center'
    },
    metodoText: {
        fontSize: 8,
        lineHeight: 1.1,
        color: "grey"
    },
    tipoPagoText: {
        fontSize: 16,
        lineHeight: 1.1,
        fontWeight: 400,

    },
    precioContainer: {
        alignItems: 'center',
        fontSize: 24,
        width: '25%',
        fontWeight: 600
    },
    parteGuia: {
        fontSize: 18,
        lineHeight: 1.1,
        fontWeight: 600,
        color: "#A6A6A6"
    },
    entregaFecha: {
        fontSize: 11,
        lineHeight: 1,
        fontWeight: 600,
        color: "#A6A6A6"
    },
  });
  
  // Create Document Component
  const PdfTest = () => {
    let canvas;
    canvas = document.createElement('canvas');
    JsBarcode(canvas, '123456', {
        width: 2,
        height: 60,
        displayValue: false
    });
    const barcode = canvas.toDataURL();

    const [rows, setRows] = useState([]);
    const [fetchedData, setFetchedData] = useState([]);

    Font.register({
        family: 'Poppins',
        fonts: [
            {
                src: "https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2FPoppins-Regular.ttf?alt=media&token=1ccd49d2-0ff6-42c5-a692-4e79a0850f7b",
                fontWeight: 400
            },
            {
                src: "https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2FPoppins-Bold.ttf?alt=media&token=856876c7-1f10-4d2f-8baf-6101415a74dc",
                fontWeigth: 600
            }
        ]
    });

    const efectivoImage = "https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fefectivo-g.png?alt=media&token=9cfd50a3-c91f-4a4b-a639-3885b6e06c81";
    const cobroImage = "https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fcobro-g.png?alt=media&token=451ad8da-58a1-4ff6-8a94-4ae634f85e05"

    useEffect(() => {
        //fetchData();
    }, []);


    const fetchData = async () => {
        await axios.get("/tiendas").then(response => {
             setRows(response.data);
             setFetchedData(response.data);
             });
             
     }

     const fileName = "Invoice.pdf";

     const lmLogo = "https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Flm-logo.png?alt=media&token=5eb8fc75-138d-4987-8991-d84ae92e4681"
     const location = "https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Flocation-g.png?alt=media&token=0a5f8d24-9fdc-4802-81b6-eace17dc9e15";

    return (
        
        <PDFViewer style={{width: '100vw'}} >
            <Document>
                
                <Page  width={50} pageNumber={1}>
                <View style={styles.lineaMitad}>
                
                    </View>
                <View style={styles.efectivoContainer}>
                    <Image style={styles.efectivoImage} src={efectivoImage}/>
                </View> 
                <View style={styles.section}>
                    <View style={styles.tableContainer}>
                        <View style={styles.rowContainer}>
                            <View style={styles.mainColumnContainer}>
                                <Image style={styles.logoPortada} src={lmLogoNew}/>
                                    
                                
                                    <View style={{marginBottom: 20}}/>
                                    <View style={styles.simpleRow}>
                                        <Home />
                                        <Text style={styles.titleText}>Origen</Text>
                                    </View>
                                
                                <View style={styles.addressContainer}>
                                    <Text >Javier Rubio</Text>
                                    <Text >Xinet</Text>
                                    <Text >Vascongadas #3505 </Text>
                                    <Text >Monterrey, N.L</Text>
                                    <Text >Tel. 8115995194</Text>
                                    <Text >Entre azucena norte y cerrada de amapola norte</Text>
                                    <Text style={styles.refText} >*** Casa cafe con portón negro ***</Text>
                                </View>
                                <View style={{marginBottom: 5}}/>
                                <Text style={styles.entregaFecha} >RECOLECCIÓN</Text>
                                <View style={styles.simpleRow}>
                                    <View style={styles.columnContainer}>
                                        <Text style={styles.fechaDia} >21</Text>
                                        <Text style={styles.fechaMes} >julio</Text>
                                    </View>
                                    <View style={{paddingLeft: 10}}></View>
                                    <Text style={styles.fechaBloque}>10:00 - 15:00 HRS</Text>
                                </View>
                                <View style={{marginBottom: 5}}/>
                                <View style={styles.medidasRow}>
                                
                                    <BoxIcon2 />
                                    <View style={{marginRight: 3}}></View>
                                    <View style={styles.columnContainer}>
                                        <View style={styles.paqSize} >
                                            <Text style={styles.paqNombre}>MINI</Text>
                                        </View>
                                        <View style={{marginTop: 1}}></View>
                                        <View style={styles.medidasRow}>
                                            <View style={styles.medidasContainer}>
                                                <Text style={styles.medidaNum} >15.5</Text>
                                                <Text style={styles.medidaTipo} >ancho</Text>
                                            </View>
                                            <View style={styles.medidasContainer}>
                                                <Text style={styles.medidaNum} >21</Text>
                                                <Text style={styles.medidaTipo} >alto</Text>
                                            </View>
                                            <View style={styles.medidasContainer}>
                                                <Text style={styles.medidaNum} >15</Text>
                                                <Text style={styles.medidaTipo} >largo</Text>
                                            </View>
                                            <View style={styles.medidasPesoContainer}>
                                                <Text style={styles.medidaNum} >1</Text>
                                                <Text style={styles.medidaTipo} >Kg</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.refText} >*** Maquillaje ***</Text>
                                    </View>

                                    
                                </View>
                                <View style={{padding: 4}}>

                                </View>
                                <View style={styles.columnContainer}>
                                    <Text style={styles.metodoText}>Método de pago</Text>
                                    <Text style={styles.tipoPagoText} >EFECTIVO</Text>
                                    <Text style={styles.precioContainer}>$58</Text>
                                </View>
                                <View style={{paddingTop: 9}}>

                                </View>
                                
                                <Text style={styles.parteGuia} >RECOLECCIÓN</Text>
                                    
                            </View>
                            
                            <View style={styles.mainColumnContainer}>
                                <View style={styles.numeroGuiaContainer}>
                                        <Text style={styles.titleText}>GUIA MTY401001-28032022</Text>
                                </View>
                                <View style={styles.numeroGuiaContainer}>
                                    <Text style={styles.captionText}>Fecha captura: 29/03/2022 17:51.11</Text>
                                </View>
                                <View style={{marginBottom: 0}}/>
                                    <View style={styles.contactoContainer}>
                                        <Text style={styles.textNaranjaBold}>NUEVA LINEA 81 4738 0949  </Text>
                                        <Text >whatsapp 81 1553 8333</Text>
                                        <Text >contacto@lamensajeria.mx</Text>
                                    </View>
                                
                                <View style={{marginBottom: 0}}/>
                                <View style={styles.simpleRow}>
                                        <Image style={{height: 15, marginRight: 2}} src={location}/>
                                        <Text style={styles.titleText}>Destino</Text>
                                    </View>
                            
                                <View style={styles.addressContainer}>
                                    <Text >Javier Rubio</Text>
                                    <Text >Xinet</Text>
                                    <Text >Vascongadas #3505 </Text>
                                    <Text >Monterrey, N.L</Text>
                                    <Text >Tel. 8115995194</Text>
                                    <Text >Entre azucena norte y cerrada de amapola norte</Text>
                                    <Text style={styles.refText} >*** Casa cafe con portón negro ***</Text>
                                </View>
                                <View style={{marginBottom: 5}}/>
                                <Text style={styles.entregaFecha} >ENTREGA</Text>
                                <View style={styles.simpleRow}>
                                    <View style={styles.columnContainer}>
                                        <Text style={styles.fechaDia} >21</Text>
                                        <Text style={styles.fechaMes} >julio</Text>
                                    </View>
                                    <View style={{paddingLeft: 10}}></View>
                                    <Text style={styles.fechaBloque}>15:00 - 19:00 HRS</Text>
                                </View>
                                <View style={{marginBottom: 5}}/>
                                <Text style={styles.entregaFecha} >COMENTARIOS</Text>
                                <View style={styles.comentarios}>
                                    <Text >Si no hay gente entregar con la vecina, casa café</Text>
                                </View>
                                
                                <View style={{marginBottom: 4}}/>

                                <View style={styles.numeroGuiaContainer}>
                                        <Image style={styles.barcodeBottom} src={barcode}/>
                                </View>
                                <View style={{marginBottom: 50}}/>
                                
                                <View style={styles.numeroGuiaContainer}>
                                        <Text style={styles.hawkText} >HAWK</Text>
                                        <Image style={styles.logoHawk} src={hawkLogo}/>
                                        
                                </View>
                            </View>

                            
                        
                        </View>
                    
                            
                    </View>    
                </View>
                <View style={styles.cobroContainer}>
                    <Image style={styles.efectivoImage} src={cobroImage}/>
                </View> 
                <View style={styles.section}>
                <View style={{marginBottom: 25}}/>
                    <View style={styles.tableContainer}>
                        <View style={styles.rowContainer}>
                            <View style={styles.mainColumnContainer}>
                                <Image style={styles.logoPortada} src={lmLogoNew}/>
                                    
                                
                                    <View style={{marginBottom: 20}}/>
                                    <View style={styles.simpleRow}>
                                        <Home />
                                        <Text style={styles.titleText}>Origen</Text>
                                    </View>
                                
                                <View style={styles.addressContainer}>
                                    <Text >Javier Rubio</Text>
                                    <Text >Xinet</Text>
                                    <Text >Vascongadas #3505 </Text>
                                    <Text >Monterrey, N.L</Text>
                                    <Text >Tel. 8115995194</Text>
                                    <Text >Entre azucena norte y cerrada de amapola norte</Text>
                                    <Text style={styles.refText} >*** Casa cafe con portón negro ***</Text>
                                </View>
                                <View style={{marginBottom: 5}}/>
                                <Text style={styles.entregaFecha} >RECOLECCIÓN</Text>
                                <View style={styles.simpleRow}>
                                    <View style={styles.columnContainer}>
                                        <Text style={styles.fechaDia} >21</Text>
                                        <Text style={styles.fechaMes} >julio</Text>
                                    </View>
                                    <View style={{paddingLeft: 10}}></View>
                                    <Text style={styles.fechaBloque}>10:00 - 15:00 HRS</Text>
                                </View>
                                <View style={{marginBottom: 5}}/>
                                <View style={styles.medidasRow}>
                                
                                    <BoxIcon2 />
                                    <View style={{marginRight: 3}}></View>
                                    <View style={styles.columnContainer}>
                                        <View style={styles.paqSize} >
                                            <Text style={styles.paqNombre}>MINI</Text>
                                        </View>
                                        <View style={{marginTop: 1}}></View>
                                        <View style={styles.medidasRow}>
                                            <View style={styles.medidasContainer}>
                                                <Text style={styles.medidaNum} >15.5</Text>
                                                <Text style={styles.medidaTipo} >ancho</Text>
                                            </View>
                                            <View style={styles.medidasContainer}>
                                                <Text style={styles.medidaNum} >21</Text>
                                                <Text style={styles.medidaTipo} >alto</Text>
                                            </View>
                                            <View style={styles.medidasContainer}>
                                                <Text style={styles.medidaNum} >15</Text>
                                                <Text style={styles.medidaTipo} >largo</Text>
                                            </View>
                                            <View style={styles.medidasPesoContainer}>
                                                <Text style={styles.medidaNum} >1</Text>
                                                <Text style={styles.medidaTipo} >Kg</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.refText} >*** Maquillaje ***</Text>
                                    </View>

                                    
                                </View>
                                <View style={{padding: 4}}>

                                </View>
                                <View style={styles.columnContainer}>
                                    <Text style={styles.metodoText}>Cobro al destinatario</Text>
                                    <Text style={styles.tipoPagoText} >COBRO</Text>
                                    <Text style={styles.precioContainer}>$450</Text>
                                </View>
                                <View style={{paddingTop: 9}}>

                                </View>
                                
                                <Text style={styles.parteGuia} >ENTREGA</Text>
                                    
                            </View>
                            
                            <View style={styles.mainColumnContainer}>
                                <View style={styles.numeroGuiaContainer}>
                                        <Text style={styles.titleText}>GUIA MTY401001-28032022</Text>
                                </View>
                                <View style={styles.numeroGuiaContainer}>
                                    <Text style={styles.captionText}>Fecha captura: 29/03/2022 17:51.11</Text>
                                </View>
                                <View style={{marginBottom: 0}}/>
                                    <View style={styles.contactoContainer}>
                                        <Text style={styles.textNaranjaBold}>NUEVA LINEA 81 4738 0949  </Text>
                                        <Text >whatsapp 81 1553 8333</Text>
                                        <Text >contacto@lamensajeria.mx</Text>
                                    </View>
                                
                                <View style={{marginBottom: 0}}/>
                                <View style={styles.simpleRow}>
                                        <Image style={{height: 15, marginRight: 2}} src={location}/>
                                        <Text style={styles.titleText}>Destino</Text>
                                    </View>
                            
                                <View style={styles.addressContainer}>
                                    <Text >Javier Rubio</Text>
                                    <Text >Xinet</Text>
                                    <Text >Vascongadas #3505 </Text>
                                    <Text >Monterrey, N.L</Text>
                                    <Text >Tel. 8115995194</Text>
                                    <Text >Entre azucena norte y cerrada de amapola norte</Text>
                                    <Text style={styles.refText} >*** Casa cafe con portón negro ***</Text>
                                </View>
                                <View style={{marginBottom: 5}}/>
                                <Text style={styles.entregaFecha} >ENTREGA</Text>
                                <View style={styles.simpleRow}>
                                    <View style={styles.columnContainer}>
                                        <Text style={styles.fechaDia} >21</Text>
                                        <Text style={styles.fechaMes} >julio</Text>
                                    </View>
                                    <View style={{paddingLeft: 10}}></View>
                                    <Text style={styles.fechaBloque}>15:00 - 19:00 HRS</Text>
                                </View>
                                <View style={{marginBottom: 5}}/>
                                <Text style={styles.entregaFecha} >COMENTARIOS</Text>
                                <View style={styles.comentarios}>
                                    <Text >Si no hay gente entregar con la vecina, casa café</Text>
                                </View>
                                
                                <View style={{marginBottom: 4}}/>

                                <View style={styles.numeroGuiaContainer}>
                                        <Image style={styles.barcodeBottom} src={barcode}/>
                                </View>
                                <View style={{marginBottom: 20}}/>
                                <Text style={styles.entregaFecha} >RECIBIDO POR</Text>
                                <View style={{marginBottom: 10}}/>
                                <View style={styles.numeroGuiaContainer}>
                                        <Text style={styles.hawkText} >HAWK</Text>
                                        <Image style={styles.logoHawk} src={hawkLogo}/>
                                        
                                </View>
                            </View>

                            
                        
                        </View>
                    
                            
                    </View>    
                </View>
                </Page>
                
                    
                    </Document>
        </PDFViewer>
       

    
      
    );
  }
  export default PdfTest;