import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    dialogOpen: false
};

// ==============================|| SLICE - MENU ||============================== //

const guiadialog = createSlice({
    name: 'guiadialog',
    initialState,
    reducers: {
        openGuiaDialog(state, action) {
            state.dialogOpen = action.payload;
        }
    }
});

export default guiadialog.reducer;

export const {  openGuiaDialog } = guiadialog.actions;
