import React,{useState,useEffect, useRef} from 'react'
import axios from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Typography,Stack, Link, useMediaQuery, LinearProgress, TextField, Divider } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import esLocale from 'date-fns/locale/es';
import {getFecha, namedDate} from 'utils/helpers';


const MigratePedidos= ({}) => {
    let params = useParams();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const componentRef = useRef(null);

    const [valueBasic, setValueBasic] = React.useState(null);
    const [pedidoId, setPedidoId] = useState(params.id);
    const [openBarcode, setOpenBarcode] = React.useState(false);
    const [pedidos, setPedidos] = React.useState({
        cantidad: '',
        recSc: '',
        recCne: '',
        entSC: '',
        entCne: '',
        pedidos: [],
    });
    const [isLoading, setIsLoading] = React.useState(false);
    const [dpOpen, setDpOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isDone, setIsDone] = useState(false);
    const [status,setStatus] = useState('');


    React.useEffect(() => {
        
    }, []);


    const fetchData = async (fecha1) => {
        const apiUrl = `/api/v1/pedidos/migratesum`;
        const apiData = {fecha1: fecha1}
        setIsLoading(true);
        //console.log(apiData);
        await axios.post(apiUrl,apiData).then(response => {
           console.log(response.data);
           setPedidos({...pedidos,
            cantidad: response.data.pedidos_no,
            recSc: response.data.pRsc_no,
            recCne: response.data.pRcne_no,
            entSc: response.data.pEsc_no,
            entCne: response.data.pEcne_no,
            pedidos: response.data.pedidos,
            munRec: response.data.munRec,
            munEnt: response.data.munEnt
        });



           const timer = setTimeout(() => {
            setIsLoading(false);
            }, 500);
             });  
     }

   

     const handleDateChange = (e) => {
       console.log(getFecha(e));
       setSelectedDate(e);
       fetchData(getFecha(e));

     };
     const handleGenerar = async () => {
        console.log(new Date());
        setStatus('Procesando...');
        let counter = 0;
       for (const p of pedidos.pedidos) {
            setStatus(`Procesando(${counter})`);
           await fetchPedido(p);
           counter++;
        };
        setStatus(`Done! (${counter})`);
        setIsDone(true);
        console.log(new Date());
 
      };

      const fetchPedido = async (pedido) => {
        const apiUrl = `/api/v1/pedidos`;
        const apiData = pedido;
        //console.log(apiData);
        await axios.post(apiUrl, apiData).then(response => {
            
                });  
        }

     const disableWeekends = (date) => {
        let bMatched = false;
         if(date.getDay() === 0) {
             return true;
         } 
         return bMatched;
       }

    return (
        <MainCard>
            <Grid container sx={{mt:1, mb:2}}>
                <Grid item xs={8} md={3} lg={2} >
                    <Stack>
                        <Typography variant="h6" sx={{mb:1}}>Selecciona la fecha de recolección</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DesktopDatePicker
                                inputFormat="dd/MM/yyyy"
                                open={dpOpen}
                                onOpen={() => setDpOpen(true)}
                                onClose={() => setDpOpen(false)}
                                renderInput={(props) => <TextField  fullWidth {...props} onClick={(e) => setDpOpen(true)} />}
                                inputProps={{placeholder :'Selecciona la fecha', readOnly: true}}
                                value={valueBasic}
                                closeOnSelect
                                onChange={(newValue) => {handleDateChange(newValue)}}
                                shouldDisableDate={disableWeekends}
                            />
                        </LocalizationProvider>
                    </Stack>
                </Grid>
            </Grid>
            {isLoading ? 
            <Grid container sx={{mt:2}}>
                <Grid item xs={12} md={8} lg={6} >
                    <Stack>
                        <Typography variant={"h4"} align="left" sx={{mb:2}}>Cargando pedidos...</Typography>
                        <LinearProgress />
                    </Stack>
                </Grid>
                
            </Grid>
             
            
            :
                <>
                {selectedDate && <Grid container sx={{mt:2}}>
                    <Grid item xs={12} md={8} lg={12} >
                        <Typography variant={matchDownMd ? "h6" : "h5"} color="primary" align="left" >{namedDate(selectedDate)}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={12} sx={{mt:3}}>
                    
                        <Typography variant={matchDownMd ? "h6" : "h1"} align="left" sx={{lineHeight: '1.4rem'}}>{pedidos.cantidad}</Typography>
                        <Typography variant={'smallsub'} align="left" sx={{lineHeight: '1.4rem'}}>Total pedidos</Typography>
                        
                    </Grid>
                    <Divider />
                    <Grid item xs={12} md={8} lg={6} sx={{mt:2}}>
                        <Divider />
                        <Typography variant={matchDownMd ? "h6" : "h3"} align="left" sx={{mt:2}}>Códigos postales</Typography>
                        <Stack direction="row"  justifyContent="space-between" alignItems="start" sx={{mt: 2,mb:2}}>
                            <Stack>
                                <Typography variant={matchDownMd ? "h6" : "h4"} align="center" >{pedidos.recSc}</Typography>
                                <Typography variant={'caption'} align="left" >Origen sin CP o inválido</Typography>
                            </Stack>
                            <Stack>
                                <Typography variant={matchDownMd ? "h6" : "h4"} align="center" >{pedidos.recCne}</Typography>
                                <Typography variant={'caption'} align="left" >Origen CP no existe</Typography>
                            </Stack>
                            <Stack>
                                <Typography variant={matchDownMd ? "h6" : "h4"} align="center" >{pedidos.entSc}</Typography>
                                <Typography variant={'caption'} align="left" >Destino sin CP o inválido</Typography>
                            </Stack>
                            <Stack>
                                <Typography variant={matchDownMd ? "h6" : "h4"} align="center" >{pedidos.entCne}</Typography>
                                <Typography variant={'caption'} align="left" >Destino CP no existe</Typography>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Typography variant={matchDownMd ? "h6" : "h3"} align="left" sx={{mt:2}}>Municipios</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={6} sx={{mt:0}}></Grid>
                    <Grid item xs={12} md={8} lg={3} sx={{mt:2}}>
                        <Stack>
                            <Stack direction="row"  justifyContent="space-between" alignItems="start" sx={{mt: 1}}>
                                <Typography variant={'h6'} sx={{width: 100}} >Municipio</Typography>
                                <Typography variant={'h6'} align="right" >Recolección</Typography>
                                <Typography variant={'h6'} align="right" >Entrega</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" alignItems="start" sx={{mt: 1}}>
                                <Typography variant={'caption'} sx={{width: 100}} >Monterrey</Typography>
                                <Typography variant={'caption'}  >{pedidos.munRec.m1r}</Typography>
                                <Typography variant={'caption'}  >{pedidos.munEnt.m1e}</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" alignItems="start" sx={{mt: 1}}>
                                <Typography variant={'caption'} sx={{width: 100}} >Guadalupe</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munRec.m2r}</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munEnt.m2e}</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" alignItems="start" sx={{mt: 1}}>
                                <Typography variant={'caption'} sx={{width: 100}} >Apodaca</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munRec.m3r}</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munEnt.m3e}</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" alignItems="start" sx={{mt: 1}}>
                                <Typography variant={'caption'} sx={{width: 100}} >San Nicolás</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munRec.m4r}</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munEnt.m4e}</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" alignItems="start" sx={{mt: 1}}>
                                <Typography variant={'caption'} sx={{width: 100}} >Escobedo</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munRec.m5r}</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munEnt.m5e}</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" alignItems="start" sx={{mt: 1}}>
                                <Typography variant={'caption'} sx={{width: 100}} >San Pedro</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munRec.m6r}</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munEnt.m6e}</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" alignItems="start" sx={{mt: 1}}>
                                <Typography variant={'caption'} sx={{width: 100}} >García</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munRec.m7r}</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munEnt.m7e}</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" alignItems="start" sx={{mt: 1}}>
                                <Typography variant={'caption'} sx={{width: 100}} >Santa Catarina</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munRec.m8r}</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munEnt.m8e}</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" alignItems="start" sx={{mt: 1}}>
                                <Typography variant={'caption'} sx={{width: 100}} >Juárez</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munRec.m9r}</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munEnt.m9e}</Typography>
                            </Stack>
                            <Stack direction="row"  justifyContent="space-between" alignItems="start" sx={{mt: 1}}>
                                <Typography variant={'caption'} sx={{width: 100}} >Cadereyta</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munRec.m10r}</Typography>
                                <Typography variant={'caption'} align="right" >{pedidos.munEnt.m10e}</Typography>
                            </Stack>
                        </Stack>

                    </Grid>
                    <Grid item xs={12} md={8} lg={12} sx={{mt:0}}></Grid>
                    <Grid sx={{my:2}}>
                            <Button variant="contained" onClick={()=> {handleGenerar()}}>
                               Generar pedidos
                            </Button>
                            {<Typography variant={matchDownMd ? "h6" : "h3"} align="left" sx={{mt:2}}>{status}</Typography>}
                        </Grid>
                </Grid>}
                </>
            }
            
        </MainCard>
    );
}

export default MigratePedidos;