// third-party
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

// assets
import { IconDashboard, IconDeviceAnalytics, IconFileExport, IconListDetails, IconSquarePlus, IconPrinter, IconListCheck, IconSearch, IconFileDollar } from '@tabler/icons';

import DatabaseIcon from '../ui-component/icons/DatabaseIcon';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconFileExport,
    IconListDetails,
    IconSquarePlus,
    AddCircleOutlineIcon,
    IconPrinter,
    IconListCheck,
    IconSearch,
    IconFileDollar
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const pedidos = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'pedidos',
            title: <Typography variant='body'>Pedidos</Typography>,
            type: 'collapse',
            icon: icons.IconListDetails,
            children: [
               
                {
                    id: 'printmod',
                    title: <Typography variant='body'>Impresión</Typography>,
                    type: 'item',
                    url: '/pedidosprint',
                    icon: icons.IconPrinter,
                    breadcrumbs: false
                },
               /*  {
                    id: 'migrate',
                    title: <Typography variant='body'>Migrate</Typography>,
                    type: 'item',
                    url: '/migrate',
                    icon: icons.IconSquarePlus,
                    breadcrumbs: false
                }, */
                 {
                    id: 'punteopagos',
                    title: <Typography variant='body'>Confirmación de pagos</Typography>,
                    type: 'item',
                    url: '/punteopagos',
                    icon: icons.IconListCheck,
                    breadcrumbs: false
                }, 
                {
                    id: 'pedidossearch',
                    title: <Typography variant='body'>Buscar</Typography>,
                    type: 'item',
                    url: '/pedidossearch',
                    icon: icons.IconSearch,
                    breadcrumbs: false
                }, 
                {
                    id: 'cobrosmain',
                    title: <Typography variant='body'>Cobros</Typography>,
                    type: 'item',
                    icon: icons.IconFileDollar,
                    url: '/cobros/main',
                    breadcrumbs: false
                }
               
                
            ]
        }
    ]
};

export default pedidos;