import React, { useState, useEffect, useRef } from 'react';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { useNavigate, useParams, Link } from 'react-router-dom';
import {
    Grid,
    Button,
    Typography,
    Stack,
    useMediaQuery,
    TextField,
    InputAdornment,
    Divider,
    LinearProgress,
    Avatar,
    Box
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme, styled } from '@mui/material/styles';
import BarcodeReader from 'react-barcode-reader';
import { shouldForwardProp } from '@mui/system';
import { namedDateFull } from 'utils/helpers';

import { getFecha, namedDate } from 'utils/helpers';
import SearchIcon from '@mui/icons-material/Search';
import { IconArrowLeft } from '@tabler/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { IconChevronRight, IconPrinter, IconEdit, IconTrash, IconX, IconClipboardList } from '@tabler/icons';
import ConfirmWidget from 'main-components/ConfirmWidget';
import PaidIcon from '@mui/icons-material/Paid';
import LaunchIcon from '@mui/icons-material/Launch';

const bcImg =
    'https://firebasestorage.googleapis.com/v0/b/hawk-admin.appspot.com/o/hawk-users%2Fbarcode-enabled9.png?alt=media&token=3b9e7d47-989f-4f5e-be4e-a5c995dd12b6';

const CobrosCaptura = ({}) => {
    let params = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const { user } = useAuth();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const [isLoading, setIsLoading] = React.useState(false);
    const [bcEnabled, setBcEnabled] = useState(true);
    const [guiaStatus, setGuiaStatus] = useState({ guia: -1 });
    const [pedidoData, setPedidoData] = React.useState({});
    const [guiaSearch, setGuiaSearch] = React.useState('');
    const [cobroAmount, setCobroAmount] = React.useState('');
    const [searchStatus, setSearchStatus] = React.useState({ status: -1 });
    const [cobroStatus, setCobroStatus] = React.useState({ status: 0 });
    const [data, setData] = React.useState({
        id: '1',
        guia: '2'
    });
    const [capturados, setCapturados] = React.useState([]);

    const [cobroEdit, setCobroEdit] = React.useState(false);
    const [cobroDelConfirm, setCobroDelConfirm] = React.useState(false);
    const [incidenciaConfirm, setIncidenciaConfirm] = React.useState(false);
    const [cancelConfirm, setCancelConfirm] = React.useState(false);
    const [reinsertarConfirm, setReinsertarConfirm] = React.useState(false);
    const [bloqueInfo, setBloqueInfo] = React.useState({});
    const [bloqueResumen, setBloqueResumen] = React.useState({});

    const [valueBasic, setValueBasic] = React.useState(null);
    const [isView, setIsView] = React.useState(false);
    const [dpOpen, setDpOpen] = useState(false);
    const [hoyString, setHoyString] = React.useState('');
    const [printDate, setPrintDate] = React.useState(null);
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        fetchBloque(params.id);
    }, []);

    const fetchBloque = async (id) => {
        const apiUrl = `/api/v1/ops/cobros/bloque/${id}`;
        await axios.get(apiUrl).then((response) => {
            if (response.status == 201) {
                console.log(response.data);
                if (response.data.status == 1) {
                    setBloqueInfo(response.data.bloque);
                    setBloqueResumen(response.data.resumen);
                    setCapturados(response.data.cobros);
                }
            }
        });
    };

    const fetchGuia = async (query, tipo) => {
        const apiUrl = `/api/v1/pedidos/pedidofullfull/${query}`;
        setIsLoading(true);
        await axios.get(apiUrl).then((response) => {
            //console.log(response)
            if (response.status == 201) {
                console.log(response.data);
                if (response.data.status == 1) {
                    setSearchStatus({ status: 1, message: response.data.statusMessage });
                    setPedidoData(response.data);
                    //setGuiaSearch('');
                    if (response.data.guia.cobro_destinatario == 1 && response.data.guia.cobro_cantidad) {
                        setCobroAmount(response.data.guia.cobro_cantidad);
                    }
                    const timer = setTimeout(() => {
                        setIsLoading(false);
                    }, 500);
                } else {
                    setPedidoData({});
                    setSearchStatus({ status: 0, message: response.data.statusMessage });
                    const timer = setTimeout(() => {
                        setIsLoading(false);
                    }, 500);
                }
            } else if (response.status == 204) {
                setPedidoData({});
                setSearchStatus({ status: 0, message: 'No se encontró la guía' });
                const timer = setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }

            setBcEnabled(true);
        });
    };

    const handleSearch = (e) => {
        const newString = e.target.value;

        if (!isNaN(newString) && newString.length == 6) {
            fetchGuias(newString);
            console.log('Pedido id: ' + newString);
        } else {
            setPedidoData({});
        }
    };

    const handleScan = (e) => {
        setCobroStatus({ status: 0 });
        setCobroAmount('');
        setGuiaSearch(e);
        setBcEnabled(false);
        const guia = e;

        if (guia == 0) {
            setBcEnabled(true);
            return null;
        }

        if (guia.toString().length >= 10 || isNaN(guia)) {
            setSearchStatus({ status: 0, message: `Código de barras incorrecto` });
            setBcEnabled(true);
            return null;
        }

        fetchGuia(guia);

        //setGuiaSearch('');
    };

    const handleError = (e) => {
        //console.log(e);
    };

    const handleAddCobro = async (guia, e) => {
        setCobroAmount('');

        const apiUrl = `/api/v1/ops/cobros`;
        const apiData = { guia: guia, cantidad: e, bloque: params.id, nombreBloque: bloqueInfo.nombre, created_by: user.eid };
        await axios.post(apiUrl, apiData).then((response) => {
            if (response.status == 201) {
                console.log(response.data);
                if (response.data.status == 1) {
                    setCobroStatus({ status: 1, message: 'Cobro capturado exitosamente' });
                    fetchBloque(params.id);
                    setGuiaSearch('');
                }
            }
        });
    };

    const handleIncidencia = async (guia) => {
        setCobroAmount('');

        const apiUrl = `/api/v1/ops/cobros/incidencia`;
        const apiData = { guia: guia, nombreBloque: bloqueInfo.nombre, created_by: user.eid };
        await axios.post(apiUrl, apiData).then((response) => {
            if (response.status == 201) {
                console.log(response.data);
                if (response.data.status == 1) {
                    setCobroStatus({ status: 1, message: 'Incidencia asignada exitosamente' });
                    setIncidenciaConfirm(false);
                    setGuiaSearch('');
                    fetchBloque(params.id);
                }
            }
        });
    };

    const handleReinsertar = async (guia) => {
        setCobroAmount('');

        const apiUrl = `/api/v1/ops/cobros/reinsertar`;
        const apiData = { guia: guia, bloque: parseInt(params.id), nombreBloque: bloqueInfo.nombre, created_by: user.eid };
        await axios.post(apiUrl, apiData).then((response) => {
            if (response.status == 201) {
                console.log(response.data);
                if (response.data.status == 1) {
                    setCobroStatus({ status: 1, message: 'Cobro Recapturado exitosamente' });
                    setReinsertarConfirm(false);
                    setGuiaSearch('');
                    fetchBloque(params.id);
                }
            }
        });
    };

    const handleConfirmar = async (guia) => {
        setCobroAmount('');

        const apiUrl = `/api/v1/ops/cobros/confirmar`;
        const apiData = { guia: guia, nombreBloque: bloqueInfo.nombre, confirmado_by: user.eid };
        await axios.post(apiUrl, apiData).then((response) => {
            if (response.status == 201) {
                console.log(response.data);
                if (response.data.status == 1) {
                    setCobroStatus({ status: 1, message: 'Cobro confirmado exitosamente' });
                    setGuiaSearch('');
                    fetchBloque(params.id);
                }
            }
        });
    };

    const handleCancelar = async (guia) => {
        setCobroAmount('');

        const apiUrl = `/api/v1/ops/cobros/cancelar`;
        const apiData = { guia: guia, nombreBloque: bloqueInfo.nombre, created_by: user.eid };
        await axios.post(apiUrl, apiData).then((response) => {
            if (response.status == 201) {
                console.log(response.data);
                if (response.data.status == 1) {
                    setCobroStatus({ status: 1, message: 'Cobro cancelado exitosamente' });
                    setGuiaSearch('');
                    setCancelConfirm(false);
                    fetchBloque(params.id);
                }
            }
        });
    };

    const handleEditCobro = async (guia, e) => {
        setCobroAmount('');

        const apiUrl = `/api/v1/ops/cobros`;
        const apiData = { guia: guia, cantidad: e };
        await axios.patch(apiUrl, apiData).then((response) => {
            if (response.status == 201) {
                console.log(response.data);
                if (response.data.status == 1) {
                    setCobroStatus({ status: 1, message: 'Cobro modificado exitosamente' });
                    setGuiaSearch('');
                    setCobroEdit(false);
                    fetchBloque(params.id);
                }
            }
        });
    };

    const handleDeleteCobro = async (guia) => {
        setCobroAmount('');

        const apiUrl = `/api/v1/ops/cobros/${guia}`;
        await axios.delete(apiUrl).then((response) => {
            if (response.status == 201) {
                console.log(response.data);
                if (response.data.status == 1) {
                    setCobroStatus({ status: 1, message: 'Cobro eliminado exitosamente' });
                    setGuiaSearch('');
                    setCobroDelConfirm(false);
                    fetchBloque(params.id);
                }
            }
        });
    };

    const sendToPrint = (guia) => {
        window.open(`/searchprint/${guia}`, '_blank');
    };

    return (
        <MainCard sx={{ mt: 0, height: '100%' }}>
            <Stack direction="row" alignItems="center" sx={{ mt: 0 }}>
                <Grid
                    sx={{ mr: 2, cursor: 'pointer' }}
                    onClick={() => navigate(`/cobros/main/${bloqueInfo?.fecha}/${bloqueInfo?.bloque}`, { replace: true })}
                >
                    <IconArrowLeft stroke={2.5} size="1.5rem" />
                </Grid>
                <Stack direction="row" alignItems="center" sx={{ mt: 0 }}>
                    <PaidIcon color="primary" sx={{ fontSize: 30, mr: 1 }} />
                    <Stack direction="column">
                        <Typography variant={matchDownMd ? 'h6' : 'h4'} align="left">
                            Cobros a destinatario
                        </Typography>
                        <Typography variant="caption" align="left">
                            {bloqueInfo?.nombre}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Grid container spacing={0} sx={{ mt: 0 }}>
                <Grid item xs={12} md={8}>
                    <Grid>
                        {bcEnabled && <BarcodeReader onError={handleError} onScan={handleScan} />}
                        <Grid item xs={12} md={6} lg={4} sx={{ mt: 0 }}>
                            <Stack direction="row" alignItems="center">
                                <Box sx={{ width: 50 }}>{bcEnabled && <img src={bcImg} width={40}></img>}</Box>

                                <TextField
                                    label=""
                                    size="small"
                                    value={guiaSearch}
                                    onChange={(e) => setGuiaSearch(e.target.value)}
                                    onKeyDown={(ev) => {
                                        if (ev.key === 'Enter') {
                                            handleScan(ev.target.value);
                                        }
                                    }}
                                    placeholder="No. Guía"
                                />
                                <InputAdornment position="end" onClick={() => handleScan(guiaSearch)} sx={{ mx: 2 }}>
                                    <HeaderAvatarStyle variant="rounded">
                                        <IconChevronRight stroke={2.5} size="1.7rem" sx={{ p: 1 }} />
                                    </HeaderAvatarStyle>
                                </InputAdornment>
                            </Stack>
                        </Grid>
                        {!isLoading ? (
                            <Grid item xs={12} md={10} lg={10} sx={{ mt: 2 }}>
                                {searchStatus.status != 0 ? (
                                    <>
                                        {Object.keys(pedidoData).length >= 1 && (
                                            <>
                                                {' '}
                                                {cobroStatus.status == 0 ? (
                                                    <Grid container direction="column">
                                                        <Stack
                                                            direction="row"
                                                            justifyContent={'space-between'}
                                                            alignItems="center"
                                                            sx={{ mb: 2, width: '70%' }}
                                                        >
                                                            <Typography variant={'h2'} align="left">
                                                                {pedidoData.guia.id}
                                                            </Typography>
                                                            <Divider orientation="vertical" sx={{ mx: 2 }} />
                                                            <Stack>
                                                                <Typography variant={'caption'} align="left">
                                                                    Cliente id: {pedidoData.guia.sysop_cli}
                                                                </Typography>
                                                                <Typography variant={'caption'} align="left">
                                                                    {pedidoData.guia.cliente_nombre}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                        <Box
                                                            sx={{
                                                                py: 0.5,
                                                                px: 1,
                                                                background: theme.palette.secondary.light,
                                                                borderRadius: 3,
                                                                width: '70%'
                                                            }}
                                                        >
                                                            <BoxSelector guia={pedidoData.guia} />
                                                        </Box>

                                                        <Divider sx={{ my: 2 }} />
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            sx={{ my: 0 }}
                                                        >
                                                            <Stack sx={{ width: 350 }}>
                                                                <Typography variant={'h6'} align="left">
                                                                    Recolección
                                                                </Typography>
                                                                <Typography variant={'caption'} align="left">
                                                                    {pedidoData.guia.origen_colonia}, {pedidoData.guia.origen_municipio}
                                                                </Typography>
                                                                <Typography variant={'caption'} align="left">
                                                                    {pedidoData.guia.origen_fecha}, bloque {pedidoData.guia.origen_bloque}
                                                                </Typography>
                                                                <Typography
                                                                    variant={'h6'}
                                                                    align="left"
                                                                    sx={{ color: theme.palette.text.secondary }}
                                                                >
                                                                    Referencias
                                                                </Typography>
                                                                <Typography variant={'h6'} align="left">
                                                                    {pedidoData.guia.origen_referencias}
                                                                </Typography>
                                                            </Stack>

                                                            <Stack sx={{ width: 350 }}>
                                                                <Typography variant={'h6'} align="left">
                                                                    Entrega
                                                                </Typography>
                                                                <Typography variant={'caption'} align="left">
                                                                    {pedidoData.guia.entrega_colonia}, {pedidoData.guia.entrega_municipio}
                                                                </Typography>
                                                                <Typography variant={'caption'} align="left">
                                                                    {pedidoData.guia.entrega_fecha}, bloque {pedidoData.guia.entrega_bloque}
                                                                </Typography>
                                                                <Typography
                                                                    variant={'h6'}
                                                                    align="left"
                                                                    sx={{ color: theme.palette.text.secondary }}
                                                                >
                                                                    Referencias
                                                                </Typography>
                                                                <Typography variant={'h6'} align="left">
                                                                    {pedidoData.guia.entrega_referencias}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                        <Typography
                                                            variant={'h6'}
                                                            align="left"
                                                            sx={{ color: theme.palette.text.secondary }}
                                                        >
                                                            Contenido
                                                        </Typography>
                                                        <Typography variant={'h6'} align="left">
                                                            {pedidoData.guia.paq_contenido}
                                                        </Typography>
                                                    </Grid>
                                                ) : (
                                                    <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
                                                        <CheckCircleIcon sx={{ fontSize: 16, color: '#00C897' }} />
                                                        <Typography variant={'h6'} align="left" sx={{ ml: 1 }}>
                                                            {cobroStatus.message}
                                                        </Typography>
                                                    </Stack>
                                                )}{' '}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <Typography color="error">{searchStatus.message}</Typography>
                                )}
                            </Grid>
                        ) : (
                            <LinearProgress sx={{ width: 300, my: 1 }} />
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid sx={{ mt: 2 }}>
                        {capturados.length >= 1 && (
                            <Grid sx={{ my: 2 }}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Stack>
                                        <Typography variant={'h3'} align="left">
                                            {bloqueResumen.monto_confirmado != 0 ? '$' : ''}
                                            {bloqueResumen.monto_confirmado}/${bloqueResumen.monto_total}
                                        </Typography>
                                        <Typography variant={'caption'} align="left">
                                            {bloqueResumen.cobros} cobros
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center">
                                        <Link to={`/cobros/print/${params.id}`} target="_blank">
                                            <InputAdornment position="end">
                                                <HeaderAvatarStyle variant="rounded">
                                                    <IconPrinter
                                                        stroke={2}
                                                        size="1.5rem"
                                                        color={theme.palette.secondary.main}
                                                        sx={{ p: 1, color: theme.palette.primary.main }}
                                                    />
                                                </HeaderAvatarStyle>
                                            </InputAdornment>
                                        </Link>
                                        <Link to={`/cobros/printzebra/${params.id}`} target="_blank">
                                            <InputAdornment position="end">
                                                <HeaderAvatarStyle variant="rounded">
                                                    <Stack direction="column" justifyContent="center" alignItems="center">
                                                        <IconPrinter
                                                            stroke={2}
                                                            size="1.1rem"
                                                            color={theme.palette.secondary.main}
                                                            sx={{ p: 1, color: theme.palette.primary.main }}
                                                        />
                                                        <Typography align="center" sx={{ fontSize: 10, textDecoration: 'none' }}>
                                                            zebra
                                                        </Typography>
                                                    </Stack>
                                                </HeaderAvatarStyle>
                                            </InputAdornment>
                                        </Link>
                                        <Link to={`/cobros/reporte/${params.id}`} target="_blank">
                                            <InputAdornment position="end">
                                                <HeaderAvatarStyle variant="rounded">
                                                    <IconClipboardList
                                                        stroke={2}
                                                        size="1.5rem"
                                                        color={theme.palette.secondary.main}
                                                        sx={{ p: 1, color: theme.palette.primary.main }}
                                                    />
                                                </HeaderAvatarStyle>
                                            </InputAdornment>
                                        </Link>
                                    </Stack>
                                </Stack>
                                <Divider sx={{ my: 1.5, border: 1 }} />
                            </Grid>
                        )}
                        {capturados.map((c, i) => {
                            return (
                                <Stack key={i}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={2.5}>
                                            <Typography variant="h6" sx={{ my: 1 }}>
                                                {c.sysop_id}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <EstatusCobroBadge statusId={c.estatus_id} statusName={c.cobro_estatus} />
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <EstatusPagoBadge statusId={c.pago_estatus_id} statusName={c.cobro_pago_estatus} />
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Typography variant="h6" sx={{ my: 1 }}>
                                                ${c.cantidad}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <LaunchIcon
                                                sx={{ fontSize: 16, color: theme.palette.secondary.main, mt: 0.5, cursor: 'pointer' }}
                                                onClick={() => handleScan(c.sysop_id)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ my: 0 }} />
                                </Stack>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );

    function BoxSelector({ guia }) {
        if (guia.cobro_destinatario == 0) {
            return <CapturaCobro guia={guia} />;
        } else {
            if (guia.cobro_status_id != 3 && guia.cobro_bloque != params.id) {
                return (
                    <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
                        <ErrorIcon sx={{ color: '#E64848', mr: 1, fontSize: 20 }} />
                        <Typography>Cobro capturado en otro bloque</Typography>
                    </Stack>
                );
            }

            if (guia.cobro_status_id == 3) {
                return <CobroEnIncidencia guia={guia} />;
            }

            if (guia.cobro_status_id == 1) {
                return <EditarCobro guia={guia} />;
            }

            if (guia.cobro_status_id == 2) {
                return <CobroCompletado guia={guia} />;
            }

            if (guia.cobro_status_id == 4) {
                return (
                    <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
                        <ErrorIcon sx={{ color: '#E64848', mr: 1, fontSize: 20 }} />
                        <Typography>Cobro cancelado</Typography>
                    </Stack>
                );
            }
        }

        return (
            <>
                <div></div>
            </>
        );
    }

    function CapturaCobro({ guia }) {
        return (
            <Stack>
                <Typography variant="h5" sx={{ mb: 2, color: theme.palette.secondary.main, fontWeight: 600 }}>
                    Cobro
                </Typography>

                <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                    <TextField
                        type="number"
                        autoFocus
                        size="small"
                        label="Agregar cobro"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(e) => handleAddCobro(guia.id, cobroAmount)}
                                >
                                    <IconChevronRight stroke={1.5} size="1.3rem" sx={{ color: theme.palette.grey[400] }} />
                                </InputAdornment>
                            )
                        }}
                        value={cobroAmount}
                        onChange={(e) => setCobroAmount(e.target.value)}
                        onKeyDown={(ev) => {
                            if (ev.key === 'Enter') {
                                handleAddCobro(guia.id, ev.target.value);
                            }
                        }}
                        sx={{ width: 250 }}
                    />
                </Stack>
            </Stack>
        );
    }

    function EditarCobro({ guia }) {
        return (
            <Stack>
                <Typography variant="h5" sx={{ mb: 0, color: theme.palette.secondary.main, fontWeight: 600 }}>
                    Cobro
                </Typography>
                <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                    <EstatusCobroBadge statusId={pedidoData.guia.cobro_status_id} statusName={pedidoData.guia.cobro_estatus} />
                    <EstatusPagoBadge statusId={pedidoData.guia.cobro_pago_estatus_id} statusName={pedidoData.guia.cobro_pago_estatus} />
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                    <TextField
                        disabled={!cobroEdit}
                        type="number"
                        autoFocus={cobroEdit}
                        size="small"
                        label={!cobroEdit ? null : 'Cambiar cobro'}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: cobroEdit ? (
                                <InputAdornment
                                    position="end"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(e) => handleEditCobro(pedidoData.guia.id, cobroAmount)}
                                >
                                    <IconChevronRight stroke={1.5} size="1.3rem" sx={{ color: theme.palette.grey[400] }} />
                                </InputAdornment>
                            ) : null
                        }}
                        value={cobroAmount}
                        onChange={(e) => setCobroAmount(e.target.value)}
                        onKeyDown={(ev) => {
                            if (ev.key === 'Enter') {
                                handleEditCobro(pedidoData.guia.id, ev.target.value);
                            }
                        }}
                        sx={{
                            width: 250,
                            mb: 2,
                            '& fieldset': { border: cobroEdit ? null : 'none', background: null },
                            input: {
                                background: cobroEdit ? null : theme.palette.secondary.light
                            },
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: '#000000'
                            }
                        }}
                    />
                    <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                        <Link to={'/cobros/print'} target="_blank" onClick={() => sendToPrint(pedidoData.guia.id)}>
                            <InputAdornment position="end">
                                <HeaderAvatarStyle variant="rounded">
                                    <IconPrinter
                                        stroke={2}
                                        size="1.5rem"
                                        color={theme.palette.secondary.main}
                                        sx={{ p: 1, color: theme.palette.primary.main }}
                                    />
                                </HeaderAvatarStyle>
                            </InputAdornment>
                        </Link>

                        <InputAdornment position="end" onClick={() => setCobroEdit(!cobroEdit)} sx={{ ml: 1 }}>
                            <HeaderAvatarStyle variant="rounded">
                                {!cobroEdit ? (
                                    <IconEdit
                                        stroke={2}
                                        size="1.5rem"
                                        color={theme.palette.secondary.main}
                                        sx={{ p: 1, color: theme.palette.primary.main }}
                                    />
                                ) : (
                                    <IconX
                                        stroke={2}
                                        size="1.5rem"
                                        color={theme.palette.secondary.main}
                                        sx={{ p: 1, color: theme.palette.primary.main }}
                                    />
                                )}
                            </HeaderAvatarStyle>
                        </InputAdornment>
                        <ConfirmWidget
                            confirm={cobroDelConfirm}
                            message={'Confirmar eliminación'}
                            siFunction={() => handleDeleteCobro(pedidoData.guia.id)}
                            noFunction={() => setCobroDelConfirm(false)}
                            component={
                                <>
                                    <InputAdornment position="end" onClick={() => setCobroDelConfirm(true)} sx={{ ml: 1 }}>
                                        <HeaderAvatarStyle variant="rounded">
                                            <IconTrash
                                                stroke={2}
                                                size="1.5rem"
                                                color={theme.palette.error.main}
                                                sx={{ p: 1, color: theme.palette.primary.main }}
                                            />
                                        </HeaderAvatarStyle>
                                    </InputAdornment>
                                </>
                            }
                        />
                    </Stack>
                </Stack>
                <Divider sx={{ my: 1 }} />
                <Stack direction="row" alignItems="center" sx={{ my: 1 }}>
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        sx={{ textTransform: 'none' }}
                        disabled={incidenciaConfirm || cancelConfirm}
                        onClick={() => handleConfirmar(pedidoData.guia.id)}
                    >
                        Confirmar pago
                    </Button>
                    <ConfirmWidget
                        confirm={incidenciaConfirm}
                        message={'Confirmar incidencia?'}
                        siFunction={() => handleIncidencia(pedidoData.guia.id)}
                        noFunction={() => setIncidenciaConfirm(false)}
                        component={
                            <>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    disabled={cancelConfirm}
                                    color="secondary"
                                    sx={{ ml: 2 }}
                                    onClick={() => setIncidenciaConfirm(true)}
                                >
                                    Incidencia
                                </Button>
                            </>
                        }
                    />
                    <ConfirmWidget
                        confirm={cancelConfirm}
                        message={'Confirmar cancelación?'}
                        siFunction={() => handleCancelar(pedidoData.guia.id)}
                        noFunction={() => setCancelConfirm(false)}
                        component={
                            <>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    disabled={incidenciaConfirm}
                                    color="error"
                                    sx={{ ml: 2, textTransform: 'none' }}
                                    onClick={() => setCancelConfirm(true)}
                                >
                                    Cancelar cobro
                                </Button>
                            </>
                        }
                    />
                </Stack>
            </Stack>
        );
    }

    function CobroCompletado({ guia }) {
        return (
            <Stack>
                <Typography variant="h5" sx={{ mb: 0, color: theme.palette.secondary.main, fontWeight: 600 }}>
                    Cobro
                </Typography>
                <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                    <EstatusCobroBadge statusId={pedidoData.guia.cobro_status_id} statusName={pedidoData.guia.cobro_estatus} />
                    <EstatusPagoBadge statusId={pedidoData.guia.cobro_pago_estatus_id} statusName={pedidoData.guia.cobro_pago_estatus} />
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 0 }}>
                    <TextField
                        disabled={!cobroEdit}
                        type="number"
                        autoFocus={cobroEdit}
                        size="small"
                        label={!cobroEdit ? null : 'Cambiar cobro'}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: cobroEdit ? (
                                <InputAdornment
                                    position="end"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(e) => handleEditCobro(pedidoData.guia.id, cobroAmount)}
                                >
                                    <IconChevronRight stroke={1.5} size="1.3rem" sx={{ color: theme.palette.grey[400] }} />
                                </InputAdornment>
                            ) : null
                        }}
                        value={cobroAmount}
                        onChange={(e) => setCobroAmount(e.target.value)}
                        onKeyDown={(ev) => {
                            if (ev.key === 'Enter') {
                                handleEditCobro(pedidoData.guia.id, ev.target.value);
                            }
                        }}
                        sx={{
                            width: 250,
                            mb: 1,
                            '& fieldset': { border: cobroEdit ? null : 'none', background: null },
                            input: {
                                background: cobroEdit ? null : theme.palette.secondary.light
                            },
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: '#000000'
                            }
                        }}
                    />
                </Stack>
                <Divider sx={{ mb: 1 }} />
                <Stack>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="caption" sx={{ color: theme.palette.text.main }}>
                            Bloque:
                        </Typography>
                        <Typography variant="caption" sx={{ ml: 0.5, color: theme.palette.text.secondary }}>
                            {guia.cobro_bloque_nombre}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="caption" sx={{ color: theme.palette.text.main }}>
                            Confirmado:
                        </Typography>
                        <Typography variant="caption" sx={{ ml: 0.5, color: theme.palette.text.secondary }}>
                            {namedDateFull(new Date(guia.cobro_confirmado_at))}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="caption" sx={{ color: theme.palette.text.main }}>
                            Por:
                        </Typography>
                        <Typography variant="caption" sx={{ ml: 0.5, color: theme.palette.secondary.main }}>
                            {guia.cobro_confirmado_by}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        );
    }

    function CobroEnIncidencia({ guia }) {
        return (
            <Stack>
                <Typography variant="h5" sx={{ mb: 0, color: theme.palette.secondary.main, fontWeight: 600 }}>
                    Cobro
                </Typography>
                <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                    <EstatusCobroBadge statusId={pedidoData.guia.cobro_status_id} statusName={pedidoData.guia.cobro_estatus} />
                    <EstatusPagoBadge statusId={pedidoData.guia.cobro_pago_estatus_id} statusName={pedidoData.guia.cobro_pago_estatus} />
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 0 }}>
                    <TextField
                        disabled={!cobroEdit}
                        type="number"
                        autoFocus={cobroEdit}
                        size="small"
                        label={!cobroEdit ? null : 'Cambiar cobro'}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: cobroEdit ? (
                                <InputAdornment
                                    position="end"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(e) => handleEditCobro(pedidoData.guia.id, cobroAmount)}
                                >
                                    <IconChevronRight stroke={1.5} size="1.3rem" sx={{ color: theme.palette.grey[400] }} />
                                </InputAdornment>
                            ) : null
                        }}
                        value={cobroAmount}
                        onChange={(e) => setCobroAmount(e.target.value)}
                        onKeyDown={(ev) => {
                            if (ev.key === 'Enter') {
                                handleEditCobro(pedidoData.guia.id, ev.target.value);
                            }
                        }}
                        sx={{
                            width: 250,
                            mb: 1,
                            '& fieldset': { border: cobroEdit ? null : 'none', background: null },
                            input: {
                                background: cobroEdit ? null : theme.palette.secondary.light
                            },
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: '#000000'
                            }
                        }}
                    />
                </Stack>
                <Divider sx={{ mb: 1 }} />
                <Stack>
                    <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
                        <ErrorIcon sx={{ color: '#d18f0d', mr: 0.5, fontSize: 14 }} />
                        <Typography variant="h6">Cobro en incidencia</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                        <Typography variant="caption" sx={{ color: theme.palette.text.main }}>
                            Bloque original:
                        </Typography>
                        <Typography variant="caption" sx={{ ml: 0.5, color: theme.palette.text.secondary }}>
                            {guia.cobro_bloque_nombre}
                        </Typography>
                    </Stack>
                    <ConfirmWidget
                        confirm={reinsertarConfirm}
                        width={250}
                        message={'Capturar en este bloque?'}
                        siFunction={() => handleReinsertar(pedidoData.guia.id)}
                        noFunction={() => setReinsertarConfirm(false)}
                        component={
                            <>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    sx={{ my: 1, textTransform: 'none' }}
                                    onClick={() => setReinsertarConfirm(true)}
                                >
                                    Capturar en este bloque
                                </Button>
                            </>
                        }
                    />
                </Stack>
            </Stack>
        );
    }

    function EstatusCobroBadge({ statusId, statusName }) {
        let bgcolor;

        switch (statusId) {
            case 1:
                bgcolor = theme.palette.secondary.main;
                break;
            case 2:
                bgcolor = '#00C897';
                break;
            case 3:
                bgcolor = '#d18f0d';
                break;
            case 4:
                bgcolor = '#E64848';
                break;
            default:
                bgcolor = theme.palette.secondary.main;
        }

        return (
            <>
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="caption"
                        sx={{
                            mr: 1,
                            pt: 0.3,
                            pb: 0.24,
                            px: 0.6,
                            fontWeight: 400,
                            fontSize: 9,
                            lineHeight: 1,
                            backgroundColor: bgcolor,
                            borderRadius: 1,
                            color: '#FFF',
                            display: 'flex'
                        }}
                    >
                        {statusName}
                    </Typography>
                </div>
            </>
        );
    }

    function EstatusPagoBadge({ statusId, statusName }) {
        let bgcolor;

        switch (statusId) {
            case 1:
                bgcolor = theme.palette.secondary.main;
                break;
            case 2:
                bgcolor = theme.palette.secondary.main;
                break;
            case 3:
                bgcolor = theme.palette.secondary.main;
                break;
            case 4:
                bgcolor = '#00C897';
                break;
            default:
                bgcolor = theme.palette.secondary.main;
        }

        return (
            <>
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="caption"
                        sx={{
                            ml: 0,
                            pt: 0.3,
                            pb: 0.24,
                            px: 0.6,
                            fontWeight: 400,
                            fontSize: 9,
                            lineHeight: 1,
                            backgroundColor: bgcolor,
                            borderRadius: 1,
                            color: '#FFF',
                            display: 'flex'
                        }}
                    >
                        {statusName}
                    </Typography>
                </div>
            </>
        );
    }
};

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    background: theme.palette.grey[200],
    color: theme.palette.secondary.main,
    '&:hover': {
        background: theme.palette.grey[400],
        color: theme.palette.secondary.main
    }
}));

export default CobrosCaptura;
