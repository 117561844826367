import React, { useState, useEffect, useRef } from 'react';
import axios from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Grid,
    Button,
    Typography,
    Stack,
    Link,
    useMediaQuery,
    Box,
    Chip,
    Badge,
    TablePagination,
    LinearProgress,
    Avatar
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { namedDate } from 'utils/helpers';
import PrintIcon from '@mui/icons-material/Print';
import SubCard from 'ui-component/cards/SubCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import PunteoPagoDialog from './PunteoPagoDialog';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { deepOrange, deepPurple } from '@mui/material/colors';
import PagoCard from './components/PagoCard';
import { GridApiContext } from '@mui/x-data-grid';

const PagosBloqueTab = ({ bloque, fechaInfo }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const componentRef = useRef(null);

    const [open, setOpen] = React.useState(false);
    const [initLoading, setInitLoading] = React.useState(false);
    const [initFetched, setInitFetched] = React.useState(false);
    const [openPrint, setOpenPrint] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [pagosData, setPagosData] = React.useState([]);
    const [displayData, setDisplayData] = React.useState([]);
    const [printDate, setPrintDate] = React.useState(new Date());
    const [selected, setSelected] = React.useState({});
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [printingData, setPrintingData] = React.useState([]);
    const [cortesData, setCortesData] = React.useState([]);
    const [counts, setCounts] = React.useState({ s: 0, c: 0, m: 0 });
    const [filterSelected, setFilterSelected] = React.useState(0);
    const [confirmCount, SetConfirmCount] = React.useState(0);
    const [noprocCount, SetNoprocCount] = React.useState(0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(21);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event?.target?.value);
        setPage(0);
    };

    React.useEffect(() => {
        fetchData(fechaInfo, bloque);
    }, [fechaInfo]);

    const dateHandler = (date) => {
        const fecha = new Date(fecha);
        return fecha;
    };

    const fetchData = async (fecha, bloque) => {
        setInitLoading(true);
        const apiUrl = `/api/v1/print/punteopagos`;
        const apiData = { fecha: fecha, bloque: bloque };

        //console.log(apiData);
        await axios.post(apiUrl, apiData).then((response) => {
            let mCount = 0;
            let cCount = 0;
            let sCount = 0;
            console.log(response.data);
            const newValues = response.data.map((obj) => {
                let c;
                c = obj.comp;
                if (c == 10) {
                    cCount++;
                } else if (c == 11) {
                    mCount++;
                } else {
                    sCount++;
                }
                return { ...obj, c: c };
            });

            const timer = setTimeout(() => {
                setCounts({ t: newValues.length, s: sCount, c: cCount, m: mCount });
                setPagosData(newValues);
                setDisplayData(newValues);
                setInitLoading(false);
                setInitFetched(true);
            }, 600);
        });
    };

    const handleConfirm = async (guia, val) => {
        setIsLoading(true);
        const apiUrl = `/api/v1/print/punteopagosup`;
        const apiData = { tipo: 1, id: guia };

        await axios.post(apiUrl, apiData).then((response) => {
            if (response.data == 'OK') {
                let mCount = 0;
                let cCount = 0;
                let sCount = 0;
                const newValues = displayData.map((obj) => {
                    if (obj.sysId === guia) {
                        setSelected({ ...obj, c: 10 });
                        return { ...obj, c: 10 };
                    }
                    return obj;
                });
                Promise.all(newValues).then((val) => {
                    setPagosData(val);
                    setDisplayData(val);

                    const counts = val.map((obj) => {
                        let c;
                        c = obj.c;
                        if (c == 10) {
                            cCount++;
                        } else if (c == 11) {
                            mCount++;
                        } else {
                            sCount++;
                        }
                        return { ...obj, c: c };
                    });

                    Promise.all(counts).then(() => {
                        setCounts({ t: counts.length, s: sCount, c: cCount, m: mCount });
                    });
                });
                const timer = setTimeout(() => {
                    setIsLoading(false);
                }, 300);
            } else {
            }
        });
    };

    const handleMark = async (guia) => {
        setIsLoading(true);
        const apiUrl = `/api/v1/print/punteopagosup`;
        const apiData = { tipo: 2, id: guia };

        await axios.post(apiUrl, apiData).then((response) => {
            if (response.data == 'OK') {
                let mCount = 0;
                let cCount = 0;
                let sCount = 0;
                const newValues = displayData.map((obj) => {
                    if (obj.sysId === guia) {
                        setSelected({ ...obj, c: 11 });
                        return { ...obj, c: 11 };
                    }
                    return obj;
                });
                Promise.all(newValues).then((val) => {
                    setPagosData(val);
                    setDisplayData(val);

                    const counts = val.map((obj) => {
                        let c;
                        c = obj.c;
                        if (c == 10) {
                            cCount++;
                        } else if (c == 11) {
                            mCount++;
                        } else {
                            sCount++;
                        }
                        return { ...obj, c: c };
                    });

                    Promise.all(counts).then(() => {
                        setCounts({ t: counts.length, s: sCount, c: cCount, m: mCount });
                    });
                });
                const timer = setTimeout(() => {
                    setIsLoading(false);
                }, 300);
            } else {
            }
        });
    };

    const handleFilter = (tipo) => {
        setFilterSelected(tipo);
        if (tipo == 0) {
            setDisplayData(pagosData);
            setPage(0);
        } else if (tipo == 1) {
            const newRows = pagosData.filter((row) => {
                let matches = true;

                const properties = ['c'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property] == 1 || row[property] == 0) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });

            setDisplayData(newRows);
        } else {
            const newRows = pagosData.filter((row) => {
                let matches = true;

                const properties = ['c'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property] == tipo) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });

            setDisplayData(newRows);
        }
    };

    const handleDialog = async (pago, index) => {
        console.log(pago);
        setSelected(pago);
        setSelectedIndex(index);
        setOpen(true);
    };

    const handleNext = async (index) => {
        console.log(index);
        setSelectedIndex(index + 1);
        setSelected(pagosData[index + 1]);
    };
    const closeDialog = (value) => {
        setOpen(false);
        console.log(value);
    };

    return (
        <Grid container direction="column" spacing={0}>
            {pagosData.length >= 1 && (
                <PunteoPagoDialog
                    open={open}
                    handleCloseDialog={closeDialog}
                    data={selected}
                    sIndex={selectedIndex}
                    handleDialogNext={handleNext}
                    confirmFunction={handleConfirm}
                    markFunction={handleMark}
                    loading={isLoading}
                />
            )}

            {initLoading && (
                <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                    <Typography>Cargando...</Typography>
                    <LinearProgress sx={{ width: 300, my: 1 }} />
                </Grid>
            )}
            {initFetched && (
                <Grid item xs={12} md={6} lg={6} sx={{ mb: 0 }}>
                    <Stack direction="row" alignItems="center" sx={{ mt: 0 }}>
                        <Typography variant="h3" sx={{ fontSize: matchDownMd ? 26 : 40 }}>
                            {counts.c}/{counts.t}
                        </Typography>
                        <Stack direction={matchDownMd ? 'column' : 'row'} alignItems={matchDownMd ? 'flex-start' : 'center'} sx={{ ml: 6 }}>
                            <Chip
                                size="small"
                                sx={{
                                    mb: matchDownMd ? 0.5 : 0,
                                    mx: 2,
                                    p: 1.5,
                                    bgcolor: filterSelected == 0 ? '#FFAE6D' : 'grey[100]',
                                    '&.MuiButtonBase-root:hover': {
                                        bgcolor: filterSelected == 0 ? '#FFAE6D' : 'grey[100]'
                                    }
                                }}
                                onClick={() => handleFilter(0)}
                                label={
                                    <Typography variant="h3" sx={{ fontSize: 12 }}>
                                        Todos ({counts.t})
                                    </Typography>
                                }
                            />
                            <Chip
                                size="small"
                                sx={{
                                    mb: matchDownMd ? 0.5 : 0,
                                    mx: 2,
                                    p: 1.5,
                                    bgcolor: filterSelected == 11 ? '#FFAE6D' : 'grey[100]',
                                    '&.MuiButtonBase-root:hover': {
                                        bgcolor: filterSelected == 11 ? '#FFAE6D' : 'grey[100]'
                                    }
                                }}
                                onClick={() => handleFilter(11)}
                                label={
                                    <Typography variant="h3" sx={{ fontSize: 12 }}>
                                        Marcados ({counts.m})
                                    </Typography>
                                }
                            />
                            <Chip
                                size="small"
                                sx={{
                                    mb: matchDownMd ? 0.5 : 0,
                                    mx: 2,
                                    p: 1.5,
                                    bgcolor: filterSelected == 10 ? '#FFAE6D' : 'grey[100]',
                                    '&.MuiButtonBase-root:hover': {
                                        bgcolor: filterSelected == 10 ? '#FFAE6D' : 'grey[100]'
                                    }
                                }}
                                onClick={() => handleFilter(10)}
                                label={
                                    <Typography variant="h3" sx={{ fontSize: 12 }}>
                                        Confirmados ({counts.c})
                                    </Typography>
                                }
                            />
                            <Chip
                                size="small"
                                sx={{
                                    mb: matchDownMd ? 0.5 : 0,
                                    mx: 2,
                                    p: 1.5,
                                    bgcolor: filterSelected == 1 ? '#FFAE6D' : 'grey[100]',
                                    '&.MuiButtonBase-root:hover': {
                                        bgcolor: filterSelected == 1 ? '#FFAE6D' : 'grey[100]'
                                    }
                                }}
                                onClick={() => handleFilter(1)}
                                label={
                                    <Typography variant="h3" sx={{ fontSize: 12 }}>
                                        Sin confirmar ({counts.s})
                                    </Typography>
                                }
                            />
                        </Stack>
                    </Stack>
                </Grid>
            )}
            <Grid item xs={12} sx={{ mb: 2 }}>
                <>
                    {displayData.length >= 1 ? (
                        <Grid container>
                            {displayData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((p, i) => {
                                return (
                                    <Grid key={p.sysId} sx={{ mx: 1, my: 1 }}>
                                        {matchDownMd ? (
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{ cursor: 'pointer', textDecoration: 'none' }}
                                                component={Link}
                                                onClick={() => handleDialog(p, i)}
                                            >
                                                <PagoCard pago={p} />
                                            </Grid>
                                        ) : (
                                            <Grid
                                                sx={{ cursor: 'pointer', textDecoration: 'none' }}
                                                component={Link}
                                                onClick={() => handleDialog(p, i)}
                                            >
                                                <PagoCard pago={p} />
                                            </Grid>
                                        )}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Grid container>
                            <Typography variant="caption" sx={{ fontSize: 14, mt: 3 }}>
                                No se encontró información
                            </Typography>
                        </Grid>
                    )}
                </>
            </Grid>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={displayData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{ display: 'flex' }}
                showFirstButton
                showLastButton
            />
        </Grid>
    );
};

export default PagosBloqueTab;
